import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f4f3f1',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    button: {
      main: '#0275d8',
    },
    chip: {
      main: '#818a91',
    },
  },
  // typography: {
  //   fontFamily: ['Fangsong', 'STFangsong', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  // },
});

export default theme;
