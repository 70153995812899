import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Hidden,
  FormControl,
  TableSortLabel,
} from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import generalStyles from '../styles/style';
import { makeRequest } from '../../api/api';
import { AppContext } from '../../context/context';
import { reduce } from 'lodash';
import useRequestList from '../../hooks/useRequestList';
import { usePrevious } from 'react-delta';

const CustGrp = (props) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;
  const generalClasses = generalStyles();
  //* State
  const [formValue, setFormValue] = useState({});
  const [initFormOptions, setInitFormOptions] = useState();
  const [formItems, setFormItems] = useState(props.formItems);
  const [orderBy, setOrderBy] = useState('site');
  const [order, setOrder] = useState('asc');
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pagiDisable, setPagiDisable] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const [totalContent, setTotalContent] = useState(0);
  const [errorMsg, setErrorMsg] = useState(false);
  const handleSetRequestList = useRequestList();
  const prevOrder = usePrevious(order);
  const prevOrderBy = usePrevious(orderBy);

  const formChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSelectChange = (key, data) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: data || '',
      };
    });
  };

  const getFilterData = useCallback(() => {
    const filterData = [];
    Object.keys(formValue).forEach((key) => {
      if (formValue[key] && formValue[key] !== '') {
        filterData.push({
          key: key,
          value: formValue[key].value || formValue[key],
        });
      }
    });
    console.log(filterData);
    return filterData;
  }, [formValue]);

  const handleSearchClick = async () => {
    const { site, cust_grp, cust_name } = formValue;
    setErrorMsg(false);
    if (site || cust_grp || cust_name) {
      setLoaderOpen(true);
      const custGrpList = await makeRequest(
        ['user-management'],
        ['getCustomerList'],
        [
          {
            filterData: getFilterData(),
            page: 1,
          },
        ]
      );
      console.log(custGrpList);
      setLoaderOpen(false);
      setTableContent(custGrpList[0].data.body.results);
      setPageNum(1);
      setPageCount(Math.ceil(custGrpList[0].data.body.total / 10));
      setTotalContent(custGrpList[0].data.body.total);
    } else {
      //! Error message
      console.log('please at least input one');
      setErrorMsg(true);
    }
  };

  const handleSortClick = (key) => (event) => {
    if (tableContent.length > 0) {
      const isAsc = orderBy === key && order === 'asc';
      setOrderBy(key);
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  const handlePageChange = async (event, page) => {
    setLoaderOpen(true);
    const custGrpList = await makeRequest(
      ['user-management'],
      ['getCustomerList'],
      [
        {
          filterData: getFilterData(),
          page: page,
          sorting: {
            orderBy: orderBy,
            order: order,
          },
        },
      ]
    );
    setLoaderOpen(false);
    window.scrollTo(0, 0);
    setPageNum(page);
    setTableContent(custGrpList[0].data.body.results);
  };

  useEffect(() => {
    console.log(totalContent);
    if (totalContent <= 10) {
      setPagiDisable(true);
    } else {
      setPagiDisable(false);
    }
  }, [totalContent]);

  useEffect(() => {
    if (initFormOptions) {
      setFormItems((prev) => {
        const newState = prev.map((prevItem) => {
          prevItem.options = prevItem.key === 'site' ? initFormOptions[prevItem.key] : [];
          return prevItem;
        });
        return newState;
      });
    }
  }, [initFormOptions]);

  useEffect(() => {
    const callingApi = async () => {
      handleSetRequestList('customer_group', false, true);
      const res = await makeRequest(['user-management', 'user-management'], ['getCustomerGroupList', 'getCustGrpSiteList']);
      console.log(res);
      if (res) {
        const custGrpData = reduce(
          res[0].data.body,
          (result, item) => {
            const newItem = {
              key: 'cust_grp',
              text: `${item.name} (${item.v_site})`,
              value: item.id,
              site: item.v_site,
            };
            result.push(newItem);
            return result;
          },
          []
        );
        const siteList = reduce(
          res[1].data.body,
          (result, item) => {
            const newItem = {
              key: 'site',
              text: `${item.name} (${item.site})`,
              value: item.id,
            };
            result.push(newItem);
            return result;
          },
          []
        );
        setInitFormOptions({
          site: siteList,
          cust_grp: custGrpData,
        });
      }
      handleSetRequestList('customer_group', true);
    };
    callingApi();
  }, []);

  useEffect(() => {
    // console.log(totalContent)
    if (totalContent <= 10) {
      setPagiDisable(true);
    } else {
      setPagiDisable(false);
    }
  }, [totalContent]);

  useEffect(() => {
    if (formValue.site) {
      const { site } = formValue;
      console.log(site);
      setFormItems((prev) => {
        const newState = prev.map((prevItem) => {
          console.log(prevItem);
          if (prevItem.key === 'cust_grp') {
            prevItem.options = initFormOptions[prevItem.key].filter((item) => {
              return item.site === site.value;
            });
          }
          return prevItem;
        });
        return newState;
      });
    }
  }, [formValue.site]);

  useEffect(() => {
    console.log(prevOrderBy, prevOrder, order, orderBy);
    if (prevOrder && prevOrderBy) {
      console.log(order, orderBy);
      if (order && orderBy) {
        const updateSorting = async () => {
          setLoaderOpen(true);
          const custGrpList = await makeRequest(
            ['user-management'],
            ['getCustomerList'],
            [
              {
                filterData: getFilterData(),
                page: 1,
                sorting: {
                  orderBy: orderBy,
                  order: order,
                },
              },
            ]
          );
          setLoaderOpen(false);
          setPageNum(1);
          setTableContent(custGrpList[0].data.body.results);
        };
        updateSorting();
      }
    }
  }, [order, orderBy]);

  return (
    <div className={generalClasses.mainContent}>
      <Typography variant="h4">{t('menu:tab.cust_grp')}</Typography>
      <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
        <Grid container spacing={1}>
          {formItems.map(({ key, type, label, options }) => {
            return (
              <Grid container item xs={12} key={key} alignItems="center">
                <Grid item xs={12} md={2} lg={2}>
                  <Typography>{t(label)}</Typography>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                  {type === 'text' && (
                    <TextField
                      className={generalClasses.formInput}
                      placeholder={t(label)}
                      variant="outlined"
                      fullWidth
                      value={formValue[key] || ''}
                      onChange={(event) => formChange(key, event.target.value)}
                    />
                  )}
                  {type === 'select' && (
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        id={key}
                        value={formValue[key] || null}
                        className={generalClasses.autoComplete}
                        onChange={(event, data) => handleSelectChange(key, data)}
                        options={options}
                        noOptionsText={t('general:no_options')}
                        getOptionLabel={(option) => option.text}
                        getOptionSelected={(option, value) => {
                          return option.value === value.value;
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} variant="outlined" placeholder={t(label)} />;
                        }}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box mt={2}>
          <Grid container direction="row" alignItems="center" spacing={1} className={generalClasses.button}>
            <Grid item xs={12} md={3} lg={2}>
              <Button variant="contained" onClick={handleSearchClick}>
                {t('general:search')}
              </Button>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              {errorMsg && <Typography style={{ color: 'red' }}>{t('error:cust_grp_input_search')}</Typography>}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
        <Hidden xsDown>
          <Box component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {props.tableColumns.map(({ key, label }) => {
                      return (
                        <TableCell key={key} sortDirection={orderBy === key ? order : 'asc'} width={key === 'site' || key === 'cust_grp' ? '20%' : '60%'}>
                          <TableSortLabel active={orderBy === key} direction={orderBy === key ? order : 'asc'} onClick={handleSortClick(key)}>
                            {t(label)}
                          </TableSortLabel>
                        </TableCell>
                        // <TableCell key={key} width={key === 'site' || key === 'cust_grp' ? '20%' : '60%'}>
                        //   {t(label)}
                        // </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableContent.map((row, index) => (
                    <TableRow key={index}>
                      {props.tableColumns.map(({ key }) => {
                        return <TableCell key={key}>{row[key]}</TableCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Hidden>
        <Hidden smUp>
          {tableContent.map((row, index) => (
            <Box mb={2} key={index}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {props.tableColumns.map(({ key, label }) => (
                      <TableRow key={key}>
                        <TableCell style={{ width: 140 }}>
                          <Typography>{t(label)}</Typography>
                        </TableCell>
                        <TableCell>{row[key]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Hidden>
        <Box mt={2}>
          <Grid container justify="center">
            <Grid item>
              <Pagination count={pageCount} onChange={handlePageChange} page={pageNum} disabled={pagiDisable} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

CustGrp.defaultProps = {
  formItems: [
    {
      key: 'site',
      type: 'select',
      options: [],
      label: 'general:site',
    },
    {
      key: 'cust_grp',
      type: 'select',
      options: [],
      label: 'menu:tab.cust_grp',
    },
    {
      key: 'cust_name',
      type: 'text',
      label: 'general:cust_grp.cust_name',
    },
  ],
  tableColumns: [
    {
      key: 'site',
      label: 'general:site',
    },
    {
      key: 'cust_grp',
      label: 'menu:tab.cust_grp',
    },
    {
      key: 'cust_name',
      label: 'general:cust_grp.cust_name',
    },
  ],
};

CustGrp.propTypes = {
  formItems: PropTypes.array,
  tableColumns: PropTypes.array,
};

export default CustGrp;
