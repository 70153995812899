import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Select,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import generalStyles from '../styles/style';
import { makeRequest } from '../../api/api';
import AlertDialog from '../../components/dialog';
import { AppContext } from '../../context/context';
import useRequestList from '../../hooks/useRequestList';

//css***************************************************************************************
const styles = makeStyles((theme) => ({
  chip: {
    marginRight: 8,
    marginBottom: 8,
  },
  hidden: {
    display: 'none',
  },
}));

//table edit and delete btns*************************************************************
const TableAction = ({ id, email, deleteAction, editAction }) => {
  const { t } = useTranslation(['menu', 'general']);
  const generalClasses = generalStyles();
  const DialogRef = useRef();

  const editAdmin = async () => {
    const data = await makeRequest(['user-management'], ['getAdminList'], [{ email, role: '', jsonData: null }]);
    editAction(data);
  };

  const deleteAdmin = async () => {
    const data = await makeRequest(['user-management'], ['deleteAdmin'], [{ admin_id: id }]);
    deleteAction();
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" size="small" disableElevation className={generalClasses.tableActionButton} onClick={editAdmin}>
          {t('general:update')}
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" size="small" disableElevation className={generalClasses.tableActionButton} onClick={() => DialogRef.current.open()}>
          {t('general:delete')}
        </Button>
        <AlertDialog ref={DialogRef} text={t('general:confirm_delete')} confirm_action={deleteAdmin} />
      </Grid>
    </Grid>
  );
};

TableAction.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  deleteAction: PropTypes.func,
  editAction: PropTypes.func,
};

//pagination***********************************************************************************
function TablePaginationActions(props) {
  const classes = styles();
  const generalClasses = generalStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={generalClasses.paginationArrow}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

//main***************************************************************************************
const Account = () => {
  const { t } = useTranslation(['menu', 'general', 'error']);
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;
  const classes = styles();
  const generalClasses = generalStyles();
  const DialogRef = useRef();

  const [formValue, setFormValue] = useState({ type: 'normal' });
  const [adminRoleList, setAdminRoleList] = useState([]);
  // const [accountList, setAccountList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [custGrpList, setCustGrpList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkNormal, setCheckNormal] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [searchForm, setSearchForm] = useState(false);
  const [hideAdminRoleNormal, setHARN] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const handleSetRequestList = useRequestList();

  //fetching*****************************************************************************
  const fetchAdminList = async (args = {}) => {
    setLoaderOpen(true);
    const { email = '', role = '', jsonData = null } = args;
    const data = await makeRequest(['user-management'], ['getAdminList'], [{ email, role, jsonData }]);
    if (data) {
      Array.isArray(data[0].data.body) ? setAdminList(data[0].data.body) : setAdminList([]);
    }
    resetError();
    setPage(0);
    setLoaderOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      // setLoaderOpen(true);
      handleSetRequestList('account', false, true);
      const res = await makeRequest(
        ['user-management', 'user-management', 'user-management', 'user-management', 'user-management'],
        ['getAdminRoleList', 'getSiteList', 'getBuildingList', 'getCustomerGroupList', 'getAdminList'],
        ['', '', '', '', { email: '', role: '', jsonData: null }]
      );
      if (res) {
        Array.isArray(res[0].data.body) ? setAdminRoleList(res[0].data.body) : setAdminRoleList([]);
        Array.isArray(res[1].data.body) ? setSiteList(res[1].data.body) : setSiteList([]);
        Array.isArray(res[2].data.body) ? setSegmentList(res[2].data.body) : setSegmentList([]);
        Array.isArray(res[3].data.body) ? setCustGrpList(res[3].data.body) : setCustGrpList([]);
        Array.isArray(res[4].data.body) ? setAdminList(res[4].data.body) : setAdminList([]);
      }
      // setLoaderOpen(false);
      handleSetRequestList('account', true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [adminList]);

  //for adding, searching, editing*******************************************************
  const constructQueryProfile = () => {
    let { cust_grp, segment, site, email, type } = formValue;
    let jsonData = [];
    let includedSites = [];
    if (!!cust_grp) {
      cust_grp.map(({ value }) => {
        const arr = value.split('::');
        includedSites.push(arr[0]);
        jsonData.push({
          site: arr[0],
          pk_staff: arr[1],
        });
      });
    }
    if (!!segment) {
      segment.map(({ value }) => {
        const arr = value.split('::');
        includedSites.push(arr[0]);
        jsonData.push({
          site: arr[0],
          pk_corp: arr[1],
          pk_building: arr[2],
        });
      });
    }
    if (!!site) {
      site.map(({ value }) => {
        if (includedSites.indexOf(value) < 0) {
          includedSites.push(value);
          jsonData.push({
            site: value,
          });
        }
      });
    }
    if (!email) {
      email = '';
    }
    if (jsonData.length < 1) {
      jsonData = null;
    }
    return {
      role: type,
      email: typeof email === 'object' ? email.value : email,
      jsonData,
    };
  };

  const validateForm = () => {
    let { type, email, site } = formValue;
    if (!type || !email || !site) {
      // if (!type || !email || site.length < 1){
      setCheckEmpty(true);
      setCheckEmail(false);
      return false;
    } else if (!email.toLowerCase().endsWith('@hanglung.com') && !email.toLowerCase().endsWith('@hanglung.net')) {
      setCheckEmpty(false);
      setCheckEmail(true);
      return false;
    } else {
      setCheckEmpty(false);
      setCheckEmail(false);
      return true;
    }
  };

  //adding********************************************************************************
  const createAdmin = async (profile = constructQueryProfile()) => {
    const data = await makeRequest(['user-management'], ['createAdmin'], [profile], errorCheck);
    function errorCheck(response) {
      if (response.data.errorCode === 'admin_exist_please_use_update_function') {
        setCheckDuplicate(true);
      } else if (response.data.errorCode === 'normal_user_cannot_assgin_whole_site') {
        setCheckNormal(true);
      } else {
        fetchAdminList();
        resetFormValue();
        setSearchForm(false);
        return '';
      }
      return 1;
    }
    if (data[0].data) {
      if (data[0].data.body === '') {
        fetchAdminList();
        resetFormValue();
        setSearchForm(false);
      }
    }
  };

  const addAdminAction = () => {
    if (formValue.type === 'root') {
      DialogRef.current.open();
    } else {
      confirmRoot();
    }
  };

  const confirmRoot = () => {
    if (validateForm()) {
      createAdmin();
    }
  };
  //searching******************************************************************************
  const searchAdmin = async () => {
    const profile = constructQueryProfile();
    setSearchForm(true);
    fetchAdminList(profile);
  };

  //editing********************************************************************************
  const editAdminAction = () => {
    if (validateForm()) {
      editAdmin();
    }
  };

  const editAdmin = async (profile = constructQueryProfile()) => {
    const data = await makeRequest(['user-management'], ['updateAdmin'], [profile]);
    if (data[0].data.errorCode === 'normal_user_cannot_assgin_whole_site') {
      setCheckNormal(true);
    } else {
      fetchAdminList();
      resetFormValue();
      cancelBtnAction();
    }
  };

  const editAction = async (data) => {
    setEditForm(true);
    resetError();
    const { body } = data[0].data;
    if (!Array.isArray(body) || body.length === 0) return;
    const { customer_groups, email, role, segments, sites } = body[0];
    let siteArr = [],
      segmentArr = [],
      cust_grpArr = [];
    sites.map(({ project_name, site }) => {
      siteArr.push({
        text: `${project_name} (${site})`,
        value: site,
      });
    });
    segments.map(({ building_type, pk_corp, site, pk_building }) => {
      segmentArr.push({
        text: `${building_type} (${site})`,
        value: `${site}::${pk_corp}::${pk_building}`,
      });
    });
    customer_groups.map(({ ps_n_name, site, pk_staff }) => {
      cust_grpArr.push({
        text: `${ps_n_name} (${site})`,
        value: `${site}::${pk_staff}`,
      });
    });
    setFormValue({
      type: role,
      email: email,
      site: siteArr,
      segment: segmentArr,
      cust_grp: cust_grpArr,
    });
  };

  //form changing**************************************************************************
  const formChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    const { site, segment, cust_grp } = formValue;
    if (!!site) {
      let includedSites = [],
        segmentArr = [],
        custGrpArr = [];
      site.map(({ value }) => includedSites.push(value));
      if (!!segment) {
        segment.map((row) => {
          const { value } = row;
          if (includedSites.indexOf(value.split('::')[0]) >= 0) {
            segmentArr.push(row);
          }
        });
      }
      if (!!cust_grp) {
        cust_grp.map((row) => {
          const { value } = row;
          if (includedSites.indexOf(value.split('::')[0]) >= 0) {
            custGrpArr.push(row);
          }
        });
      }
      if ((!!segment && segment.length !== segmentArr.length) || (!!cust_grp && cust_grp.length !== custGrpArr.length)) {
        setFormValue((prev) => {
          return {
            ...prev,
            segment: segmentArr,
            cust_grp: custGrpArr,
          };
        });
      }
    }
  }, [formValue]);

  //reset functions*************************************************************************
  const cancelBtnAction = () => {
    setEditForm(false);
    setSearchForm(false);
    fetchAdminList();
    resetFormValue();
    resetError();
  };

  const resetFormValue = () => {
    setFormValue({
      type: 'normal',
      email: '',
      site: '',
      segment: '',
      cust_grp: '',
    });
  };

  const resetError = () => {
    setCheckEmpty(false);
    setCheckDuplicate(false);
    setCheckNormal(false);
    setCheckEmail(false);
  };

  //form items**************************************************************************
  const formItems = [
    {
      key: 'type',
      type: 'select',
      label: 'general:type',
      required: true,
      options: adminRoleList.map(({ type }) => ({
        text: `general:admin_group.${type}_admin`,
        value: type,
      })),
    },
    {
      key: 'email',
      type: 'text',
      label: 'general:email',
      required: true,
      // options:
      //     accountList.map(({ email }) => (
      //         { text: email, value: email }
      //     ))
    },
    {
      key: 'site',
      type: 'multiselect',
      label: 'general:site',
      required: true,
      options: siteList.map(({ project_name, site }) => ({
        text: project_name + ' (' + site + ')',
        value: site,
      })),
    },
    {
      key: 'segment',
      type: 'multiselect',
      label: 'general:segment',
      required: false,
      options: [
        ...segmentList.filter(({ v_site }) => {
          const { site } = formValue;
          if (!!site) {
            for (let i = 0; i < site.length; i++) {
              if (site[i].value === v_site) {
                return true;
              }
            }
          }
          return false;
        }),
      ].map(({ building_type, pk_building, pk_corp, v_site }) => ({
        text: building_type + ' (' + v_site + ')',
        value: v_site + '::' + pk_corp + '::' + pk_building,
      })),
    },
    {
      key: 'cust_grp',
      type: 'multiselect',
      label: 'menu:tab.cust_grp',
      required: false,
      options: [
        ...custGrpList.filter(({ v_site }) => {
          const { site } = formValue;
          if (!!site) {
            for (let i = 0; i < site.length; i++) {
              if (site[i].value === v_site) {
                return true;
              }
            }
          }
          return false;
        }),
      ].map(({ ps_n_name, pk_staff, v_site }) => ({
        text: ps_n_name + ' (' + v_site + ')',
        value: v_site + '::' + pk_staff,
      })),
    },
  ];

  //table items***************************************************************************
  const tableColumns = [
    { element: 'basic', key: 'role', label: 'general:type', width: '5%' },
    { element: 'basic', key: 'email', label: 'general:email', width: '20%' },
    { element: 'array', key: 'sites', label: 'general:site', width: '5%' },
    {
      element: 'chip',
      key: 'segments',
      label: 'general:segment',
      width: '30%',
    },
    {
      element: 'chip',
      key: 'customer_groups',
      label: 'menu:tab.cust_grp',
      width: '20%',
    },
    { element: 'action', key: 'action', label: 'general:action', width: '20%' },
  ];

  //pagination******************************************************************************
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, adminList.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //sorting*********************************************************************************
  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'sites') {
      if (b[orderBy][0].site.toLowerCase() < a[orderBy][0].site.toLowerCase()) {
        return -1;
      }
      if (b[orderBy][0].site.toLowerCase() > a[orderBy][0].site.toLowerCase()) {
        return 1;
      }
    } else if (orderBy === 'customer_groups') {
      if (b[orderBy].length === 0 || a[orderBy].length === 0) {
        if (b[orderBy].length === 0 && a[orderBy].length > 0) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (b[orderBy][0].site.toLowerCase() < a[orderBy][0].site.toLowerCase()) {
          return -1;
        }
        if (b[orderBy][0].site.toLowerCase() > a[orderBy][0].site.toLowerCase()) {
          return 1;
        }
      }
    } else if (orderBy === 'segments') {
      if (b[orderBy].length === 0 || a[orderBy].length === 0) {
        if (b[orderBy].length === 0 && a[orderBy].length > 0) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (b[orderBy][0].site.toLowerCase() < a[orderBy][0].site.toLowerCase()) {
          return -1;
        }
        if (b[orderBy][0].site.toLowerCase() > a[orderBy][0].site.toLowerCase()) {
          return 1;
        }
      }
    } else {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
    }

    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  //return*********************************************************************************
  return (
    <div className={`${hideAdminRoleNormal ? classes.hidden : ''} ${generalClasses.mainContent}`}>
      <Typography variant="h4">{t('menu:tab.account')}</Typography>
      <Box p={3} my={3} className={generalClasses.root}>
        <Grid container spacing={1}>
          {formItems.map(({ key, type, label, required, options }) => {
            return (
              <Grid item xs={12} key={key}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Typography style={{ minWidth: 130 }}>
                    {t(label)}
                    {required ? '*' : ''}
                  </Typography>
                  {type === 'select' && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        className={generalClasses.formInput}
                        native
                        id={key}
                        disabled={editForm ? true : false}
                        value={formValue[key] || options[0].value}
                        onChange={(event) => formChange(key, event.target.value)}
                      >
                        {options.map(({ text, value }) => (
                          <option key={value} value={value}>
                            {' '}
                            {t(text)}{' '}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {type === 'text' && (
                    <FormControl fullWidth>
                      <TextField
                        id={key}
                        className={generalClasses.formInput}
                        disabled={editForm ? true : false}
                        required={required}
                        placeholder={t(label)}
                        variant="outlined"
                        value={formValue[key] || ''}
                        onChange={(event) => formChange(key, event.target.value)}
                      />
                    </FormControl>
                  )}
                  {type === 'autocomplete' && (
                    <FormControl fullWidth>
                      <Autocomplete
                        id={key}
                        value={formValue[key] || null}
                        className={generalClasses.autoComplete}
                        required={required}
                        disabled={editForm ? true : false}
                        options={options}
                        onChange={(event, data) => {
                          formChange(key, data ? data.value : '');
                        }}
                        noOptionsText={t('general:no_options')}
                        // getOptionLabel={option => option.text}
                        getOptionLabel={(option) => (option.text ? option.text : option)}
                        getOptionSelected={(option, value) => option.email === value.email}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t(label)} />}
                      />
                    </FormControl>
                  )}
                  {type === 'multiselect' && (
                    <FormControl fullWidth>
                      <Autocomplete
                        id={key}
                        className={generalClasses.autoComplete}
                        multiple
                        value={formValue[key] || []}
                        options={options}
                        onChange={(event, value) => {
                          formChange(key, value);
                        }}
                        disableCloseOnSelect
                        noOptionsText={t('general:no_options')}
                        getOptionLabel={(option) => option.text}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={(option, { selected }) => {
                          return (
                            <React.Fragment>
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.text}
                            </React.Fragment>
                          );
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t(label)} />}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box mt={2}>
          <Grid container direction="row" alignItems="center" spacing={1} className={generalClasses.button}>
            <Grid item>
              <Button variant="contained" style={{ marginRight: 8 }} onClick={searchAdmin} className={editForm ? classes.hidden : ''}>
                {t('general:search')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ marginRight: 8 }}
                onClick={cancelBtnAction}
                className={editForm ? classes.hidden : searchForm ? '' : classes.hidden}
              >
                {t('general:back_to_all')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={addAdminAction} className={editForm ? classes.hidden : ''}>
                {t('general:add')}
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={editForm ? classes.hidden : ''}>
                {t('general:mandatory_fields')}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" style={{ marginRight: 8 }} onClick={editAdminAction} className={editForm ? '' : classes.hidden}>
                {t('general:confirm')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={cancelBtnAction} className={editForm ? '' : classes.hidden}>
                {t('general:cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Typography color="error">
                {checkEmpty && t('error:empty_field')}
                {checkDuplicate && t('error:admin_exist_please_use_update_function')}
                {checkNormal && t('error:normal_user_cannot_assgin_whole_site')}
                {checkEmail && t('error:check_email')}
              </Typography>
            </Grid>
          </Grid>
          <AlertDialog ref={DialogRef} text={t('error:root_admin_select_segment')} close_action={true} confirm_action={confirmRoot} />
        </Box>
      </Box>
      <Hidden xsDown>
        <Box p={3} my={4} className={generalClasses.root}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableColumns.map(({ key, label, width }) => {
                    let comp;
                    if (key === 'action') {
                      comp = (
                        <TableCell key={key} width={width} minwidth={width} style={{ whiteSpace: 'nowrap' }}>
                          {t(label)}
                        </TableCell>
                      );
                    } else {
                      comp = (
                        <TableCell key={key} sortDirection={orderBy === key ? order : false} width={width} minwidth={width} style={{ whiteSpace: 'nowrap' }}>
                          <TableSortLabel active={orderBy === key} direction={orderBy === key ? order : 'asc'} onClick={createSortHandler(key)}>
                            {t(label)}
                            {orderBy === key ? (
                              <span className={generalClasses.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      );
                    }
                    return comp;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stableSort(adminList, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : adminList
                ).map((row, index) => (
                  // {adminList.map((row, index) => (
                  <TableRow key={index}>
                    {tableColumns.map(({ element, key, width }) => {
                      if (element === 'action') {
                        return (
                          <TableCell key={key} width={width}>
                            <TableAction id={row.admin_id} email={row.email} deleteAction={cancelBtnAction} editAction={editAction} />
                          </TableCell>
                        );
                      } else if (element === 'chip') {
                        return (
                          <TableCell key={key} width={width}>
                            {!!row[key] && row[key].length > 0
                              ? key === 'segments'
                                ? row[key].map(({ site, pk_building, building_type }, index) =>
                                    pk_building === null ? '' : <Chip key={index} label={site + '-' + building_type} className={classes.chip} />
                                  )
                                : row[key].map(({ site, ps_n_name }, index) => <Chip key={index} label={site + '-' + ps_n_name} className={classes.chip} />)
                              : t('general:all')}
                          </TableCell>
                        );
                      } else if (element === 'array') {
                        return (
                          <TableCell key={key} width={width}>
                            {!!row[key] &&
                              row[key].map(({ site }, index) => (
                                <div key={index}>
                                  {site}
                                  {row[key].length === index + 1 ? '' : ','}
                                </div>
                              ))}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={key} width={width}>
                            {!!row[key] ? row[key] : t('general:all')}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className={generalClasses.paginationSpacer}
                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                    count={adminList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    labelRowsPerPage={t('general:rows_per_page')}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box p={3} mt={4} className={generalClasses.root}>
          {adminList.map((row, index) => (
            <Box key={index} mb={4}>
              <TableContainer key={index} component={Paper}>
                <Table>
                  <TableBody>
                    {tableColumns.map(({ element, key, label }) => (
                      <TableRow key={key}>
                        <TableCell style={{ width: 140 }}>
                          <Typography>{t(label)}</Typography>
                        </TableCell>
                        {element === 'action' ? (
                          <TableCell>
                            <TableAction id={row.admin_id} email={row.email} deleteAction={cancelBtnAction} editAction={editAction} />
                          </TableCell>
                        ) : element === 'chip' ? (
                          <TableCell>
                            {!!row[key] && row[key].length > 0
                              ? key === 'segments'
                                ? row[key].map(({ site, pk_building, building_type }, index) =>
                                    pk_building === null ? '' : <Chip key={index} label={site + '-' + building_type} className={classes.chip} />
                                  )
                                : row[key].map(({ site, ps_n_name }, index) => <Chip key={index} label={site + '-' + ps_n_name} className={classes.chip} />)
                              : t('general:all')}
                          </TableCell>
                        ) : element === 'array' ? (
                          <TableCell>
                            {!!row[key] &&
                              row[key].map(({ site }, index) => (
                                <div key={index}>
                                  {site}
                                  {row[key].length === index + 1 ? '' : ','}
                                </div>
                              ))}
                          </TableCell>
                        ) : (
                          <TableCell>{!!row[key] ? row[key] : t('general:all')}</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Box>
      </Hidden>
    </div>
  );
};

export default Account;
