import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeRequest } from '../api/api';

const SendSMS = () => {
  const [telephone, setTelephone] = useState({});

  const handleInputOnChange = (e, id) => {
    const value = e.target.value;
    setTelephone((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handeSendClick = async (id) => {
    const targetValue = telephone[id];
    console.log(targetValue);
    if (!targetValue) {
      alert('请输入电话号码');
      return;
    } else if (targetValue.substring(0, 2) !== '86') {
      alert('請输入正確格式(86XXXXXXXXXXX)');
      return;
    }
    const res = await makeRequest(['system-setting'], ['sendCNSMS'], [{ phoneNumber: targetValue, template: id }]);
    console.log(res);
    if (res) {
      if (res[0].data.body) {
        if (res[0].data.body.statusCode === 200 && res[0].data.body.ResponseCode === 'OK') {
          alert(`成功![${res[0].data.body.ResponseCode}]`);
        } else if (res[0].data.body.statusCode === 200 && res[0].data.body.ResponseCode === 'ALERT_LIMIT_DAY') {
          alert(`到达用户每日发送限制。[${res[0].data.body.ResponseCode}]`);
        } else if (res[0].data.body.statusCode === 400) {
          alert(`失败！5分钟再试 [${res[0].data.body.code}]`);
        }
      }
    }
  };

  return (
    <Box style={{ padding: '2em' }}>
      <Typography variant="h4" style={{ marginBottom: '10px' }}>
        发送SMS
      </Typography>
      <Grid container alignItems="center" style={{ padding: '10px 0', borderBottom: '1px solid lightgrey' }}>
        <Grid item>验证码(Verification code):</Grid>
        <Grid item style={{ margin: '0 20px', minWidth: '300px' }}>
          <TextField type="text" variant="outlined" fullWidth placeholder="电话号码(86XXXXXXXXXXX)" onChange={(e) => handleInputOnChange(e, 'code')} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handeSendClick('code')}>
            发送
          </Button>
        </Grid>
      </Grid>
      {/* <Grid container alignItems="center" style={{ padding: '10px 0', borderBottom: '1px solid lightgrey' }}>
        <Grid item>會員信息变更(Update Lease):</Grid>
        <Grid item style={{ margin: '0 20px', minWidth: '300px' }}>
          <TextField type="text" variant="outlined" fullWidth placeholder="电话号码(86XXXXXXXXXXX)" onChange={(e) => handleInputOnChange(e, 'update')} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handeSendClick('update')}>
            发送
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ padding: '10px 0', borderBottom: '1px solid lightgrey' }}>
        <Grid item>會員合同变更(Lease Expired):</Grid>
        <Grid item style={{ margin: '0 20px', minWidth: '300px' }}>
          <TextField type="text" variant="outlined" fullWidth placeholder="电话号码(86XXXXXXXXXXX)" onChange={(e) => handleInputOnChange(e, 'expired')} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handeSendClick('expired')}>
            发送
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ padding: '10px 0', borderBottom: '1px solid lightgrey' }}>
        <Grid item>會員停止使用(Deactivate):</Grid>
        <Grid item style={{ margin: '0 20px', minWidth: '300px' }}>
          <TextField type="text" variant="outlined" fullWidth placeholder="电话号码(86XXXXXXXXXXX)" onChange={(e) => handleInputOnChange(e, 'deactivate')} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handeSendClick('deactivate')}>
            发送
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ padding: '10px 0', borderBottom: '1px solid lightgrey' }}>
        <Grid item>會員管理人變更(Secondary to Primary):</Grid>
        <Grid item style={{ margin: '0 20px', minWidth: '300px' }}>
          <TextField type="text" variant="outlined" fullWidth placeholder="电话号码(86XXXXXXXXXXX)" onChange={(e) => handleInputOnChange(e, 'upgrade')} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handeSendClick('upgrade')}>
            发送
          </Button>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default SendSMS;
