import Cookies from 'js-cookie';
import { makeRequest, sendApiError } from '../api/api';

const handleLogout = async () => {
  const accountType = Cookies.get('accountType');
  const username = Cookies.get('username');

  Cookies.remove('accountType');
  Cookies.remove('accessToken');
  Cookies.remove('username');
  Cookies.remove('site_list');
  Cookies.remove('pk_lessee_list');
  Cookies.remove('drawerItems');

  if (accountType === 'tenant') {
    const res = await makeRequest(['user-auth'], ['userLogout'], [{ username }]);
    console.log(res);
    // window.location.pathname = '/';
    //* let the drawer to control
  } else if (accountType === 'account') {
    if (window.location.hostname === 'localhost') {
      window.location.href =
        'https://login.microsoftonline.com/42c1b969-dae8-4da7-8fc2-b994bfe10bb6/oauth2/logout?post_logout_redirect_uri=http://localhost:3000/adminlogin?logout=1';
    } else {
      window.location.href =
        'https://login.microsoftonline.com/42c1b969-dae8-4da7-8fc2-b994bfe10bb6/oauth2/logout?post_logout_redirect_uri=https://' +
        window.location.hostname +
        '/adminlogin?logout=1';
    }
  }
};
export default handleLogout;
