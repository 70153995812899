import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'quill/dist/quill.snow.css';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableSortLabel,
  TableBody,
  Paper,
  Hidden,
} from '@material-ui/core';
import generalStyles from '../styles/style';
import { AppContext } from '../../context/context';
import { makeRequest } from '../../api/api';
import { Pagination } from '@material-ui/lab';
import ReactHtmlParser from 'react-html-parser';
import { usePrevious } from 'react-delta';

const SystemLog = (props) => {
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;
  const { t } = useTranslation(['menu', 'general', 'error']);
  const generalClasses = generalStyles();
  const [formValue, setFormValue] = useState({});
  const [formItems, setFormItems] = useState(props.formItems);
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [tableContent, setTableContent] = useState([]);
  const [pagiDisable, setPagiDisable] = useState(false);
  const [totalContent, setTotalContent] = useState(0);

  const [notificationPageNum, setNotificationPageNum] = useState(1);
  const [notificationPageCount, setNotificationPageCount] = useState(0);
  const [notificationTableContent, setNotificationTableContent] = useState([]);
  const [notificationPagiDisable, setNotificationPagiDisable] = useState(false);
  const [notificationTotalContent, setNotificationTotalContent] = useState(0);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const prevOrder = usePrevious(order);
  const prevOrderBy = usePrevious(orderBy);

  const [orderNotification, setNotificationOrder] = useState('desc');
  const [orderByNotification, setNotificationOrderBy] = useState('created_at');
  const prevOrderNotification = usePrevious(orderByNotification);
  const prevOrderByNotification = usePrevious(orderByNotification);

  const formChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleSearchClick = async () => {
    setLoaderOpen(true);
    const res = await makeRequest(
      ['user-management', 'user-management'],
      ['getSystemLogList', 'getNotificationLog'],
      [
        {
          filterData: {
            user: formValue.user,
          },
          page: 1,
          sorting: {
            orderBy: orderBy,
            order: order,
          },
        },
        {
          filterData: {
            user: formValue.user,
          },
          page: 1,
          sorting: {
            orderBy: orderByNotification,
            order: orderNotification,
          },
        },
      ]
    );
    if (res) {
      if (Array.isArray(res[0].data.body.results)) {
        setTableContent(res[0].data.body.results);
        setPageNum(1);
        setPageCount(Math.ceil(res[0].data.body.total / 5));
        setTotalContent(res[0].data.body.total);
      }
      if (Array.isArray(res[1].data.body.results)) {
        setNotificationTableContent(res[1].data.body.results);
        setNotificationPageNum(1);
        setNotificationPageCount(Math.ceil(res[1].data.body.total / 5));
        setNotificationTotalContent(res[1].data.body.total);
        console.log('done');
      }
    }
    setLoaderOpen(false);
  };
  const getFilterData = useCallback(() => {
    const filterData = [];
    Object.keys(formValue).forEach((key) => {
      if (formValue[key] && formValue[key] !== '') {
        filterData.push({
          key: key,
          value: formValue[key].value || formValue[key],
        });
      }
    });
    return filterData;
  }, [formValue]);
  const handleSortClick = (key) => (event) => {
    if (tableContent.length > 0) {
      const isAsc = orderBy === key && order === 'asc';
      setOrderBy(key);
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };
  const handleNotificationSortClick = (key) => (event) => {
    if (tableContent.length > 0) {
      const isAsc = orderByNotification === key && orderNotification === 'asc';
      setNotificationOrderBy(key);
      setNotificationOrder(isAsc ? 'desc' : 'asc');
    }
  };
  const handlePageChange = async (event, page) => {
    setLoaderOpen(true);
    const systemLogList = await makeRequest(
      ['user-management'],
      ['getSystemLogList'],
      [
        {
          filterData: {
            user: formValue.user,
          },
          page: page,
          sorting: {
            orderBy: orderBy,
            order: order,
          },
        },
      ]
    );
    setLoaderOpen(false);
    setPageNum(page);
    setTableContent(systemLogList[0].data.body.results);
  };
  const handleNotificationPageChange = async (event, page) => {
    setLoaderOpen(true);
    const notificationLogList = await makeRequest(
      ['user-management'],
      ['getNotificationLog'],
      [
        {
          filterData: {
            user: formValue.user,
          },
          page: page,
          sorting: {
            orderBy: orderByNotification,
            order: orderNotification,
          },
        },
      ]
    );
    setLoaderOpen(false);
    setNotificationPageNum(page);
    setNotificationTableContent(notificationLogList[0].data.body.results);
  };
  useEffect(() => {
    console.log(totalContent);
    if (totalContent <= 5) {
      setPagiDisable(true);
    } else {
      setPagiDisable(false);
    }
  }, [totalContent]);
  useEffect(() => {
    console.log(notificationTotalContent);
    if (notificationTotalContent <= 5) {
      setNotificationPagiDisable(true);
    } else {
      setNotificationPagiDisable(false);
    }
  }, [notificationTotalContent]);
  useEffect(() => {
    console.log(prevOrderBy, prevOrder, order, orderBy);
    if (prevOrder && prevOrderBy) {
      console.log(order, orderBy);
      if (order && orderBy) {
        const updateSorting = async () => {
          setLoaderOpen(true);
          const systemLogList = await makeRequest(
            ['user-management'],
            ['getSystemLogList'],
            [
              {
                filterData: {
                  user: formValue.user,
                },
                page: 1,
                sorting: {
                  orderBy: orderBy,
                  order: order,
                },
              },
            ]
          );
          setLoaderOpen(false);
          setPageNum(1);
          setTableContent(systemLogList[0].data.body.results);
        };
        updateSorting();
      }
    }
  }, [order, orderBy]);
  useEffect(() => {
    console.log(prevOrderByNotification, prevOrderNotification, orderNotification, orderByNotification);
    if (prevOrderNotification && prevOrderByNotification) {
      console.log(orderNotification, orderByNotification);
      if (orderNotification && orderByNotification) {
        const updateSorting = async () => {
          setLoaderOpen(true);
          const notificationLogList = await makeRequest(
            ['user-management'],
            ['getNotificationLog'],
            [
              {
                filterData: {
                  user: formValue.user,
                },
                page: 1,
                sorting: {
                  orderBy: orderByNotification,
                  order: orderNotification,
                },
              },
            ]
          );
          setLoaderOpen(false);
          setNotificationPageNum(1);
          setNotificationTableContent(notificationLogList[0].data.body.results);
        };
        updateSorting();
      }
    }
  }, [orderNotification, orderByNotification]);
  return (
    <div className={generalClasses.mainContent}>
      <Typography variant="h4">{t('menu:tab.system_log')}</Typography>
      <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
        <Grid container spacing={1}>
          {formItems.map(({ key, type, label, options }) => {
            return (
              <Grid container item xs={12} key={key} alignItems="center">
                <Grid item xs={12} md={2} lg={2}>
                  <Typography>{t(label)}</Typography>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                  {type === 'text' && (
                    <TextField
                      className={generalClasses.formInput}
                      placeholder={t(label)}
                      variant="outlined"
                      fullWidth
                      value={formValue[key] || ''}
                      onChange={(event) => formChange(key, event.target.value)}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box mt={2}>
          <Grid container direction="row" alignItems="center" spacing={1} className={generalClasses.button}>
            <Grid item xs={12} md={3} lg={2}>
              <Button variant="contained" onClick={handleSearchClick}>
                {t('general:search')}
              </Button>
            </Grid>
            <Grid item xs={12} md={5} lg={5}></Grid>
          </Grid>
        </Box>
      </Box>

      <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
        <Typography style={{ marginBottom: 20 }} variant="h5">
          {t('menu:tab.system_log')}
        </Typography>
        <Hidden xsDown>
          <Box component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {props.tableColumns.map(({ key, label }) => {
                      return (
                        <TableCell key={key}>
                          <TableSortLabel active={orderBy === key} direction={orderBy === key ? order : 'asc'} onClick={handleSortClick(key)}>
                            {t(label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableContent.map((row, index) => (
                    <TableRow key={index}>
                      {props.tableColumns.map(({ key }) => {
                        if (key === 'action' || key === 'account_type') {
                          return <TableCell key={key}>{t('general:system_log.' + row[key])}</TableCell>;
                        } else {
                          return <TableCell key={key}>{ReactHtmlParser(row[key])}</TableCell>;
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Hidden>
        <Hidden smUp>
          {tableContent.map((row, index) => (
            <Box mb={2} key={index}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {props.tableColumns.map(({ key, label }) => (
                      <TableRow key={key}>
                        <TableCell style={{ width: 140 }}>
                          <Typography>{t(label)}</Typography>
                        </TableCell>
                        <TableCell>{row[key]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Hidden>
        <Box mt={2}>
          <Grid container justify="center">
            <Grid item>
              <Pagination count={pageCount} onChange={handlePageChange} page={pageNum} disabled={pagiDisable} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
        <Typography style={{ marginBottom: 20 }} variant="h5">
          {t('general:system_log.sms_email_log')}
        </Typography>
        <Hidden xsDown>
          <Box component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {props.notificationTableColumns.map(({ key, label }) => {
                      return (
                        <TableCell key={key} width="25%">
                          <TableSortLabel
                            active={orderByNotification === key}
                            direction={orderByNotification === key ? orderNotification : 'asc'}
                            onClick={handleNotificationSortClick(key)}
                          >
                            {t(label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationTableContent.map((row, index) => (
                    <TableRow key={index}>
                      {props.notificationTableColumns.map(({ key }) => {
                        if (key === 'notification_type' || key === 'account_type') {
                          if (row[key] == 'mfa') {
                            return <TableCell key={key}>{row['notification_content']}</TableCell>;
                          } else {
                            return <TableCell key={key}>{t('general:system_log.' + row[key])}</TableCell>;
                          }
                        } else {
                          return <TableCell key={key}>{row[key]}</TableCell>;
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Hidden>
        <Hidden smUp>
          {notificationTableContent.map((row, index) => (
            <Box mb={2} key={index}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {props.notificationTableColumns.map(({ key, label }) => (
                      <TableRow key={key}>
                        <TableCell style={{ width: 140 }}>
                          <Typography>{t(label)}</Typography>
                        </TableCell>
                        <TableCell>{row[key]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Hidden>
        <Box mt={2}>
          <Grid container justify="center">
            <Grid item>
              <Pagination count={notificationPageCount} onChange={handleNotificationPageChange} page={notificationPageNum} disabled={notificationPagiDisable} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

SystemLog.defaultProps = {
  formItems: [
    {
      key: 'user',
      type: 'text',
      label: 'general:system_log.user',
    },
  ],
  tableColumns: [
    {
      key: 'created_at',
      label: 'general:system_log.time',
    },
    {
      key: 'action',
      label: 'general:action',
    },
    {
      key: 'description_plain',
      label: 'general:system_log.description_plain',
    },
    {
      key: 'account_type',
      label: 'general:type',
    },
    {
      key: 'account_username',
      label: 'general:system_log.user',
    },
    {
      key: 'ip',
      label: 'general:system_log.ip',
    },
  ],
  notificationTableColumns: [
    {
      key: 'created_at',
      label: 'general:system_log.time',
    },
    {
      key: 'notification_type',
      label: 'general:system_log.notification_type',
    },
    {
      key: 'account_type',
      label: 'general:type',
    },
    {
      key: 'account_username',
      label: 'general:system_log.user',
    },
  ],
};

SystemLog.propTypes = {
  formItems: PropTypes.array,
  tableColumns: PropTypes.array,
  notificationTableColumns: PropTypes.array,
};

export default SystemLog;
