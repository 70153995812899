import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Divider, Drawer, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../context/context';
import LanguageBar from '../components/lang_menu';
import GroupIcon from '@material-ui/icons/Group';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SettingsIcon from '@material-ui/icons/Settings';
import DvrIcon from '@material-ui/icons/Dvr';
import PaymentIcon from '@material-ui/icons/Payment';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HistoryIcon from '@material-ui/icons/History';
import HelpIcon from '@material-ui/icons/Help';
import handleLogout from '../helper/handleLogout';
import { makeRequest } from '../api/api';
import { filter } from 'lodash';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  headerLogo: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
  menuItem: {
    '& svg': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  mainMenu: {
    '& p, & span': {
      fontSize: 14,
    },
  },
  menuList: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: 150,
    },
  },
  footer: {
    cursor: 'pointer',
  },
}));

const DrawerContent = (props) => {
  const classes = useStyles();
  const { items, handleClose } = props;
  const { t } = useTranslation(['menu']);
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [drawerItems, setDrawerItems] = useState();
  const context = useContext(AppContext);
  const { setLoginUsername, setAuth } = context;

  const handleDrawerLogout = () => {
    const accountType = Cookies.get('accountType');
    const username = decodeURIComponent(Cookies.get('username'));
    handleLogout();
    if (accountType === 'tenant') {
      setAuth(false);
      history.push('/');
      setLoginUsername(username);
    }
  };

  useEffect(() => {
    const setupDrawer = async () => {
      try {
        const res = await makeRequest(['user-management'], ['getMyAccount']);
        const userData = res[0].data;
        setUsername(userData.body[0].username.split('@')[0]);
        let filteredItem;
        if (userData.accountType === 'tenant') {
          filteredItem = filter(items, (o) => {
            return o.group === 'tenant' || o.group === 'billing';
          });
        } else if (userData.accountType === 'admin') {
          if (userData.adminRole === 'normal') {
            filteredItem = filter(items, (o) => {
              return (o.group === 'admin' && o.name !== 'account' && o.name !== 'system_setting') || o.group === 'billing' || o.name === 'help';
            });
          } else {
            filteredItem = filter(items, (o) => {
              return o.group === 'admin' || o.group === 'billing';
            });
          }
        }
        console.log(filteredItem);
        Cookies.set('drawerItems', JSON.stringify(filteredItem));
        setDrawerItems(filteredItem);
      } catch (e) {}
    };
    const cookiesDrawerItems = Cookies.get('drawerItems');
    if (Cookies.get('accessToken')) {
      if (cookiesDrawerItems) {
        setDrawerItems(JSON.parse(cookiesDrawerItems));
        setUsername(Cookies.get('username').split('@')[0]);
      } else {
        console.log('no cookies item');
        setupDrawer();
      }
    }
  }, [items]);

  return (
    <div className={classes.root}>
      <div className={classes.headerLogo}>
        <Box p={2}>
          <img src={'/img/hl_logo_name.png'} style={{ height: 50 }} alt="Logo" />
          <Typography
            align="center"
            style={{
              marginTop: '10px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: 200,
            }}
          >
            {t('menu:heading.eportal')}
            <br />
            {username && t('general:hello') + username}
          </Typography>
        </Box>
      </div>
      <List style={{ flex: 1 }} className={[classes.mainMenu, classes.menuList].join(' ')}>
        {drawerItems &&
          drawerItems.map(({ type, name, icon, group }, index) => {
            let comp;
            switch (type) {
              case 'heading':
                comp = (
                  <div key={index} id={`${type}-${name}`} data-group={group}>
                    <Box ml={2}>
                      <Typography>{t(`menu:heading.${name}`)}</Typography>
                    </Box>
                    <Divider />
                  </div>
                );
                break;
              case 'item':
                comp = (
                  <div key={index} id={`${type}-${name}`} data-group={group}>
                    <ListItem
                      button
                      onClick={() => {
                        if (handleClose) {
                          handleClose();
                        }
                        history.push(name);
                      }}
                    >
                      <ListItemIcon className={classes.menuItem}>
                        {icon === 'cust_grp' ? (
                          <GroupIcon />
                        ) : icon === 'account' ? (
                          <AccountCircleIcon />
                        ) : icon === 'tenant_list' ? (
                          <FormatListBulletedIcon />
                        ) : icon === 'system_setting' ? (
                          <SettingsIcon />
                        ) : icon === 'system_log' ? (
                          <DvrIcon />
                        ) : icon === 'tenant_account' ? (
                          <AccountCircleIcon />
                        ) : icon === 'preference' ? (
                          <SettingsIcon />
                        ) : icon === 'debit_note' ? (
                          <PaymentIcon />
                        ) : icon === 'payment_reminder' ? (
                          <NotificationsIcon />
                        ) : icon === 'monthly_statement' ? (
                          <EqualizerIcon />
                        ) : icon === 'amount_due' ? (
                          <MonetizationOnIcon />
                        ) : icon === 'payment_history' ? (
                          <HistoryIcon />
                        ) : icon === 'help' ? (
                          <HelpIcon />
                        ) : (
                          <div></div>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={t(`menu:tab.${name}`)} />
                    </ListItem>
                  </div>
                );
                break;
              default:
                break;
            }
            return comp;
          })}
      </List>
      <Grid container direction="row" justify="space-between" style={{ padding: '5px 15px' }} className={classes.mainMenu}>
        <Grid item>
          <LanguageBar />
        </Grid>
        <Grid item className={classes.footer}>
          <Typography noWrap onClick={handleDrawerLogout}>
            {t('menu:footer.logout')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

DrawerContent.defaultProps = {
  items: [
    { type: 'heading', name: 'admin', group: 'admin' },
    { type: 'item', name: 'cust_grp', icon: 'cust_grp', group: 'admin' },
    {
      type: 'item',
      name: 'account',
      icon: 'account',
      group: 'admin',
    },
    {
      type: 'item',
      name: 'tenant_list',
      icon: 'tenant_list',
      group: 'admin',
    },
    {
      type: 'item',
      name: 'system_setting',
      icon: 'system_setting',
      group: 'admin',
    },
    { type: 'item', name: 'system_log', icon: 'system_log', group: 'admin' },
    { type: 'heading', name: 'tenant', group: 'tenant' },
    {
      type: 'item',
      name: 'tenant_account',
      icon: 'tenant_account',
      group: 'tenant',
    },
    {
      type: 'item',
      name: 'preference',
      icon: 'preference',
      group: 'tenant',
    },
    { type: 'heading', name: 'billing', group: 'billing' },
    {
      type: 'item',
      name: 'debit_note',
      icon: 'debit_note',
      group: 'billing',
    },
    {
      type: 'item',
      name: 'payment_reminder',
      icon: 'payment_reminder',
      group: 'billing',
    },
    {
      type: 'item',
      name: 'monthly_statement',
      icon: 'monthly_statement',
      group: 'billing',
    },
    {
      type: 'item',
      name: 'amount_due',
      icon: 'amount_due',
      group: 'billing',
    },
    {
      type: 'item',
      name: 'payment_history',
      icon: 'payment_history',
      group: 'billing',
    },
    { type: 'item', name: 'help', icon: 'help', group: 'tenant' },
  ],
};

DrawerContent.propTypes = {
  width: PropTypes.number,
  items: PropTypes.array,
  handleClose: PropTypes.func,
};

const ResponsiveDrawer = ({ window, width, mobileDrawerOpen, mobileDrawerOnClose, handleClose }) => {
  const drawerStyles = makeStyles((theme) => {
    return {
      drawerPaper: {
        width,
        backgroundColor: theme.palette.primary.main,
        borderRight: 'none',
      },
    };
  });
  const drawerClasses = drawerStyles();
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Hidden xsDown>
        <Drawer
          classes={{
            paper: drawerClasses.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerContent width={width} />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileDrawerOpen}
          onClose={mobileDrawerOnClose}
          classes={{
            paper: drawerClasses.drawerPaper,
          }}
        >
          <DrawerContent width={width} handleClose={handleClose} />
        </Drawer>
      </Hidden>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  window: PropTypes.node,
  width: PropTypes.number,
  mobileDrawerOpen: PropTypes.bool,
  mobileDrawerOnClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ResponsiveDrawer;
