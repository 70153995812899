import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MainLayout from '../layouts/main';
import Landing from '../layouts/login/landing';

import AdminLogin from '../layouts/login/admin_login';
import TenantLogin from '../layouts/login/tenant_login';
import Register from '../layouts/login/register';

import CustGrp from '../layouts/admin/cust_grp';
import Account from '../layouts/admin/account';
import TenantList from '../layouts/admin/tenant_list';
import SystemSetting from '../layouts/admin/sys_setting';
import SystemLog from '../layouts/admin/sys_log';

import TenantAccount from '../layouts/tenant/tenant_account';
import Preference from '../layouts/tenant/preference';

import DebitNote from '../layouts/billing/debit_note';
import PaymentReminder from '../layouts/billing/payment_reminder';
import MonthlyStatement from '../layouts/billing/monthly_statement';
import AmountDue from '../layouts/billing/amount_due';
import PaymentHistory from '../layouts/billing/payment_history';
import Help from '../layouts/billing/help';

import SendSMS from '../layouts/sendSMS';

import SigninOpenId from '../layouts/login/signinopenid';

import { AppContext } from '../context/context';

const Routes = () => {
  const context = useContext(AppContext);
  const { auth } = context;

  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => (!auth ? <Landing {...props} MainContent={<TenantLogin {...props} />} /> : <Redirect to={{ pathname: '/debit_note' }} />)}
      />
      {/* <Route
                path="/login" exact
                render={(props) => <Landing {...props} MainContent={<TenantLogin {...props}/>} />}
            /> */}
      <Route
        path="/adminlogin"
        exact
        render={(props) => (!auth ? <Landing {...props} MainContent={<AdminLogin {...props} />} /> : <Redirect to={{ pathname: '/debit_note' }} />)}
      />
      <Route
        path="/r"
        exact
        render={(props) => (!auth ? <Landing {...props} MainContent={<Register {...props} />} /> : <Redirect to={{ pathname: '/debit_note' }} />)}
      />
      <Route
        path="/register"
        exact
        render={(props) => (!auth ? <Landing {...props} MainContent={<Register {...props} />} /> : <Redirect to={{ pathname: '/debit_note' }} />)}
      />

      <Route
        path="/cust_grp"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<CustGrp {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/account"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<Account {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/tenant_list"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<TenantList {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/system_setting"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<SystemSetting {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/system_log"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<SystemLog {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />

      <Route
        path="/tenant_account"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<TenantAccount {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/preference"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<Preference {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/debit_note"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<DebitNote {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/payment_reminder"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<PaymentReminder {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/monthly_statement"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<MonthlyStatement {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/amount_due"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<AmountDue {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/payment_history"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<PaymentHistory {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />
      <Route
        path="/help"
        exact
        render={(props) => (auth ? <MainLayout {...props} MainContent={<Help {...props} />} /> : <Redirect to={{ pathname: '/' }} />)}
      />

      <Route path="/signinopenid_1" exact render={(props) => (!auth ? <SigninOpenId {...props} /> : <Redirect to={{ pathname: '/debit_note' }} />)} />
      <Route path="/signin-openid_1" exact render={(props) => (!auth ? <SigninOpenId {...props} /> : <Redirect to={{ pathname: '/debit_note' }} />)} />

      <Route path="/sendSMS" exact render={(props) => <SendSMS {...props} />} />
      {/* <Route path="/debit_note" exact render={(props) => <MainLayout {...props} MainContent={<DebitNote {...props} />} />} />
      <Route path="/amount_due" exact render={(props) => <MainLayout {...props} MainContent={<AmountDue {...props} />} />} /> */}
      {/* <Redirect from="/:lng(en|sc|tc)?/*" to="/" /> */}
      <Redirect from="/*" to="/" />
    </Switch>
  );
};

export default Routes;
