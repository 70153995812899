import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import generalStyles from '../styles/style';
import { Box, FormControl, Grid, Select, Typography } from '@material-ui/core';
import { makeRequest } from '../../api/api';
import { AppContext } from '../../context/context';
import useRequestList from '../../hooks/useRequestList';

const Help = () => {
  const { t, i18n } = useTranslation(['menu', 'general', 'error']);
  const generalClasses = generalStyles();
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;
  const [siteList, setSiteList] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [locale, setLocale] = useState(i18n.language);
  const handleSetRequestList = useRequestList();

  //fetching data****************************************************************************
  const fetchContent = async () => {
    handleSetRequestList('help', false, true);
    let { site } = formValue;
    const res = await makeRequest(['user-management'], ['getHelp'], [{ site: site }]);
    if (res) {
      if (res[0].data.body.length > 0) {
        if (locale === 'tc' || locale === 'zh-TW' || locale === 'zh-HK') {
          setContentList(res[0].data.body[0].content_tc);
        } else if (locale === 'en' || locale === 'en-US' || locale === 'en-GB') {
          setContentList(res[0].data.body[0].content_en);
        } else if (locale === 'sc' || locale === 'zh-CN') {
          setContentList(res[0].data.body[0].content_sc);
        }
      } else {
        setContentList([]);
      }
    }
    handleSetRequestList('help', true);
  };

  const fetchData = async () => {
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['getSiteList']);
    if (res) {
      Array.isArray(res[0].data.body) ? setSiteList(res[0].data.body) : setSiteList([]);
    }
    setLoaderOpen(false);
  };

  //form changing****************************************************************************
  const formChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  //useEffect********************************************************************************
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (formValue.site) {
      fetchContent();
    }
  }, [formValue, locale]);

  useEffect(() => {
    if (siteList.length > 0) {
      formChange('site', siteList[0].id);
    }
  }, [siteList]);

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  return (
    <div className={generalClasses.mainContent}>
      <Typography variant="h4">{t('menu:tab.help')}</Typography>
      <Box p={3} my={3} className={generalClasses.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ minWidth: 55 }}>{t('general:site')}</Typography>
              <div style={{ maxWidth: '80%', width: '80%' }}>
                <FormControl variant="outlined" fullWidth>
                  <Select className={generalClasses.formInput} native onChange={(event) => formChange('site', event.target.value)}>
                    {siteList.map(({ project_name, site }) => (
                      <option key={site} value={site}>{`${project_name} (${site})`}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={10} style={{ marginTop: 10 }}>
            <div dangerouslySetInnerHTML={{ __html: contentList }}></div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Help;
