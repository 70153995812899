import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import generalStyles from '../styles/style';
import { Typography } from '@material-ui/core';
import BillingForm from '../billing/components/billing_form';
import ServerTable from '../../components/serverload_table';
import { makeRequest } from '../../api/api';
import { AppContext, TableContext } from '../../context/context';
import AlertDialog from '../../components/dialog';
import useRequestList from '../../hooks/useRequestList';

const TenantList = () => {
  const generalClasses = generalStyles();
  const { t } = useTranslation(['menu', 'general', 'error']);
  const DialogRef = useRef();
  const context = useContext(AppContext);
  const { setLoaderOpen, setDialogOpen } = context;

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageDisable, setPageDisable] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [formOptions, setFormOptions] = useState({
    tenant_type: ['primary', 'secondary'],
    status: ['active', 'inactive', 'pending'],
  });
  const [formValue, setFormValue] = useState({
    username: '',
    tenant_type: [],
    pk_pact_list: [],
    pk_lessee_list: [],
    pk_unit_list: [],
    checkNoPrimary: false,
    status: [],
  });
  const handleSetRequestList = useRequestList();

  //fetch data*******************************************************************************
  const fetchFormItems = async () => {
    handleSetRequestList('tenantListPage', false, true);
    const data = await makeRequest(['user-management'], ['getSearchTenantList']);
    if (data) {
      if (data[0].data.adminRole !== null) {
        Array.isArray(
          data[0].data.body[0].customerList ? formOptionsChange('pk_lessee_list', data[0].data.body[0].customerList) : formOptionsChange('pk_lessee_list', [])
        );
        Array.isArray(data[0].data.body[1].unitList ? formOptionsChange('pk_unit_list', data[0].data.body[1].unitList) : formOptionsChange('pk_unit_list', []));
        Array.isArray(data[0].data.body[2].pactList ? formOptionsChange('pk_pact_list', data[0].data.body[2].pactList) : formOptionsChange('pk_pact_list', []));
      } else {
        setDialogOpen(true);
        return;
      }
    }
    handleSetRequestList('tenantListPage', true);
  };

  const fetchTenantList = async (page) => {
    if (
      formValue.username.length < 1 &&
      formValue.tenant_type.length < 1 &&
      formValue.pk_pact_list.length < 1 &&
      formValue.pk_lessee_list.length < 1 &&
      formValue.pk_unit_list.length < 1 &&
      formValue.checkNoPrimary === false &&
      formValue.status.length < 1
    ) {
      DialogRef.current.open();
    } else {
      setLoaderOpen(true);
      const profile = { ...constructQueryProfile(), username: formValue['username'] };
      console.log(formValue);
      const data = await makeRequest(
        ['user-management'],
        ['getTenantList'],
        [{ ...profile, page: page, checkNoPrimary: formValue['checkNoPrimary'], sorting: { order, orderBy } }]
      );

      try {
        if (data) {
          if (Array.isArray(data[0].data.body.results)) {
            if (data[0].data.body.results.length !== 0) {
              setTenantList(data[0].data.body.results);
              setPageNo(page);
              setPageCount(Math.ceil(data[0].data.body.total / 10));
              setPageDisable(data[0].data.body.total <= 10 ? true : false);
            } else {
              setTenantList([]);
              setPageNo(1);
              setPageCount(1);
              setPageDisable(true);
            }
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoaderOpen(false);
      }
    }
  };

  //useEffect******************************************************************************
  useEffect(() => {
    fetchFormItems();
  }, []);

  useEffect(() => {
    if (order && orderBy) {
      fetchTenantList(1);
    }
  }, [order, orderBy]);

  //searching******************************************************************************
  const constructQueryProfile = () => {
    let jsonData = {};
    const arraylist = ['tenant_type', 'pk_pact_list', 'pk_lessee_list', 'pk_unit_list', 'status'];
    arraylist.map((key) => {
      jsonData[key] = formValue[key].reduce((acc, item, index) => {
        acc.push(item.value);
        return acc;
      }, []);
    });
    return jsonData;
  };

  //form items*****************************************************************************
  const formItems = [
    {
      key: 'username',
      type: 'text',
      label: 'general:login.username',
      xs: 12,
      sm: 12,
      lg: 12,
    },
    {
      key: 'tenant_type',
      type: 'multiselect',
      label: 'general:type',
      xs: 12,
      sm: 12,
      lg: 12,
      options: formOptions['tenant_type'].map((value) => ({ text: t(`general:system_log.${value}`), value: value })),
    },
    {
      key: 'pk_pact_list',
      type: 'multiselect',
      label: 'general:contract_no',
      xs: 12,
      sm: 12,
      lg: 12,
      options: formOptions['pk_pact_list']
        ? formOptions['pk_pact_list'].map(({ pk_pact, v_site, v_pact_num }) => ({ text: `${v_pact_num} (${v_site})`, value: `${v_site}::${pk_pact}` }))
        : [],
    },
    {
      key: 'pk_lessee_list',
      type: 'multiselect',
      label: 'general:cust_grp.cust_name',
      xs: 12,
      sm: 12,
      lg: 12,
      options: formOptions['pk_lessee_list']
        ? formOptions['pk_lessee_list'].map(({ pk_lessee, v_cust_name, v_site }) => ({ text: `${v_cust_name} (${v_site})`, value: `${v_site}::${pk_lessee}` }))
        : [],
    },
    {
      key: 'pk_unit_list',
      type: 'multiselect',
      label: 'general:unit',
      xs: 12,
      sm: 12,
      lg: 12,
      options: formOptions['pk_unit_list']
        ? formOptions['pk_unit_list'].map(({ pk_unit, unit_name, v_site }) => ({ text: `${unit_name} (${v_site})`, value: `${v_site}::${pk_unit}` }))
        : [],
    },
    {
      key: 'status',
      type: 'multiselect',
      label: 'general:tenant_account.status',
      xs: 12,
      sm: 12,
      lg: 12,
      options: formOptions['status'].map((value) => ({ text: t(`general:tenant_account.${value}`), value: value })),
    },
  ];

  //table items****************************************************************************
  const tableColumns = [
    { element: 'basic', key: 'primary_username', label: 'general:system_log.primary', width: '10%' },
    { element: 'basic', key: 'secondary_username', label: 'general:system_log.secondary', width: '10%' },
    { element: 'basic', key: 'tenant_type', label: 'general:tenant_account.account_type', width: '3%' },
    {
      element: 'chip',
      key: 'contract',
      label: 'general:contract_no',
      width: '70%',
    },
    {
      element: 'basic',
      key: 'status',
      label: 'general:tenant_account.status',
      width: '4%',
    },
    {
      element: 'basic',
      key: 'resend_times',
      label: 'general:tenant_account.resend_times',
      width: '3%',
    },
  ];

  //form changing**************************************************************************
  const formValueChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const formOptionsChange = (key, value) => {
    setFormOptions((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  //pagination*****************************************************************************
  const handlePageChange = (e, page) => {
    setPageNo(page);
    fetchTenantList(page);
  };

  const handleSortClick = (key) => (event) => {
    if (tenantList.length > 0) {
      console.log({ key, order, orderBy });
      const isAsc = orderBy === key && order === 'asc';
      setOrderBy(key);
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  const contextValue = {
    pageCount,
    pageNo,
    pageDisable,
    handlePageChange,
    order,
    orderBy,
    handleSortClick,
  };

  return (
    <div className={generalClasses.mainContent}>
      <TableContext.Provider value={contextValue}>
        <Typography variant="h4">{t('menu:tab.tenant_list')}</Typography>
        <BillingForm
          formItems={formItems}
          searchAction={() => {
            fetchTenantList(1);
          }}
          formValueChange={formValueChange}
          formValue={formValue}
          checkNoPrimary={true}
          downloadBtn={false}
        />
        <ServerTable tableColumns={tableColumns} tableList={tenantList} />
        <AlertDialog ref={DialogRef} text={t('error:cust_grp_input_search')} close_action={true} />
      </TableContext.Provider>
    </div>
  );
};

export default TenantList;
