import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    general: require('./locales/en/general.json'),
    menu: require('./locales/en/menu.json'),
    error: require('./locales/en/error.json'),
  },
  'en-US': {
    general: require('./locales/en/general.json'),
    menu: require('./locales/en/menu.json'),
    error: require('./locales/en/error.json'),
  },
  'en-GB': {
    general: require('./locales/en/general.json'),
    menu: require('./locales/en/menu.json'),
    error: require('./locales/en/error.json'),
  },
  tc: {
    general: require('./locales/tc/general.json'),
    menu: require('./locales/tc/menu.json'),
    error: require('./locales/tc/error.json'),
  },
  'zh-TW': {
    general: require('./locales/tc/general.json'),
    menu: require('./locales/tc/menu.json'),
    error: require('./locales/tc/error.json'),
  },
  'zh-HK': {
    general: require('./locales/tc/general.json'),
    menu: require('./locales/tc/menu.json'),
    error: require('./locales/tc/error.json'),
  },
  sc: {
    general: require('./locales/sc/general.json'),
    menu: require('./locales/sc/menu.json'),
    error: require('./locales/sc/error.json'),
  },
  'zh-CN': {
    general: require('./locales/sc/general.json'),
    menu: require('./locales/sc/menu.json'),
    error: require('./locales/sc/error.json'),
  },
};

// const options = {
// order: ['queryString'],
// order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
// lookupQuerystring: 'lng'
// lookupFromPathIndex: 0
// };

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    fallbackLng: 'sc', // use if detected lng is not available
    // detection: options,
    //load: 'languageOnly',
    // debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
