import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    cursor: 'pointer',
  },
}));

const LanguageBar = () => {
  const classes = useStyles();
  const { i18n } = useTranslation(['menu']);
  // const [languageMenu, setLanguageMenu] = React.useState(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // setLanguageMenu(null);
  };

  return (
    <Grid container>
      <Grid item onClick={() => changeLanguage('sc')} className={classes.footer}>
        <Typography>简</Typography>
      </Grid>
      <Grid item>
        <Typography>&nbsp;|&nbsp;</Typography>
      </Grid>
      <Grid item onClick={() => changeLanguage('tc')} className={classes.footer}>
        <Typography>繁</Typography>
      </Grid>
      <Grid item>
        <Typography>&nbsp;|&nbsp;</Typography>
      </Grid>
      <Grid item onClick={() => changeLanguage('en')} className={classes.footer}>
        <Typography>English</Typography>
      </Grid>
    </Grid>
  );
};

export default LanguageBar;
