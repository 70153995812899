import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import urlPraser from '@websanova/url/dist/es5-url.min.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AppContext } from '../../context/context';

const apiUrl = process.env.REACT_APP_API_LINK;

const SigninOpenId = () => {
  const context = useContext(AppContext);
  const { t } = useTranslation();
  const { setLoaderOpen, setAuth } = context;
  const [adError, setAdError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const returnCode = urlPraser('?code');
    const getAccessToken = async (code) => {
      setLoaderOpen(true);
      try {
        const res = await axios({
          method: 'post',
          url: `${apiUrl}/user-auth/`,
          data: {
            entry: 'getADToken',
            code,
          },
        });
        console.log(res);
        if (res.data) {
          const { token, message } = res.data;
          if (message === 'success') {
            Cookies.set('accessToken', token, { secure: true });
            Cookies.set('accountType', 'account', { secure: true });
            setAuth(true);
          } else if (message === 'get ad token error') {
            console.log('error in get ad');
            setLoaderOpen(false);
            setAdError(true);
            setTimeout(() => {
              history.push('/adminlogin?error=error_in_adminlogin');
            }, 2000);
          }
        }
      } catch (error) {
        console.log(error);
        setLoaderOpen(false);
        setAdError(true);
        setTimeout(() => {
          history.push('/adminlogin?error=error_in_adminlogin');
        }, 2000);
        //* get access token error
      }
    };
    if (returnCode) {
      getAccessToken(returnCode);
    }
  }, []);
  return <div>{adError && t('error:adminlogin_error')}</div>;
};

export default SigninOpenId;
