import { makeStyles } from '@material-ui/core/styles';

const generalStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
  },
  mainContent: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
  button: {
    '& button': {
      backgroundColor: theme.palette.button.main,
      color: '#fff',
    },
    '& button.MuiButtonBase-root.Mui-disabled': {
      backgroundColor: 'grey',
      color: 'dark grey',
    },
  },
  formInput: {
    '& input, & select': {
      padding: '9px 16px',
      backgroundColor: '#fff',
      borderRadius: 5,
    },
  },
  autoComplete: {
    '.MuiAutocomplete-inputRoot': {
      padding: 5,
    },
    backgroundColor: '#fff',
    borderRadius: 5,
  },
  tableActionButton: {
    backgroundColor: theme.palette.button.main,
    color: '#fff',
    borderRadius: 20,
    padding: '5px 12px 5px 12px',
  },
  paginationArrow: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paginationSpacer: {
    borderBottom: 0,
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
    // '& .MuiToolbar-gutters': {
    //   paddingLeft: 0,
    // },
    '& .MuiTablePagination-toolbar': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    '& .makeStyles-paginationArrow-12': {
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default generalStyles;
