import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import generalStyles from '../layouts/styles/style';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Hidden,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { TableContext } from '../context/context';

const ServerTable = ({ tableColumns = [], tableList = [], setData, padding, handlePdf }) => {
  //tableColumns, tableList compulsory; setData, padding for Opt-out
  const { t } = useTranslation(['general']);
  const generalClasses = generalStyles();
  const context = useContext(TableContext);
  const { pageCount, pageNo, pageDisable, handlePageChange, order, orderBy, handleSortClick } = context;

  const handleChange = (event, pk_rent_lessee) => {
    setData((prev) => {
      let rowList = prev.reduce((acc, item, index) => {
        if (item.pk_rent_lessee === pk_rent_lessee) {
          const newItem = {
            ...item,
            opt_out: event.target.checked ? 1 : 0,
          };
          acc.push(newItem);
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
      return rowList;
    });
  };

  return (
    <div>
      <Box p={padding ? padding : 3} my={4} className={generalClasses.root}>
        <Hidden xsDown>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableColumns &&
                    tableColumns.map(({ key, label, width }) => {
                      let comp;
                      if (key === 'action') {
                        comp = (
                          <TableCell key={key} style={{ whiteSpace: 'nowrap', width: width }}>
                            {t(label)}
                          </TableCell>
                        );
                      } else {
                        comp = (
                          <TableCell key={key} sortDirection={orderBy === key ? order : 'asc'} style={{ whiteSpace: 'nowrap' }}>
                            <TableSortLabel active={orderBy === key} direction={orderBy === key ? order : 'asc'} onClick={handleSortClick(key)}>
                              {t(label)}
                            </TableSortLabel>
                          </TableCell>
                        );
                      }
                      return comp;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList &&
                  tableList.map((row, index) => (
                    <TableRow key={index}>
                      {tableColumns.map(({ element, key }) => {
                        let comp;
                        if (element === 'action') {
                          comp = (
                            <TableCell key={key}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={generalClasses.tableActionButton}
                                    onClick={() => handlePdf('previewPdf', row)}
                                  >
                                    {t('general:preview')}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={generalClasses.tableActionButton}
                                    onClick={() => handlePdf('downloadPdf', row)}
                                  >
                                    {t('general:download')}
                                  </Button>
                                </Grid>
                              </Grid>
                            </TableCell>
                          );
                        } else if (element === 'basic') {
                          if (key === 'primary_username' && row[key] === '') {
                            comp = <TableCell key={key}>{t('general:secondary_without_primary')}</TableCell>;
                          } else if (key === 'tenant_type') {
                            comp = <TableCell key={key}>{t(`general:tenant_account.${row[key]}`)}</TableCell>;
                          } else if (key === 'status') {
                            comp = <TableCell key={key}>{t(`general:tenant_account.${row[key]}`)}</TableCell>;
                          } else {
                            comp = <TableCell key={key}>{row[key]}</TableCell>;
                          }
                        } else if (element === 'checkbox') {
                          comp = (
                            <TableCell key={key}>
                              <Checkbox
                                disabled={row[key] === 1 && row['handler'] === 1 ? true : false}
                                checked={row[key] === 1 ? true : false}
                                onChange={(e) => {
                                  handleChange(e, row.pk_rent_lessee);
                                }}
                              />
                            </TableCell>
                          );
                        } else if (element === 'chip') {
                          comp = (
                            <TableCell key={key}>
                              {row.pactList.map(({ pk_pact, v_pact_num, v_cust_name, unit_code, v_brand_name }) => (
                                <Chip
                                  key={pk_pact + v_pact_num + v_cust_name + unit_code + v_brand_name}
                                  label={`${v_pact_num} - ${v_cust_name} - ${unit_code} - ${v_brand_name}`}
                                  style={{ marginBottom: '5px' }}
                                />
                              ))}
                            </TableCell>
                          );
                        }
                        return comp;
                      })}
                    </TableRow>
                  ))}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
        <Hidden smUp>
          {tableList.length > 0 &&
            tableList.map((row, index) => (
              <Box key={index} my={4}>
                <TableContainer key={index} component={Paper}>
                  <Table>
                    <TableBody>
                      {tableColumns.map(({ element, key, label }) => (
                        <TableRow key={key}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            <Typography>{t(label)}</Typography>
                          </TableCell>
                          {element === 'action' && (
                            <TableCell key={key}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={generalClasses.tableActionButton}
                                    onClick={() => handlePdf('previewPdf', row)}
                                  >
                                    {t('general:preview')}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={generalClasses.tableActionButton}
                                    onClick={() => handlePdf('downloadPdf', row)}
                                  >
                                    {t('general:download')}
                                  </Button>
                                </Grid>
                              </Grid>
                            </TableCell>
                          )}
                          {element === 'basic' &&
                            (key === 'primary_username' && row[key] === '' ? (
                              <TableCell key={key}>{t('general:secondary_without_primary')}</TableCell>
                            ) : key === 'tenant_type' ? (
                              <TableCell key={key}>{t(`general:tenant_account.${row[key]}`)}</TableCell>
                            ) : key === 'status' ? (
                              <TableCell key={key}>{t(`general:tenant_account.${row[key]}`)}</TableCell>
                            ) : (
                              <TableCell key={key}>{row[key]}</TableCell>
                            ))}
                          {element === 'checkbox' && (
                            <TableCell key={key}>
                              <Checkbox
                                disabled={row[key] === 1 && row['handler'] === 1 ? true : false}
                                checked={row[key] === 1 ? true : false}
                                onChange={(e) => {
                                  handleChange(e, row.pk_rent_lessee);
                                }}
                              />
                            </TableCell>
                          )}
                          {element === 'chip' && (
                            <TableCell key={key}>
                              {row.pactList.map(({ pk_pact, v_pact_num, v_cust_name, unit_code, v_brand_name }) => (
                                <Chip
                                  key={pk_pact + v_pact_num + v_cust_name + unit_code + v_brand_name}
                                  label={`${v_pact_num} - ${v_cust_name} - ${unit_code} - ${v_brand_name}`}
                                  style={{ marginBottom: '5px' }}
                                />
                              ))}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
        </Hidden>

        <Box mt={2}>
          <Grid container justify="center">
            <Grid item>
              <Pagination count={pageCount} page={pageNo} onChange={handlePageChange} disabled={pageDisable} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

ServerTable.propTypes = {
  tableColumns: PropTypes.array,
  tableList: PropTypes.array,
  setData: PropTypes.func,
  padding: PropTypes.string,
  handlePdf: PropTypes.func,
};

export default ServerTable;
