import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import generalStyles from '../styles/style';
import { Box, Button, FormControl, Grid, Select, Typography } from '@material-ui/core';
import { makeRequest } from '../../api/api';
import { AppContext } from '../../context/context';
import { usePrevious } from 'react-delta';
import useRequestList from '../../hooks/useRequestList';

import 'quill/dist/quill.snow.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const styles = makeStyles((theme) => ({
  qlContainer: {
    '& .ql-container': {
      height: 300,
      backgroundColor: '#fff',
    },
  },
}));

const SystemSetting = () => {
  const { t } = useTranslation(['menu', 'general', 'error']);
  const classes = styles();
  const generalClasses = generalStyles();
  const context = useContext(AppContext);
  const { setLoaderOpen, setSnackBar } = context;
  const [formValue, setFormValue] = useState({});
  const [langSelection, setlangSelection] = useState('content_sc');
  const [siteList, setSiteList] = useState([]);
  const [roleType, setRoleType] = useState('');
  const [tmpFormValue, setTmpFormValue] = useState('');
  const prevTmpFormValue = usePrevious(tmpFormValue);
  const prevLngSec = usePrevious(langSelection);
  const handleSetRequestList = useRequestList();

  //fetching data****************************************************************************
  const fetchContent = async () => {
    setLoaderOpen(true);
    let { site } = formValue;
    if (site) {
      const data = await makeRequest(['user-management'], ['getHelp'], [{ site: site }]);
      if (data[0].data.body.length > 0) {
        setFormValue((prev) => {
          return {
            ...prev,
            content_sc: data[0].data.body[0].content_sc === null ? '' : data[0].data.body[0].content_sc,
            content_tc: data[0].data.body[0].content_tc === null ? '' : data[0].data.body[0].content_tc,
            content_en: data[0].data.body[0].content_en === null ? '' : data[0].data.body[0].content_en,
          };
        });
        setTmpFormValue(data[0].data.body[0][langSelection]);
      } else {
        setFormValue((prev) => {
          return {
            ...prev,
            content_sc: '',
            content_tc: '',
            content_en: '',
          };
        });
        setTmpFormValue('');
      }
    }
    setLoaderOpen(false);
  };

  const fetchData = async () => {
    handleSetRequestList('system_setting', false, true);
    const data = await makeRequest(['user-management'], ['getSiteList']);
    Array.isArray(data[0].data.body) ? setSiteList(data[0].data.body) : setSiteList([]);
    setRoleType(data[0].data.adminRole);
    handleSetRequestList('system_setting', true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (siteList.length > 0) {
      formChange('site', siteList[0].site);
    }
  }, [siteList]);

  useEffect(() => {
    if (formValue.site) {
      fetchContent();
    }
  }, [formValue.site]);

  useEffect(() => {
    if (tmpFormValue !== prevTmpFormValue && tmpFormValue !== '' && prevTmpFormValue !== '') {
      formChange(langSelection, tmpFormValue);
    }
  }, [tmpFormValue]);

  useEffect(() => {
    if (prevLngSec !== langSelection && prevLngSec) {
      setTmpFormValue(formValue[langSelection]);
    }
  }, [langSelection]);

  const updateHelp = async () => {
    setLoaderOpen(true);
    let { site, content_tc, content_en, content_sc } = formValue;
    await makeRequest(['user-management'], ['updateHelp'], [{ site: site, content_tc: content_tc, content_en: content_en, content_sc: content_sc }]);
    setLoaderOpen(false);
    setSnackBar((prev) => ({
      ...prev,
      open: true,
      message: t('error:success_message.update_completed'),
    }));
  };

  //form changing**************************************************************************
  const formChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleChange = (value) => {
    // formChange(langSelection, value);
    setTmpFormValue(value);
  };

  return (
    <div className={generalClasses.mainContent}>
      <Typography variant="h4">{t('menu:tab.system_setting')}</Typography>
      {roleType !== 'normal' && (
        <Box p={3} my={4} className={generalClasses.root}>
          <Typography style={{ marginBottom: 20 }} variant="h5">
            {t('menu:tab.help')}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Typography style={{ marginRight: 20 }}>{t('general:site')}</Typography>
                <div>
                  <FormControl variant="outlined" fullWidth style={{ display: 'inline' }}>
                    <Select className={generalClasses.formInput} native onChange={(event) => formChange('site', event.target.value)}>
                      {siteList.map(({ project_name, site }) => (
                        <option key={site} value={site}>{`${site} - ${project_name}`}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" style={{ display: 'inline', marginLeft: 20 }}>
                    <Select className={generalClasses.formInput} native onChange={(event) => setlangSelection(event.target.value)}>
                      <option value="content_sc">简</option>
                      <option value="content_tc">繁</option>
                      <option value="content_en">English</option>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ margin: '25px 0' }}>
              <div>
                <ReactQuill className={classes.qlContainer} value={tmpFormValue} onChange={handleChange} />
              </div>
            </Grid>
            <Grid item className={generalClasses.button}>
              <Button variant="contained" onClick={updateHelp}>
                {t('general:update')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default SystemSetting;
