import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
  FormControl,
  Checkbox,
  Paper,
  Hidden,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import generalStyles from '../styles/style';
import { AppContext } from '../../context/context';
import { makeRequest } from '../../api/api';
import { find, pick, filter } from 'lodash';
import AlertDialog from '../../components/dialog';
import useRequestList from '../../hooks/useRequestList';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TableActionBtn = (props) => {
  const { t } = useTranslation();
  const generalClasses = generalStyles();
  const { id, justify, updateOnClick, deleteOnClick, tenant_id } = props;
  return (
    <Grid container spacing={1} justify={justify} wrap="nowrap">
      <Grid item>
        <Button
          id={id}
          data-tenant-id={tenant_id}
          variant="contained"
          size="small"
          disableElevation
          onClick={updateOnClick}
          className={generalClasses.tableActionButton}
        >
          {t('general:update')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          id={id}
          data-tenant-id={tenant_id}
          variant="contained"
          size="small"
          disableElevation
          onClick={deleteOnClick}
          className={generalClasses.tableActionButton}
        >
          {t('general:delete')}
        </Button>
      </Grid>
    </Grid>
  );
};

TableActionBtn.propTypes = {
  id: PropTypes.string,
  justify: PropTypes.string,
  tenant_id: PropTypes.number,
  updateOnClick: PropTypes.func,
  deleteOnClick: PropTypes.func,
};

const TenantAccount = (props) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const { setLoaderOpen, setSnackBar } = context;
  const generalClasses = generalStyles();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [disableEmailPhone, setDisableEmailPhone] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [contractOptions, setContractOptions] = useState([]);
  const [tableContent, setTableContent] = useState([]);
  const [error, setError] = useState();
  const [tenantType, setTenantType] = useState('');
  const dialogRef = useRef();
  const dialogRef2 = useRef();
  const handleSetRequestList = useRequestList();

  useEffect(() => {
    const getPriContractSecTenantList = async () => {
      handleSetRequestList('tenant_account', false, true);
      const res = await makeRequest(['user-management', 'user-management'], ['getPriTenantList', 'getSecTenantList']);
      if (res) {
        //* getPriTenantList data handle
        const list = res[0].data.body;
        setPriTenantList(list);
        //* getSecTenantList data handle
        const list2 = res[1].data.body;
        setTenantType(res[1].data.tenantType);
        setSecTenantList(list2);
      }
      handleSetRequestList('tenant_account', true);
    };
    getPriContractSecTenantList();
  }, []);

  const setPriTenantList = (list) => {
    const contractList = list.reduce((result, item) => {
      const tableObj = {
        id: item.pk_rent_lessee,
        value: `${item.project_name}-${item.v_pact_num}-${item.building_type}-${item.v_cust_name}-${
          item.building_type === '办公楼' || item.building_type === 'RTSA' ? '' : item.v_brand_name
        }-${item.unit_name}`,
        pk_rent_lessee: item.pk_rent_lessee,
        pk_pact: item.pk_pact,
        pk_lessee: item.pk_lessee,
        pk_corp: item.pk_corp,
      };
      result.push(tableObj);
      return result;
    }, []);
    setContractOptions(contractList);
  };

  const setSecTenantList = (list) => {
    const tableContentList = list.reduce((result, item) => {
      const tableObj = {
        id: `${item.tenant_type}-${item.tenant_id}`,
        tenant_id: item.tenant_id,
        tenant_type: item.tenant_type,
        email_phone: item.username,
        account_type: `general:tenant_account.${item.tenant_type}`,
        status: `general:tenant_account.${item.status}`,
        pactList: item.pactList,
        optionValue: item.pactList.reduce((pact_result, pact_item) => {
          const pickItem = pick(pact_item, ['pk_corp', 'pk_lessee', 'pk_pact', 'pk_rent_lessee']);
          pact_result.push(pickItem);
          return pact_result;
        }, []),
        contract_no: item.pactList.reduce((pact_result, pact_item) => {
          const text = `${pact_item.project_name}-${pact_item.v_pact_num}-${pact_item.building_type}-${pact_item.v_cust_name}-${
            pact_item.building_type === '办公楼' || pact_item.building_type === 'RTSA' ? '' : pact_item.v_brand_name
          }-${pact_item.unit_name}`;
          pact_result.push(text);
          return pact_result;
        }, []),
      };
      result.push(tableObj);
      return result;
    }, []);
    setTableContent(tableContentList);
  };

  const getSecTenantList = async () => {
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['getSecTenantList']);
    const list = res[0].data.body;
    setSecTenantList(list);
    setLoaderOpen(false);
  };

  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const targetId = target.getAttribute('id');
    const targetVal = target.value;
    setInputValue((prev) => {
      return {
        ...prev,
        [targetId]: targetVal,
      };
    });
  };

  const handleAutoCompleteChange = (id, value) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  };

  const createSecTenantReq = async () => {
    const { email_phone, contract_no } = inputValue;
    setError(null);
    setLoaderOpen(true);
    if (email_phone && email_phone !== '' && contract_no && contract_no.length !== 0) {
      const submitData = {
        username: email_phone,
        lessee: contract_no.reduce((result, item) => {
          const pickItem = pick(item, ['pk_rent_lessee', 'pk_lessee', 'pk_pact', 'pk_corp']);
          result.push(pickItem);
          return result;
        }, []),
      };
      const res = await makeRequest(['user-management'], ['createSecTenant'], [submitData], errorCheck);
      console.log(res);
      if (res) {
        if (res[0].data.body === 'success create account') {
          getSecTenantList();
          setInputValue({});
        }
      }
      function errorCheck(response) {
        if (response.data && response.data.errorCode !== 'something_went_wrong' && response.data.body === 'success create account') {
          getSecTenantList();
          setInputValue({});
        } else if (response.data.errorCode === 'phone_or_email_not_valid') {
          setError('error:email_phone_error');
        } else if (response.data.errorCode === 'something_went_wrong') {
          setError('error:something_went_wrong');
        } else if (response.data.errorCode === 'one_primary_account_only_can_have_five_secondary_account') {
          setError('error:only_have_five_secondary_account');
        } else {
          return '';
        }
        return 1;
      }
    } else if (email_phone === '' || !email_phone) {
      setError('error:empty_username');
    } else if (!contract_no || contract_no.length === 0) {
      setError('error:empty_contract_no');
    }
    setLoaderOpen(false);
  };

  const handleCreateSecTenant = async () => {
    const { email_phone } = inputValue;
    if (!validateEmail(email_phone) && isNaN(email_phone)) {
      //* is not valid email format
      setError('error:error_email_format');
      return;
    }
    if (email_phone.includes('+')) {
      //* phone number
      console.log(email_phone);
      const countryCode = email_phone.substring(1, 3);
      console.log(countryCode);
      if (countryCode === '86') {
        console.log(email_phone.length);
        if (email_phone.length !== 14) {
          dialogRef2.current.open();
          return;
        }
      }
    }
    createSecTenantReq();
  };

  const handleUpdateSecTenant = async () => {
    setError(null);
    const { tenant_id, email_phone, contract_no } = inputValue;
    if (email_phone && email_phone !== '' && contract_no && contract_no.length !== 0) {
      const submitData = {
        username: email_phone,
        tenant_id: tenant_id,
        lessee: contract_no.reduce((result, item) => {
          const pickItem = pick(item, ['pk_rent_lessee', 'pk_lessee', 'pk_pact', 'pk_corp']);
          result.push(pickItem);
          return result;
        }, []),
      };
      setLoaderOpen(true);
      const res = await makeRequest(['user-management'], ['updateSecTenant'], [submitData]);
      if (res[0].data && res[0].data.body === 'success update account') {
        getSecTenantList();
        setUpdateStatus(false);
        setDisableEmailPhone(false);
        setInputValue({});
      }
      setLoaderOpen(false);
    } else if (email_phone === '' || !email_phone) {
      setError('error:empty_username');
    } else if (!contract_no || contract_no.length === 0) {
      setError('error:empty_contract_no');
    }
  };

  const handleUpdateClick = (e) => {
    const target = e.currentTarget;
    const targetId = target.getAttribute('id');
    const targetData = find(tableContent, (o) => o.id === targetId);
    const email_phone = targetData.email_phone;
    const option_value = targetData.optionValue;
    const tenant_id = targetData.tenant_id;
    const toUpdateOptions = filter(contractOptions, (o) => {
      let matchOption = false;
      option_value.forEach((item) => {
        if (item.pk_corp === o.pk_corp && item.pk_lessee === o.pk_lessee && item.pk_pact === o.pk_pact && item.pk_rent_lessee === o.pk_rent_lessee) {
          matchOption = true;
        }
      });
      return matchOption;
    });
    setInputValue((prev) => {
      return {
        ...prev,
        tenant_id,
        email_phone,
        contract_no: toUpdateOptions,
      };
    });
    setUpdateStatus(true);
    setDisableEmailPhone(true);
  };

  const handleUpdateCancelClick = () => {
    setUpdateStatus(false);
    setInputValue({});
  };

  const handleDeleteClick = (e) => {
    const target = e.currentTarget;
    const tenant_id = target.dataset.tenantId;
    setInputValue((prev) => {
      return {
        ...prev,
        tenant_id,
      };
    });
    dialogRef.current.open();
  };

  const handleConfirmDeleteAction = async () => {
    const { tenant_id } = inputValue;
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['deleteSecTenant'], [{ tenant_id }]);
    setLoaderOpen(false);
    if (res[0].data && res[0].data.body === 'success delete account') {
      getSecTenantList();
      setSnackBar((prev) => {
        return {
          ...prev,
          open: true,
          message: t('general:tenant_account.deleted'),
        };
      });
    }
  };

  return (
    tenantType !== '' && (
      <div className={generalClasses.mainContent}>
        <Typography variant="h4">{t('menu:tab.tenant_account')}</Typography>
        {tenantType !== 'secondary' && (
          <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant="h5">{t('general:tenant_account.add_new_secondary')}</Typography>
                </Box>
              </Grid>
              {props.add_field.map((item) => {
                return (
                  <Grid container item alignItems="center" key={item.id}>
                    <Grid item xs={12} md={2}>
                      <Typography>{t(item.label)} *</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      {item.type === 'input' && (
                        <TextField
                          id={item.id}
                          className={generalClasses.formInput}
                          type="text"
                          variant="outlined"
                          fullWidth
                          value={inputValue[item.id] || ''}
                          placeholder={`${t(item.label)}(+86XXXXXXXXXXX)`}
                          onChange={handleInputChange}
                          disabled={disableEmailPhone}
                        />
                      )}
                      {item.type === 'autocomplete' && (
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            multiple
                            id={item.id}
                            className={generalClasses.autoComplete}
                            options={contractOptions}
                            value={inputValue[item.id] || []}
                            disableCloseOnSelect
                            noOptionsText={t('general:no_options')}
                            getOptionLabel={(option) => option.value}
                            onChange={(e, value) => handleAutoCompleteChange(item.id, value)}
                            renderOption={(option, { selected }) => {
                              return (
                                <React.Fragment>
                                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                  {option.value}
                                </React.Fragment>
                              );
                            }}
                            renderInput={(params) => {
                              return <TextField {...params} variant="outlined" placeholder={t(item.label)} />;
                            }}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Box mt={2}>
              <Grid item container alignItems="center" spacing={1}>
                {!updateStatus && (
                  <Grid item xs={12} md={1} className={generalClasses.button}>
                    <Button variant="contained" onClick={handleCreateSecTenant}>
                      {t('general:add')}
                    </Button>
                  </Grid>
                )}
                {updateStatus && (
                  <>
                    <Grid item xs={3} sm={2} md={1} className={generalClasses.button}>
                      <Button variant="contained" onClick={handleUpdateSecTenant}>
                        {t('general:update')}
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={2} md={1}>
                      <Button variant="contained" onClick={handleUpdateCancelClick}>
                        {t('general:cancel')}
                      </Button>
                    </Grid>
                  </>
                )}
                {error && (
                  <Grid item xs={12} md={10}>
                    <Button
                      size="small"
                      style={{
                        textTransform: 'none',
                        color: 'red',
                        textAlign: 'left',
                      }}
                      disabled
                    >
                      {t(error)}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        )}
        <Box p={3} my={4} borderRadius={5} className={generalClasses.root}>
          <Hidden xsDown>
            <Box component={Paper}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {props.table.map((cell) => {
                        let comp;
                        if (cell.key === 'action') {
                          if (tenantType === 'primary') {
                            comp = (
                              <TableCell style={{ textAlign: 'center' }} key={cell.key}>
                                {t(cell.label)}
                              </TableCell>
                            );
                          }
                        } else {
                          comp = (
                            <TableCell key={cell.key} style={{ whiteSpace: 'nowrap' }}>
                              {t(cell.label)}
                            </TableCell>
                          );
                        }
                        return comp;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableContent.map((row, index) => (
                      <TableRow key={index}>
                        {props.table.map(({ key }) => {
                          let comp;
                          if (key === 'contract_no') {
                            comp = (
                              <TableCell key={key} style={{ paddingBottom: 0 }}>
                                {row[key].map((col) => {
                                  return (
                                    <Chip
                                      key={col}
                                      label={col}
                                      style={{
                                        marginRight: 16,
                                        marginBottom: 16,
                                      }}
                                    />
                                  );
                                })}
                              </TableCell>
                            );
                          } else if (key === 'account_type' || key === 'status') {
                            comp = (
                              <TableCell key={key} style={{ whiteSpace: 'nowrap' }}>
                                {t(row[key])}
                              </TableCell>
                            );
                          } else if (key !== 'action') {
                            comp = <TableCell key={key}>{row[key]}</TableCell>;
                          }
                          return comp;
                        })}
                        {tenantType !== 'secondary' && (
                          <TableCell>
                            <TableActionBtn
                              id={row.id}
                              tenant_id={row.tenant_id}
                              justify="center"
                              updateOnClick={handleUpdateClick}
                              deleteOnClick={handleDeleteClick}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Hidden>
          <Hidden smUp>
            {tableContent.map((row, index) => (
              <Box mb={2} key={`row-${index}`}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {props.table.map(({ key, label }) => {
                        return key === 'action' && tenantType === 'secondary' ? (
                          ''
                        ) : (
                          <TableRow key={key}>
                            <TableCell style={{ width: 140, whiteSpace: 'nowrap' }}>
                              <Typography>{t(label)}</Typography>
                            </TableCell>
                            <TableCell>
                              {key === 'action' ? (
                                <TableActionBtn
                                  id={row.id}
                                  tenant_id={row.tenant_id}
                                  justify="flex-start"
                                  updateOnClick={handleUpdateClick}
                                  deleteOnClick={handleDeleteClick}
                                />
                              ) : key === 'contract_no' ? (
                                row[key].map((col) => {
                                  return (
                                    <Chip
                                      key={col}
                                      label={col}
                                      style={{
                                        marginRight: 16,
                                        marginBottom: 16,
                                      }}
                                    />
                                  );
                                })
                              ) : key === 'account_type' || key === 'status' ? (
                                t(row[key])
                              ) : (
                                row[key]
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </Hidden>
        </Box>
        <AlertDialog ref={dialogRef} text={t('general:confirm_delete')} confirm_action={handleConfirmDeleteAction} />
        <AlertDialog ref={dialogRef2} text={t('general:confirm_add_sec')} confirm_action={createSecTenantReq} />
      </div>
    )
  );
};

TenantAccount.defaultProps = {
  add_field: [
    {
      id: 'email_phone',
      type: 'input',
      label: 'general:email_phone',
    },
    {
      id: 'contract_no',
      type: 'autocomplete',
      label: 'general:contract_no',
      // options: [
      //   {
      //     id: 'option-1',
      //     value: 'Option 1',
      //   },
      //   {
      //     id: 'option-2',
      //     value: 'Option 2',
      //   },
      //   {
      //     id: 'option-3',
      //     value: 'Option 3',
      //   },
      // ],
    },
  ],
  table: [
    {
      key: 'email_phone',
      label: 'general:email_phone',
    },
    {
      key: 'contract_no',
      label: 'general:contract_no',
    },
    // {
    //   key: 'customer_name',
    //   label: 'general:tenant_account.customer_name',
    // },
    // {
    //   key: 'unit',
    //   label: 'general:tenant_account.unit',
    // },
    // {
    //   key: 'brand',
    //   label: 'general:tenant_account.brand',
    // },
    {
      key: 'account_type',
      label: 'general:tenant_account.account_type',
    },
    {
      key: 'status',
      label: 'general:tenant_account.status',
    },
    {
      key: 'action',
      label: 'general:action',
    },
  ],
};

TenantAccount.propTypes = {
  add_field: PropTypes.array,
  table: PropTypes.array,
};

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default TenantAccount;
