import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, InputAdornment, IconButton, OutlinedInput, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/context';
import generalStyles from '../styles/style';
import BasicTable from '../../components/table';
import { makeRequest } from '../../api/api';
import AlertDialog from '../../components/dialog';
import { pick } from 'lodash';
import useRequestList from '../../hooks/useRequestList';
import passwordValidator from 'password-validator';

const pwSchema = new passwordValidator();
pwSchema.min(10).uppercase().lowercase().digits().symbols();

//table items***************************************************************************
const tableColumns = [
  { element: 'basic', key: 'project_name', label: 'general:site', width: '20%' },
  {
    element: 'basic',
    key: 'unit_name',
    label: 'general:unit',
    width: '16%',
  },
  {
    element: 'basic',
    key: 'v_pact_num',
    label: 'general:contract_no',
    width: '16%',
  },
  {
    element: 'basic',
    key: 'v_cust_name',
    label: 'general:tenant_account.customer_name',
    width: '16%',
  },
  {
    element: 'basic',
    key: 'v_brand_name',
    label: 'general:tenant_account.brand',
    width: '16%',
  },
  {
    element: 'checkbox',
    key: 'opt_out',
    label: 'general:preference.no_paper_statement',
    width: '16%',
  },
];

const Preference = (props) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const { setLoaderOpen, setSnackBar } = context;
  const DialogRef = useRef();
  const generalClasses = generalStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInput, setPasswordInput] = useState({});
  const [error, setError] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [tenantType, setTenantType] = useState('');
  const handleSetRequestList = useRequestList();

  const handleShowPw = () => {
    setShowPassword(true);
  };
  const handleHidePw = () => {
    setShowPassword(false);
  };

  const handleSaveClick = async () => {
    const { old_pw, new_pw, confirm_new_pw } = passwordInput;
    setError(null);
    if (old_pw && new_pw && confirm_new_pw) {
      if (new_pw === confirm_new_pw) {
        if (!pwSchema.validate(new_pw)) {
          setError('error:error_password_length_pattern');
          return;
        }
        setLoaderOpen(true);
        try {
          const res = await makeRequest(['user-management'], ['changeUserPassword'], [{ oldPassword: old_pw, newPassword: new_pw }]);
          console.log(res);
          if (res[0].data) {
            const { body } = res[0].data;
            if (body === 'done changeUserPassword') {
              setSnackBar((prev) => ({
                ...prev,
                open: true,
                message: t('error:success_message.password_changed'),
              }));
              setPasswordInput((prev) => ({
                ...prev,
                old_pw: '',
                new_pw: '',
                confirm_new_pw: '',
              }));
            } else if (body === 'old password not match') {
              setError('error:error_old_password');
            } else if (body === 'new password pattern invalid') {
              setError('error:error_password_length_pattern');
            }
          }
        } catch (error) {
          console.log(error);
        }
        setLoaderOpen(false);
      } else {
        setError('error:password_not_same');
      }
    } else {
      setError('error:empty_password');
    }
  };

  const handlePwInputChange = (e, id) => {
    const value = e.target.value;
    setPasswordInput((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  };

  const fetchData = async () => {
    handleSetRequestList('preference', false, true);
    const data = await makeRequest(['user-management'], ['getPriTenantList']);
    if (data) {
      let rowList = data[0].data.body.reduce((acc, item, index) => {
        const newItem = {
          ...item,
          handler: item.opt_out,
        };
        acc.push(newItem);
        return acc;
      }, []);
      Array.isArray(data[0].data.body) ? setDataList(rowList) : setDataList([]);
      setTenantType(data[0].data.tenantType);
    }
    handleSetRequestList('preference', true);
  };

  const updateOptOut = async () => {
    setLoaderOpen(true);
    const profile = constructQueryProfile();
    await makeRequest(['user-management'], ['updatePaperOptOut'], [profile]);
    setLoaderOpen(false);
    fetchData();
    setSnackBar((prev) => ({
      ...prev,
      open: true,
      message: t('error:success_message.update_completed'),
    }));
  };

  const constructQueryProfile = () => {
    let rent_lessee_list = [];
    rent_lessee_list = dataList.reduce((acc, item, index) => {
      const new_item = pick(item, ['pk_rent_lessee', 'pk_lessee', 'pk_pact', 'pk_corp', 'opt_out', 'handler']);
      if (new_item.handler === 0 && new_item.opt_out === 1) {
        acc.push(new_item);
      }
      return acc;
    }, []);
    return {
      rent_lessee_list,
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={generalClasses.mainContent}>
      <Typography variant="h4">{t('menu:tab.preference')}</Typography>
      <Box p={3} my={4} className={generalClasses.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h5">{t('general:preference.change_pw')}</Typography>
            </Box>
          </Grid>
          {props.changePw.map((field) => {
            return (
              <Grid container item xs={12} alignItems="center" key={field.id}>
                <Grid item xs={12} md={3} lg={2}>
                  <Typography>{t(field.label)} *</Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                  <OutlinedInput
                    className={generalClasses.formInput}
                    value={passwordInput[field.id] || ''}
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    fullWidth
                    placeholder={t(field.label)}
                    onChange={(e) => handlePwInputChange(e, field.id)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={handleShowPw}
                          onMouseUp={handleHidePw}
                          onTouchStart={handleShowPw}
                          onTouchEnd={handleHidePw}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box mt={2}>
          <Grid item container alignItems="center">
            <Grid item xs={12} md={1} className={generalClasses.button}>
              <Button variant="contained" onClick={handleSaveClick}>
                {t('general:save')}
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12} md={11}>
                <Button size="small" style={{ textTransform: 'none', color: 'red' }} disabled>
                  {t(error)}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      {tenantType === 'primary' && (
        <Box p={3} my={4} className={generalClasses.root}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="h5">{t('general:preference.no_paper_statement')}</Typography>
                <BasicTable tableColumns={tableColumns} tableList={dataList} setData={setDataList} padding="0" />
                <Grid item className={generalClasses.button}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      DialogRef.current.open();
                    }}
                  >
                    {t('general:save')}
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <AlertDialog ref={DialogRef} text={t('error:reminder_msg.opt_out_reminder')} close_action={false} confirm_action={updateOptOut} />
        </Box>
      )}
    </div>
  );
};

Preference.defaultProps = {
  changePw: [
    {
      id: 'old_pw',
      label: 'general:preference.old_pw',
    },
    {
      id: 'new_pw',
      label: 'general:login.new_password',
    },
    {
      id: 'confirm_new_pw',
      label: 'general:login.re_enter_new_password',
    },
  ],
};

Preference.propTypes = {
  changePw: PropTypes.array,
};

export default Preference;
