import axios from 'axios';
import Cookies from 'js-cookie';
import handleLogout from '../helper/handleLogout';
import { isBrowser } from 'react-device-detect';

const api_url = process.env.REACT_APP_API_LINK;
var util = require('util');

export const makeRequest = async (apiGroup, entry, data, callback) => {
  const req = entry.reduce((acc, item, index) => {
    acc.push(
      axios({
        method: 'post',
        url: `${api_url}/${apiGroup[index]}/${item}`,
        headers: {
          Authorization: Cookies.get('accessToken'),
        },
        data: {
          entry: item,
          ...(data ? data[index] : null),
        },
      })
    );
    return acc;
  }, []);
  try {
    const res = await Promise.all(req);
    if (res[0].status !== 200) {
      throw {
        data: 'API Error2',
        response: res[0],
      };
    }
    if (res[0].hasOwnProperty('data')) {
      if (res[0].data.hasOwnProperty('status')) {
        if (res[0].data.status !== 200) {
          throw {
            data: 'API Error',
            response: res[0],
          };
        }
      }
    }
    if (res[0].hasOwnProperty('data')) {
      if (res[0].data.hasOwnProperty('at')) {
        if (res[0].data.at) {
          Cookies.set('accessToken', res[0].data.at, { secure: true });
        }
      }
    }
    return res;
  } catch (error) {
    console.log(error, error.response);

    if (error) {
      const constrErrorData = {
        data: data,
        error: error,
      };

      if (error.response) {
        if (error.response.status === 401) {
          handleLogout();
          return;
        }

        if (error.response.status === 502) {
          alert('Timeout');
          return [error.response];
        }

        if (error.response.data) {
          if (error.response.data.errorCode === 'jwt expired') {
            window.location.pathname = '/';
            handleLogout();
            return;
          }
          if (error.response.data.message === 'The incoming token has expired') {
            console.log('token expired');
            handleLogout();
            return;
          }
        }
      }
      let result = '';
      if (callback instanceof Function) {
        result = callback(error.response);
      }
      if (result === '') {
        axios({
          method: 'post',
          url: `${api_url}/system-setting/sendApiError`,
          data: {
            entry: 'sendApiError',
            action: `${window.location.href}: ${JSON.stringify(entry)}`,
            error: util.inspect(constrErrorData, { depth: null }),
            username: Cookies.get('username') || '',
          },
        })
          .then((res) => {
            //console.table(res.data)
          })
          .catch((error) => {
            console.error(error);
          });
        if (error.response.data) {
          if (error.response.data.errorCode) {
            alert('System error:' + error.response.data.errorCode);
          }
        } else {
          alert('System error');
        }
      }
      return [error.response];
    }
  }
};

export const pdfRequest = async (method, site, type, date, filename) => {
  try {
    const res = await axios({
      method: 'post',
      url: `${api_url}/user-management/getPdfURL`,
      headers: {
        Authorization: Cookies.get('accessToken'),
      },
      data: {
        entry: 'getPdfURL',
        site: site,
        type: type,
        date: date,
        filename: filename,
      },
    });
    if (res.data.body) {
      if (method === 'previewPdf') {
        // var fileURL = URL.createObjectURL(res.data.body);
        const url = res.data.body;
        const link = document.createElement('a');
        link.href = url;
        if (isBrowser) {
          link.setAttribute('target', '_blank');
        }
        document.body.appendChild(link);
        link.click();
      }
      if (method === 'downloadPdf') {
        try {
          function checkVersion() {
            var agent = window.navigator.userAgent,
              start = agent.indexOf('OS');
            if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
              return window.Number(agent.substr(start + 3, 3).replace('_', ','));
            }
            return 0;
          }
          const downloadRes = await axios({
            method: 'GET',
            responseType: 'blob',
            url: res.data.body,
          });

          let version = checkVersion();
          if (version == '12') {
            let url = URL.createObjectURL(new Blob([downloadRes.data], { type: 'application/pdf' }));
            window.open(url, '_blank');
          } else {
            const url = window.URL.createObjectURL(new Blob([downloadRes.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            link.setAttribute('download', `${filename}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        } catch (error) {
          console.log(error);
        }
      }
      return true;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const csvRequest = async () => {
  try {
    const res = await axios({
      method: 'post',
      url: `${api_url}/user-management/getTenantListCSV`,
      headers: {
        Authorization: Cookies.get('accessToken'),
      },
      data: {
        entry: 'getTenantListCSV',
      },
    });
    if (res.data.body) {
      try {
        const downloadRes = await axios({
          method: 'GET',
          responseType: 'blob',
          url: res.data.body,
        });
        const url = window.URL.createObjectURL(new Blob([downloadRes.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', 'tenantlist.csv');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendApiError = async (error, action) => {
  try {
    await axios({
      method: 'post',
      url: `${api_url}/system-setting/sendApiError`,
      data: {
        entry: 'sendApiError',
        error: typeof error === 'object' ? JSON.stringify(error) : error,
        action,
        username: Cookies.get('username') || '',
      },
    }).then((res) => {
      if (res) {
        if (res.data.body) {
          if (res.data.body === 'sendApiError success') {
            console.log('sendApiError success');
          }
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
};
