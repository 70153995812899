import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Grid, TextField, Typography, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCNLocale from 'date-fns/locale/zh-CN';
import enUSLocale from 'date-fns/locale/en-US';
import zhTWLocale from 'date-fns/locale/zh-TW';
import ListboxComponent from './listbox';

//* Context
import { PaymentHistoryContext } from '../../../context/context';

//* Style
import { makeStyles } from '@material-ui/core/styles';
import generalStyles from '../../styles/style';

const datepickerStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  datepicker: {
    width: 'calc((100% - 12px) / 2)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  separator: {
    padding: '0 2px',
    width: '12px',
    textAlign: 'center',
    lineHeight: '1',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InlineDatePicker = (props) => {
  const { date_to, date_from } = props;
  const context = useContext(PaymentHistoryContext);
  const { formValue, stateSetFormValue } = context;
  const { t, i18n } = useTranslation();
  const generalClasses = generalStyles();
  //* State
  const [locale, setLocale] = useState(i18n.language);
  //* Locale Map
  const localeMap = {
    en: enUSLocale,
    sc: zhCNLocale,
    tc: zhTWLocale,
  };
  //* Style
  const classes = datepickerStyles();

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
        <KeyboardDatePicker
          autoOk
          disableFuture
          variant="inline"
          inputVariant="outlined"
          format="yyyy-MM-dd"
          value={formValue[date_from]}
          onChange={(date, value) => {
            stateSetFormValue('date_from', value);
          }}
          placeholder={t('general:date')}
          className={[generalClasses.autoComplete, classes.datepicker].join(' ')}
          invalidDateMessage={t('error:invalid_date_format')}
        />
        <div className={classes.separator}>-</div>
        <KeyboardDatePicker
          autoOk
          disableFuture
          variant="inline"
          inputVariant="outlined"
          format="yyyy-MM-dd"
          value={formValue[date_to]}
          onChange={(date, value) => {
            stateSetFormValue('date_to', value);
          }}
          placeholder={t('general:date')}
          className={[generalClasses.autoComplete, classes.datepicker].join(' ')}
          invalidDateMessage={t('error:invalid_date_format')}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

InlineDatePicker.propTypes = {
  date_to: PropTypes.string,
  date_from: PropTypes.string,
};

const listboxStyle = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const PHForm = () => {
  const context = useContext(PaymentHistoryContext);
  const { formFields, formErrorMsg, formValue, handleSearchClick, stateSetFormValue } = context;
  const { t } = useTranslation();
  const generalClasses = generalStyles();
  const listboxClasses = listboxStyle();

  return (
    <Box p={3} my={4} className={generalClasses.root}>
      <Grid container spacing={1}>
        {formFields.length > 0 &&
          formFields.map(({ key, type, label, xs, md, required, options, date_to, date_from }) => {
            return (
              <Grid item xs={xs} md={md} key={key}>
                <Typography>
                  {t(label)}
                  {required ? '*' : ''}
                </Typography>
                {type === 'select' && (
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      id={key}
                      value={formValue[key]}
                      noOptionsText={t('general:no_options')}
                      className={generalClasses.autoComplete}
                      onChange={(event, value) => {
                        stateSetFormValue(key, value);
                      }}
                      options={options}
                      // ListboxComponent={ListboxComponent}
                      getOptionLabel={(option) => {
                        return option.text;
                      }}
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      renderInput={(params) => {
                        return <TextField {...params} variant="outlined" placeholder={t(label)} />;
                      }}
                    />
                  </FormControl>
                )}
                {type === 'multiselect' && (
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      id={key}
                      value={formValue[key]}
                      noOptionsText={t('general:no_options')}
                      className={generalClasses.autoComplete}
                      classes={listboxClasses}
                      onChange={(event, value) => {
                        stateSetFormValue(key, value);
                      }}
                      options={options}
                      ListboxComponent={ListboxComponent}
                      multiple
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => {
                        return option.text;
                      }}
                      renderOption={(option, { selected }) => {
                        return (
                          <React.Fragment>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {option.text}
                          </React.Fragment>
                        );
                      }}
                      renderInput={(params) => {
                        return <TextField {...params} variant="outlined" placeholder={formValue[key].length > 0 ? '' : t(label)} />;
                      }}
                    />
                  </FormControl>
                )}
                {type === 'date' && <InlineDatePicker date_to={date_to} date_from={date_from} />}
              </Grid>
            );
          })}
      </Grid>
      <Box mt={2}>
        <Grid container alignItems="center" spacing={1} className={generalClasses.button}>
          <Grid item>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Button variant="contained" style={{ marginRight: 16 }} onClick={handleSearchClick}>
                {t('general:search')}
              </Button>
              {formErrorMsg && <Typography style={{ color: 'red' }}>{t('error:at_least_two_options')}</Typography>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PHForm;
