import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, Grid, TextField, Typography, Box, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import generalStyles from '../styles/style';
import { AppContext } from '../../context/context';
import { useTimer } from 'react-timer-hook';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeRequest, sendApiError } from '../../api/api';
import Cookies from 'js-cookie';
import url from '@websanova/url/dist/es5-url.min.js';
import axios from 'axios';

import passwordValidator from 'password-validator';
const pwSchema = new passwordValidator();
pwSchema.min(10).uppercase().lowercase().digits().symbols();

const apiUrl = process.env.REACT_APP_API_LINK;

const TenantLogin = (props) => {
  const { t } = useTranslation(['general', 'error']);
  const context = useContext(AppContext);
  const { setLoaderOpen, setSnackBar, loginUsername, setLoginUsername, setAuth, setCheckUserPw } = context;
  const [disableMFABtn, setDisableMFABtn] = useState(false);
  const { seconds, restart } = useTimer({
    onExpire: () => setDisableMFABtn(false),
  });
  const generalClasses = generalStyles();
  const [error, setError] = useState({
    empty_username_password: null,
    error_username_password: null,
    error_verify: null,
    empty_verify: null,
    password_not_same: null,
    error_password_length_pattern: null,
    empty_username: null,
    empty_verify_pw: null,
    error_user_account_error: null,
    error_verify_expired: null,
    error_send_mfa: null,
    error_mfa: null,
    error_account_lockout: null,
  });
  const resetError = () => {
    setError({
      empty_username_password: null,
      error_username_password: null,
      error_verify: null,
      empty_verify: null,
      password_not_same: null,
      error_password_length_pattern: null,
      empty_username: null,
      empty_verify_pw: null,
      error_user_account_error: null,
      error_verify_expired: null,
      error_send_mfa: null,
      error_mfa: null,
      network_error: null,
      error_account_lockout: null,
    });
  };
  const [formValue, setFormValue] = useState({});
  const [login, setLogin] = useState(true); // default is login
  const [forgetPw, setForgetPw] = useState({
    step1: false,
    step2: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const formChange = (key, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleSendMFA = async () => {
    resetError();
    const { username, password } = formValue;
    if (!username || username === '' || !password || password === '') {
      setError({ empty_username_password: true });
      return;
    }
    resetError();
    setLoaderOpen(true);
    try {
      const res = await makeRequest(['user-auth'], ['sendMFA'], [{ username, password, type: 'login' }]);
      console.log(res);
      if (res[0].data) {
        const { message, token, errorMessage } = res[0].data;
        console.log(message, errorMessage);
        if (message === 'done sendMFA') {
          const time = new Date();
          time.setSeconds(time.getSeconds() + 60);
          restart(time);
          setDisableMFABtn(true);
          Cookies.set('mfaToken', token, { secure: true });
        } else if (message === 'cannot find tenant username') {
          setError({ error_username_password: true });
        } else if (message === 'password is not correct') {
          setError({ error_username_password: true });
        } else if (message === 'account status error') {
          setError({ error_user_account_error: true });
        } else if (message === 'The account is locked out') {
          setError({ error_account_lockout: true });
        } else if (errorMessage && errorMessage.includes('Function timed out')) {
          sendApiError(error, 'tenant login: send mfa');
          setError({ network_error: true });
        }
      }
    } catch (error) {
      //* send api error
      sendApiError(error, 'tenant login: send mfa');
      setError({ network_error: true });
    }
    setLoaderOpen(false);
  };
  const handleLogin = async () => {
    const { mfa_code, username } = formValue;
    if (!mfa_code) {
      setError({ empty_verify: true });
      return;
    }
    resetError();
    setLoaderOpen(true);
    try {
      // const res = await makeRequest(['user-auth'], ['userLogin'], [{ username, code: mfa_code }]);
      const res = await axios({
        method: 'post',
        url: `${apiUrl}/user-auth/`,
        headers: {
          Authorization: Cookies.get('mfaToken'),
        },
        data: {
          entry: 'userLogin',
          username,
          code: mfa_code,
        },
      });
      console.log(res);
      Cookies.remove('mfaToken');
      if (res) {
        const { message, token } = res.data;
        console.log(message);
        if (message === 'login success' && token) {
          Cookies.set('accessToken', token, { secure: true });
          Cookies.set('accountType', 'tenant', { secure: true });
          Cookies.set('username', username, { secure: true });
          setAuth(true);
          setCheckUserPw(true);
        } else if (message === 'mfa code expired') {
          setLoaderOpen(false);
          setError({ error_verify: true });
        } else if (message === 'session expired') {
          setLoaderOpen(false);
          setError({ error_mfa: true });
        } else if (message === 'mfa code not match') {
          setLoaderOpen(false);
          setError({ error_verify: true });
        }
      }
    } catch (error) {
      setLoaderOpen(false);
      sendApiError(error, 'tenant login: userLogin error');
      setError({ network_error: true });
    }
  };
  const handleForgetPw = () => {
    resetError();
    setForgetPw((prev) => ({
      ...prev,
      step1: true,
    }));
    setLogin(false);
  };
  const handleSubmitForgetPw = async () => {
    const { username } = formValue;
    resetError();
    if (!username || username === '') {
      setError({ empty_username: true });
      return false;
    }
    setLoaderOpen(true);
    try {
      const res = await makeRequest(['user-auth'], ['sendMFA'], [{ type: 'forgetPassword', username }]);
      if (res[0].data) {
        const { message, token } = res[0].data;
        console.log(message);
        if (message === 'done sendMFA') {
          setForgetPw({
            step1: false,
            step2: true,
          });
          const time = new Date();
          time.setSeconds(time.getSeconds() + 60);
          restart(time);
          setDisableMFABtn(true);
          Cookies.set('mfaToken', token);
        } else if (message === 'account status error') {
          setError({ error_user_account_error: true });
        } else if (message === 'fail sendMFA') {
          setError({ error_send_mfa: true });
        }
      }
    } catch (error) {
      sendApiError(error, 'tenant forget password: send mfa');
    }
    setLoaderOpen(false);
  };
  const handleForgetNewPw = async () => {
    const { mfa_code, new_password, confirm_new_password, username } = formValue;
    resetError();
    if (mfa_code && new_password && confirm_new_password) {
      if (!pwSchema.validate(new_password)) {
        setError({ error_password_length_pattern: true });
        return;
      }
      if (new_password === confirm_new_password) {
        setLoaderOpen(true);
        try {
          // const res = await makeRequest(['user-auth'], ['completeForgetPassword'], [{ code: mfa_code, username, password: new_password }]);
          const res = await axios({
            method: 'post',
            url: `${apiUrl}/user-auth/`,
            headers: {
              Authorization: Cookies.get('mfaToken'),
            },
            data: {
              entry: 'completeForgetPassword',
              username,
              password: new_password,
              code: mfa_code,
            },
          });
          Cookies.remove('mfaToken');
          if (res) {
            const { message } = res.data;
            console.log(message);
            if (message === 'password updated') {
              setSnackBar((prev) => ({
                ...prev,
                open: true,
                message: t('error:success_message.password_changed'),
              }));
              setForgetPw({
                step1: false,
                step2: false,
              });
              setLogin(true);
              setLoginUsername(username);
            } else if (message === 'session expired') {
              setError({ error_mfa: true });
            } else if (message === 'mfa code expired') {
              setForgetPw({
                step1: true,
                step2: false,
              });
            } else if (message === 'password format incorrect') {
              setError({ error_password_length_pattern: true });
            }
          }
        } catch (error) {
          sendApiError(error, 'tenant forget password: submit forget password');
        }
        setLoaderOpen(false);
      } else {
        setError({ password_not_same: true });
      }
    } else {
      setError({ empty_verify_pw: true });
    }
  };
  const handleShowPw = () => {
    setShowPassword(true);
  };
  const handleHidePw = () => {
    setShowPassword(false);
  };

  useEffect(() => {
    if (loginUsername) {
      formChange('username', loginUsername);
    }
  }, [loginUsername]);

  useEffect(() => {
    const username = url('?username') || url('?u');
    if (username) {
      formChange('username', isNaN(username) ? username : `+${username}`);
    }
    Cookies.remove('mfaToken');
  }, []);

  return (
    <div>
      <Typography style={{ marginBottom: 25 }}>{t('general:login.heading')}</Typography>
      <Grid container spacing={1}>
        {login && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  className={generalClasses.formInput}
                  required
                  placeholder={t('general:login.username')}
                  variant="outlined"
                  value={formValue['username'] || ''}
                  onChange={(event) => formChange('username', event.target.value)}
                  autoComplete="off"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  className={generalClasses.formInput}
                  required
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('general:login.password')}
                  onChange={(event) => formChange('password', event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={handleShowPw}
                        onMouseUp={handleHidePw}
                        onTouchStart={handleShowPw}
                        onTouchEnd={handleHidePw}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="off"
                />
              </FormControl>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <TextField
                  className={generalClasses.formInput}
                  required
                  placeholder={t('general:login.mfa_code')}
                  variant="outlined"
                  onChange={(event) => formChange('mfa_code', event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5} className={generalClasses.button}>
              <Button style={{ textTransform: 'none', width: '100%' }} onClick={handleSendMFA} disabled={disableMFABtn}>
                <Box display={disableMFABtn ? 'none' : 'block'}>{t('general:login.send_mfa')}</Box>
                <Box display={disableMFABtn ? 'block' : 'none'}>
                  {seconds}
                  {t('general:can_resend')}
                </Box>
              </Button>
            </Grid>
          </>
        )}
        {forgetPw.step1 && (
          <>
            <Grid item xs={12}>
              <div style={{ textAlign: 'left' }}>{t('error:empty_username')}</div>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  className={generalClasses.formInput}
                  required
                  placeholder={t('general:login.username')}
                  variant="outlined"
                  onChange={(event) => formChange('username', event.target.value)}
                />
              </FormControl>
            </Grid>
          </>
        )}
        {forgetPw.step2 && (
          <>
            <Grid item xs={12}>
              <div style={{ textAlign: 'left' }}>{t('error:empty_verify_pw')}</div>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <TextField
                  className={generalClasses.formInput}
                  required
                  placeholder={t('general:login.mfa_code')}
                  variant="outlined"
                  onChange={(event) => formChange('mfa_code', event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5} className={generalClasses.button}>
              <Button style={{ textTransform: 'none', width: '100%' }} onClick={handleSubmitForgetPw} disabled={disableMFABtn}>
                <Box display={disableMFABtn ? 'none' : 'block'}>{t('general:login.send_mfa')}</Box>
                <Box display={disableMFABtn ? 'block' : 'none'}>
                  {seconds}
                  {t('general:can_resend')}
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  className={generalClasses.formInput}
                  required
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('general:login.new_password')}
                  variant="outlined"
                  onChange={(event) => formChange('new_password', event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={handleShowPw}
                        onMouseUp={handleHidePw}
                        onTouchStart={handleShowPw}
                        onTouchEnd={handleHidePw}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  className={generalClasses.formInput}
                  required
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('general:login.re_enter_new_password')}
                  variant="outlined"
                  onChange={(event) => formChange('confirm_new_password', event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={handleShowPw}
                        onMouseUp={handleHidePw}
                        onTouchStart={handleShowPw}
                        onTouchEnd={handleHidePw}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            {login && (
              <Grid item>
                <Button size="small" style={{ textTransform: 'none' }} onClick={handleForgetPw}>
                  {t('general:login.forget_pw')}
                </Button>
              </Grid>
            )}
            <Grid item md={6} style={{ textAlign: 'left' }}>
              <Button
                size="small"
                style={{
                  textTransform: 'none',
                  color: 'red',
                  textAlign: 'left',
                }}
                disabled
              >
                {error.empty_username_password && t('error:empty_username_password')}
                {error.error_username_password && t('error:error_username_password')}
                {error.error_verify && t('error:error_verify')}
                {error.empty_verify && t('error:empty_verify')}
                {error.password_not_same && t('error:password_not_same')}
                {error.error_password_length_pattern && t('error:error_password_length_pattern')}
                {error.empty_username && t('error:empty_username')}
                {error.empty_verify_pw && t('error:empty_verify_pw')}
                {error.error_username_not_exist && t('error:error_username_not_exist')}
                {error.error_register_first && t('error:error_register_first')}
                {error.error_user_account_error && t('error:error_user_account_error')}
                {error.error_verify_expired && t('error:error_verify_expired')}
                {error.error_mfa && t('error:error_mfa')}
                {error.error_send_mfa && t('error:error_send_mfa')}
                {error.error_account_lockout && t('error:error_account_lockout')}
                {error.network_error && t('error:network_error')}
              </Button>
            </Grid>
            {login && (
              <Grid item className={generalClasses.button}>
                <Button onClick={handleLogin}>{t('general:login.login')}</Button>
              </Grid>
            )}
            {forgetPw.step1 && (
              <Grid item className={generalClasses.button}>
                <Button onClick={handleSubmitForgetPw}>{t('general:login.submit')}</Button>
              </Grid>
            )}
            {forgetPw.step2 && (
              <Grid item className={generalClasses.button}>
                <Button onClick={handleForgetNewPw}>{t('general:login.submit')}</Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TenantLogin;
