import React from 'react';
import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import generalStyles from '../styles/style';
// import { useHistory } from 'react-router-dom';

const AdminLogin = (props) => {
  const { t } = useTranslation(['general']);
  const generalClasses = generalStyles();
  const goADAuth = () => {
    const { hostname } = window.location;
    if (hostname === 'localhost') {
      let domain =
        'https://login.microsoftonline.com/42c1b969-dae8-4da7-8fc2-b994bfe10bb6/oauth2/v2.0/authorize?response_type=code&client_id=23de9123-5da7-4162-8545-558ae4c02b58&scope=openid';
      let redirect_uri = 'http://localhost:3000/signin-openid_1';

      window.location.href = domain + '&redirect_uri=' + redirect_uri;
    } else if (hostname === 'devhk.tip-hanglung.com') {
      let domain =
        'https://login.microsoftonline.com/42c1b969-dae8-4da7-8fc2-b994bfe10bb6/oauth2/v2.0/authorize?response_type=code&client_id=23de9123-5da7-4162-8545-558ae4c02b58&scope=openid';
      let redirect_uri = 'https://devhk.tip-hanglung.com/signin-openid_1';

      window.location.href = domain + '&redirect_uri=' + redirect_uri;
    } else if (hostname === 'uat.tip.hanglung.com.cn') {
      let domain =
        'https://login.microsoftonline.com/42c1b969-dae8-4da7-8fc2-b994bfe10bb6/oauth2/v2.0/authorize?response_type=code&client_id=4d01754c-7fd5-46d0-b560-cf54971c2de4&scope=openid';
      window.location.href = domain;
    } else if (hostname === 'www.tip-hanglung.com') {
      let domain =
        'https://login.microsoftonline.com/42c1b969-dae8-4da7-8fc2-b994bfe10bb6/oauth2/v2.0/authorize?response_type=code&client_id=6a403e70-ae52-4cef-ac20-2227402a368d&scope=openid';
      window.location.href = domain;
    }
  };

  return (
    <div className={generalClasses.button}>
      <Typography style={{ marginBottom: 25 }}>{t('general:login.heading')}</Typography>
      <Button style={{ width: 240 }} onClick={goADAuth}>
        {t('general:login.ad_login')}
      </Button>
    </div>
  );
};

export default AdminLogin;
