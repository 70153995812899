import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeRequest } from '../../api/api';
import { reduce, find, isEqual } from 'lodash';
import { usePrevious } from 'react-delta';
import useRequestList from '../../hooks/useRequestList';
import generalStyles from '../styles/style';

//* Context
import { AppContext, PaymentHistoryContext } from '../../context/context';

//* Components
import PHForm from './components/ph_form';
import PHTable from '../../components/ph_table';

import Cookies from 'js-cookie';

const PaymentHistory = (props) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;
  const { tableFields } = props;

  const defaultFormValue = {
    site_list: null,
    pk_lessee_list: null,
    building_type_list: null,
    pk_unit_list: null,
    pk_pact_list: null,
    pk_brand_list: null,
    // pk_actym_list: null,
    date_from: null,
    date_to: null,
  };

  //* State
  const [initFormOptions, setInitFormOptions] = useState({});
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [formFields, setFormFields] = useState(props.formFields);
  const [tableData, setTableData] = useState([]);
  const [formErrorMsg, setFormErrorMsg] = useState(false);
  const [pagCount, setPagCount] = useState(0);
  const [pagPage, setPagPage] = useState(1);
  const [pagDisable, setPagDisable] = useState(false);
  const [pageTotal, setPageTotal] = useState(0);
  const [sumTotal, setSumTotal] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('d_rev_fare_date');

  //* Compare Previous/Hooks
  const previousFormValue = usePrevious(formValue);
  const previousFormFields = usePrevious(formFields);
  const handleSetRequestList = useRequestList();
  const prevOrder = usePrevious(order);
  const prevOrderBy = usePrevious(orderBy);

  const generalClasses = generalStyles();

  const stateSetFormValue = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  //* Handle Search Click
  const handleSearchClick = () => {
    const { site_list } = formValue;
    console.log(formValue);
    const ArrInputValue = Object.keys(formValue).reduce((acc, key) => {
      if (formValue[key] !== null) {
        acc.push(key);
      }
      return acc;
    }, []);
    if ((site_list && ArrInputValue.length < 2) || !site_list) {
      setFormErrorMsg(true);
    } else {
      setFormErrorMsg(false);
      fetchTableData(1);
    }
  };

  const handleSortClick = (key) => (event) => {
    if (tableData.length > 0) {
      console.log({ key, order, orderBy });
      const isAsc = orderBy === key && order === 'asc';
      setOrderBy(key);
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  const fetchOtherFormFields = async (formValue) => {
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['getSearchPaymentHistory'], [{ formValue }]);
    console.log(res);
    const construFormFields = {};
    if (res[0].data) {
      if (res[0].data.errorMessage) {
        return;
      }
      res.forEach((resItem, index) => {
        if (resItem.data.body.length > 0) {
          const data = resItem.data.body;
          console.log(data);
          data.forEach((dItem) => {
            if (dItem.customerList) {
              const new_data = reduce(
                dItem.customerList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.v_cust_name} (${item.v_site})`,
                    value: item.pk_lessee,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_lessee_list = new_data;
            } else if (dItem.unitList) {
              const new_data = reduce(
                dItem.unitList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.unit_name} (${item.v_site})`,
                    value: item.pk_unit,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_unit_list = new_data;
            } else if (dItem.pactList) {
              const new_data = reduce(
                dItem.pactList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.v_pact_num} (${item.v_site})`,
                    value: item.pk_pact,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_pact_list = new_data;
            } else if (dItem.billMonthList) {
              const new_data = reduce(
                dItem.billMonthList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.pk_actym} (${item.v_site})`,
                    value: item.pk_actym,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_actym_list = new_data;
            } else if (dItem.brandList) {
              const new_data = reduce(
                dItem.brandList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.v_brand_name} (${item.v_site})`,
                    value: item.pk_brand,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_brand_list = new_data;
            } else if (dItem.buildingList) {
              const new_data = reduce(
                dItem.buildingList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.building_type} (${item.v_site})`,
                    value: item.building_type,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.building_type_list = new_data;
            }
          });
        }
      });
    }
    setInitFormOptions((prev) => {
      return {
        ...prev,
        ...construFormFields,
      };
    });
    setLoaderOpen(false);
  };

  //* set form options into form fields
  const setOptionsToFields = (source, init) => {
    setFormFields((prev) => {
      const new_fields = reduce(
        prev,
        (result, item) => {
          if (init) {
            const newItem = {
              ...item,
              options: item.key === 'site_list' ? source[item.key] : [],
            };
            result.push(newItem);
          } else {
            const newItem = {
              ...item,
              options: source[item.key] || [],
            };
            result.push(newItem);
          }
          return result;
        },
        []
      );
      console.log(new_fields);
      return new_fields;
    });
  };

  //* modify formValue in order to post
  const modifyFormValue = (formValue) => {
    return reduce(
      formValue,
      (result, fValue, fKey) => {
        let new_result;
        if (fValue && fKey !== 'date_from' && fKey !== 'date_to') {
          new_result = {
            ...result,
            [fKey.replace('_list', '')]: fValue.value,
          };
        } else if (fKey === 'date_from' || fKey === 'date_to') {
          new_result = {
            ...result,
            [fKey]: fValue || '',
          };
        } else {
          new_result = {
            ...result,
            [fKey.replace('_list', '')]: '',
          };
        }
        return new_result;
      },
      {}
    );
  };

  //* fetching data from db
  const fetchTableData = async (page) => {
    const m_formValue = modifyFormValue(formValue);
    const toPostData = {
      formValue: m_formValue,
      page,
      sorting: {
        order,
        orderBy,
      },
    };
    console.log(toPostData);
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['getPaymentHistory'], [toPostData]);
    setLoaderOpen(false);
    console.log(res);
    window.scrollTo(0, 0);
    if (res) {
      if (res[0].data.body) {
        const data = res[0].data.body.results;
        const pageTotal = res[0].data.body.pageTotal;
        const sumTotal = res[0].data.body.sumTotal;
        const rowTotal = res[0].data.body.total;
        console.log(data);
        if (data.length !== 0) {
          setPagPage(page);
          setPageTotal(pageTotal);
          setSumTotal(sumTotal);
          setTableData(data);
          setPagCount(Math.ceil(rowTotal / 10));
          setPagDisable(rowTotal <= 10 ? true : false);
        } else {
          setPagPage(1);
          setPageTotal(pageTotal);
          setSumTotal(sumTotal);
          setTableData(data);
          setPagCount(1);
          setPagDisable(true);
        }
      }
    }
  };

  //* handle click on Pagination
  const handlePageChange = (e, page) => {
    setPagPage(page);
    fetchTableData(page);
  };

  useEffect(() => {
    //* Fetching Form Fields from db
    const fetchFormFields = async () => {
      handleSetRequestList('payment_history', false, true);
      const res = await makeRequest(['user-management'], ['getSiteList2']);
      console.log(res);
      const construFormFields = {};
      if (res) {
        res.forEach((resItem, index) => {
          if (resItem.data.body.length > 0) {
            if (index === 0) {
              //* Site List
              const data = resItem.data.body;
              const new_data = reduce(
                data,
                (result, item) => {
                  const reItem = {
                    text: `${item.project_name} (${item.site})`,
                    value: item.site,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.site_list = new_data;
              console.log(new_data);
            }
          }
        });
      }
      setInitFormOptions(construFormFields);
      handleSetRequestList('payment_history', true);
    };
    fetchFormFields();
  }, []);

  useEffect(() => {
    const optionSiteList = find(formFields, (o) => o.key === 'site_list').options;
    const prevOptionSiteList = find(previousFormFields, (o) => o.key === 'site_list') ? find(previousFormFields, (o) => o.key === 'site_list').options : [];
    const optionLesseeList = find(formFields, (o) => o.key === 'pk_lessee_list').options;
    const prevOptionLesseeList = find(previousFormFields, (o) => o.key === 'pk_lessee_list')
      ? find(previousFormFields, (o) => o.key === 'pk_lessee_list').options
      : [];
    console.log(optionSiteList, prevOptionSiteList, optionLesseeList, prevOptionLesseeList);
    if (optionSiteList.length > 0 && prevOptionSiteList !== optionSiteList) {
      console.log('trigger once option site list');
      console.log(Cookies.get('site_list'));
      if (Cookies.get('site_list')) {
        const cookies_site_list = JSON.parse(Cookies.get('site_list'));
        console.log(cookies_site_list);
        if (cookies_site_list) {
          let optionValue;
          if (Array.isArray(cookies_site_list)) {
            optionValue = cookies_site_list[0].value;
          } else {
            optionValue = cookies_site_list.value;
          }
          const foundOption = find(optionSiteList, (o) => o.value === optionValue);
          console.log(foundOption);
          if (foundOption) {
            setFormValue((prev) => ({
              ...prev,
              site_list: foundOption,
            }));
          }
        }
      }
    }

    if (optionLesseeList.length > 0 && prevOptionLesseeList !== optionLesseeList) {
      console.log('trigger once option lessee list');
      console.log(Cookies.get('pk_lessee_list'));
      if (Cookies.get('pk_lessee_list')) {
        const cookies_lessee_list = JSON.parse(Cookies.get('pk_lessee_list'));
        console.log(cookies_lessee_list);
        if (cookies_lessee_list) {
          const optionValue = cookies_lessee_list.value.split('::').length === 2 ? cookies_lessee_list.value.split('::')[1] : cookies_lessee_list.value;
          const foundOption = find(optionLesseeList, (o) => o.value === optionValue);
          console.log(foundOption);
          if (foundOption) {
            setFormValue((prev) => ({
              ...prev,
              pk_lessee_list: foundOption,
            }));
          }
        }
      }
    }
  }, [formFields]);

  useEffect(() => {
    console.log(initFormOptions);
    if (Object.keys(initFormOptions).length === 1 && initFormOptions.site_list) {
      setOptionsToFields(initFormOptions, true);
    } else {
      setOptionsToFields(initFormOptions, false);
    }
  }, [initFormOptions]);

  useEffect(() => {
    if (!isEqual(previousFormValue, formValue)) {
      const { site_list, pk_lessee_list } = formValue;
      const prev_pk_lessee_list = previousFormValue ? previousFormValue.pk_lessee_list : '';
      if (site_list) {
        const m_formValue = modifyFormValue(formValue);
        console.log(m_formValue);
        Cookies.set('site_list', JSON.stringify(site_list));
        fetchOtherFormFields(m_formValue);
      } else {
        setFormValue(defaultFormValue);
        const filteredFormOptions = reduce(
          initFormOptions,
          (result, ffValue, ffKey) => {
            let new_result;
            if (ffKey === 'site_list') {
              new_result = {
                ...result,
                [ffKey]: ffValue,
              };
            } else {
              new_result = {
                ...result,
                [ffKey]: [],
              };
            }
            return new_result;
          },
          {}
        );
        setOptionsToFields(filteredFormOptions, false);
        if (previousFormValue) {
          Cookies.remove('site_list');
          Cookies.remove('pk_lessee_list');
        }
      }
      if (pk_lessee_list) {
        Cookies.set('pk_lessee_list', JSON.stringify(pk_lessee_list));
      } else if (!pk_lessee_list && prev_pk_lessee_list !== pk_lessee_list && prev_pk_lessee_list !== '') {
        Cookies.remove('pk_lessee_list');
      }
    }
  }, [formValue]);

  useEffect(() => {
    if (prevOrder && prevOrderBy) {
      fetchTableData(1);
    }
  }, [order, orderBy]);

  const contextValue = {
    formFields,
    tableFields,
    tableData,
    formValue,
    stateSetFormValue,
    pagCount,
    pagPage,
    pagDisable,
    formErrorMsg,
    handleSearchClick,
    pageTotal,
    sumTotal,
    handlePageChange,
    handleSortClick,
    order,
    orderBy,
  };

  return (
    <PaymentHistoryContext.Provider value={contextValue}>
      <div className={generalClasses.mainContent}>
        <Typography variant="h4">{t('menu:tab.payment_history')}</Typography>
        <PHForm />
        <PHTable />
        <Typography>{t('general:remarks.remark') + t('general:remarks.debit')}</Typography>
      </div>
    </PaymentHistoryContext.Provider>
  );
};

PaymentHistory.propTypes = {
  formFields: PropTypes.array,
  tableFields: PropTypes.array,
  tableData: PropTypes.array,
};

PaymentHistory.defaultProps = {
  formFields: [
    {
      key: 'site_list',
      type: 'select',
      label: 'general:site',
      xs: 12,
      md: 12,
      required: true,
      options: [],
    },
    {
      key: 'pk_lessee_list',
      type: 'select',
      label: 'general:cust_grp.cust_name',
      xs: 12,
      md: 12,
      options: [],
    },
    {
      key: 'building_type_list',
      type: 'select',
      label: 'general:segment',
      xs: 12,
      md: 4,
      options: [],
    },
    {
      key: 'pk_unit_list',
      type: 'select',
      label: 'general:unit',
      xs: 12,
      md: 4,
      options: [],
    },
    {
      key: 'pk_pact_list',
      type: 'select',
      label: 'general:contract_no',
      xs: 12,
      md: 4,
      options: [],
    },
    {
      key: 'pk_brand_list',
      type: 'select',
      label: 'general:brand',
      xs: 12,
      md: 4,
      options: [],
    },
    // {
    //   key: 'pk_actym_list',
    //   type: 'select',
    //   label: 'general:payment_period',
    //   xs: 12,
    //   md: 4,
    //   options: [],
    // },
    {
      key: 'date',
      type: 'date',
      label: 'general:payment_period',
      xs: 12,
      md: 8,
      date_from: 'date_from',
      date_to: 'date_to',
    },
  ],
  tableFields: [
    {
      key: 'site',
      label: 'general:site',
      field_name: 'project_name',
    },
    {
      key: 'cust_name',
      label: 'general:cust_grp.cust_name',
      field_name: 'v_cust_name',
    },
    {
      key: 'segment',
      label: 'general:segment',
      field_name: 'building_type',
    },
    {
      key: 'unit',
      label: 'general:unit',
      field_name: 'unit_name',
    },
    {
      key: 'contract_no',
      label: 'general:contract_no',
      field_name: 'v_pact_num',
    },
    {
      key: 'brand',
      label: 'general:brand',
      field_name: 'v_brand_name',
    },
    {
      key: 'payment_method',
      label: 'general:payment_method',
      field_name: 'rev_source',
    },
    {
      key: 'payment_date',
      label: 'general:payment_date',
      field_name: 'd_rev_fare_date',
    },
    {
      key: 'paid_amount',
      label: 'general:paid_amount',
      field_name: 'srn_rev_mny',
    },
  ],
  tableData: [
    {
      pk_re_fare_id: '1001A21000000004OC0O',
      pk_actym: '2012-05',
      pk_pact: '1001A21000000003XSYI',
      pk_fare_type_id: '1001A21000000003TFJM',
      pk_unit: '0001A21000000000AVN9',
      pk_lessee: '1001A21000000004ZRNB',
      pk_building: '1001A21000000003TGAP',
      pk_brand: '1001A21000000003TEGN',
      d_rev_fare_date: '2013-08-31T00:00:00.000Z',
      srn_rev_mny: 377128.5,
      rev_source: '贷记凭证.',
      dr: 0,
      project_name: '上海港汇恒隆广场',
      site: 'GG',
      v_cust_name: '巴丽（上海）商业有限公司',
      building_type: '商场',
      unit_name: '111',
      v_pact_num: 'CJ100004',
      v_brand_name: 'BALLY',
    },
    {
      pk_re_fare_id: '1001A21000000004OC0P',
      pk_actym: '2012-05',
      pk_pact: '1001A21000000003XSYI',
      pk_fare_type_id: '1001A21000000003TFJN',
      pk_unit: '0001A21000000000AVN9',
      pk_lessee: '1001A21000000004ZRNB',
      pk_building: '1001A21000000003TGAP',
      pk_brand: '1001A21000000003TEGN',
      d_rev_fare_date: '2013-08-31T00:00:00.000Z',
      srn_rev_mny: 766535.5,
      rev_source: '贷记凭证.',
      dr: 0,
      project_name: '上海港汇恒隆广场',
      site: 'GG',
      v_cust_name: '巴丽（上海）商业有限公司',
      building_type: '商场',
      unit_name: '111',
      v_pact_num: 'CJ100004',
      v_brand_name: 'BALLY',
    },
  ],
};

export default PaymentHistory;
