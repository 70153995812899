import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import generalStyles from '../../styles/style';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Typography, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCNLocale from 'date-fns/locale/zh-CN';
import enLocale from 'date-fns/locale/en-US';
import zhTWLocale from 'date-fns/locale/zh-TW';
import ListboxComponent from '../components/listbox';
import { csvRequest } from '../../../api/api';

const styles = makeStyles((theme) => ({
  datepicker: {
    width: 'calc((100% - 12px) / 2)',
    '& > .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
}));

const listboxStyle = makeStyles(() => ({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
}));

const InlineDatePicker = ({ formValueChange, formValue, dpLocale }) => {
  const { t, i18n } = useTranslation();
  const generalClasses = generalStyles();
  const classes = styles();
  const localeMap = {
    en: enLocale,
    sc: zhCNLocale,
    tc: zhTWLocale,
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[dpLocale]}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="yyyy-MM-dd"
          // initialFocusedDate={format(subYears(new Date(), 1), 'yyyy-MM-dd')}
          value={formValue.m_make_date_from}
          onChange={(date, value) => {
            formValueChange('m_make_date_from', value);
          }}
          placeholder={t('general:date')}
          className={[generalClasses.autoComplete, classes.datepicker].join(' ')}
          invalidDateMessage={t('error:invalid_date_format')}
        />
        <span style={{ padding: '0 2px', width: '12px', display: 'inline-block', textAlign: 'center', lineHeight: '1' }}>-</span>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="yyyy-MM-dd"
          // initialFocusedDate={format(new Date(), 'yyyy-MM-dd')}
          value={formValue.m_make_date_to}
          onChange={(date, value) => {
            formValueChange('m_make_date_to', value);
          }}
          placeholder={t('general:date')}
          className={[generalClasses.autoComplete, classes.datepicker].join(' ')}
          invalidDateMessage={t('error:invalid_date_format')}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

const BillingForm = ({ searchAction, formItems, formValueChange, formValue, checkNoPrimary, downloadBtn }) => {
  const { t, i18n } = useTranslation(['general', 'error']);
  const [dpLocale, setDpLocale] = useState(i18n.language);
  const listboxClasses = listboxStyle();
  const generalClasses = generalStyles();

  useEffect(() => {
    setDpLocale(i18n.language);
  }, [i18n.language]);

  return (
    <div>
      <Box p={3} my={3} className={generalClasses.root}>
        <Grid container alignItems="flex-start" spacing={1}>
          {formItems.map(({ key, type, label, xs, sm, lg, required, options = [] }) => {
            return (
              <Grid item xs={xs} sm={sm} key={key}>
                {type === 'multiselect' && (
                  <div>
                    <Typography>
                      {t(label)}
                      {required ? '*' : ''}
                    </Typography>
                    <FormControl fullWidth>
                      <Autocomplete
                        id={key}
                        className={generalClasses.autoComplete}
                        classes={listboxClasses}
                        multiple
                        value={formValue[key] || []}
                        options={options}
                        onChange={(event, value) => {
                          formValueChange(key, value);
                        }}
                        noOptionsText={t('general:no_options')}
                        ListboxComponent={ListboxComponent}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.text}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.text}
                          </React.Fragment>
                        )}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t(label)} />}
                      />
                    </FormControl>
                  </div>
                )}
                {type === 'date' && (
                  <div>
                    <Typography>{t(label)}</Typography>
                    <InlineDatePicker formValueChange={formValueChange} formValue={formValue} dpLocale={dpLocale} />
                  </div>
                )}
                {type === 'text' && (
                  <div>
                    <Typography>{t(label)}</Typography>
                    <FormControl fullWidth>
                      <TextField
                        id={key}
                        className={generalClasses.formInput}
                        placeholder={t(label)}
                        variant="outlined"
                        value={formValue[key] || ''}
                        onChange={(event) => formValueChange(key, event.target.value)}
                      />
                    </FormControl>
                  </div>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Box mt={2}>
          <Grid container direction="row" alignItems="center" spacing={1} className={generalClasses.button}>
            <Grid item>
              <Button variant="contained" style={{ marginRight: 8 }} onClick={searchAction}>
                {t('general:search')}
              </Button>
            </Grid>
            {downloadBtn === true && (
              <Grid item>
                <Button variant="contained" disableElevation onClick={() => csvRequest()}>
                  {t('general:download_all')}
                </Button>
              </Grid>
            )}
            {checkNoPrimary === true && (
              <Grid item>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('general:display_no_primary_only')}
                  labelPlacement="end"
                  onChange={(event) => formValueChange('checkNoPrimary', event.target.checked)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

BillingForm.propTypes = {
  searchAction: PropTypes.func,
  formItems: PropTypes.array,
  formValueChange: PropTypes.func,
  formValue: PropTypes.object,
  checkNoPrimary: PropTypes.bool,
  downloadBtn: PropTypes.bool,
};

InlineDatePicker.propTypes = {
  formValueChange: PropTypes.func,
  formValue: PropTypes.object,
  dpLocale: PropTypes.string,
};

export default BillingForm;
