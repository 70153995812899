import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Backdrop,
  Fade,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@material-ui/core';
import generalStyles from '../styles/style';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../context/context';
import url from '@websanova/url/dist/es5-url.min.js';
import { useTimer } from 'react-timer-hook';
import { makeRequest, sendApiError } from '../../api/api';
import AlertDialog from '../../components/dialog';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import tnc from '../../content/tnc';
import Cookies from 'js-cookie';
import axios from 'axios';

import passwordValidator from 'password-validator';
const pwSchema = new passwordValidator();
pwSchema.min(10).uppercase().lowercase().digits().symbols();

const localStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px', // default 1 = 8px
  },
  modalContent: {
    background: 'white',
    maxWidth: '600px',
    width: '100%',
    padding: '24px 0 24px 24px',
    '&:focus': {
      outline: 'none',
    },
    position: 'relative',
    '& > div': {
      width: '100%',
      height: '85vh',
      overflowY: 'auto',
      fontFamily: ['Fangsong', 'STFangsong', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      '& > h2': {
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
      '& ol': {
        paddingLeft: '20px',
      },
    },
  },
  modalClose: {
    position: 'absolute',
    top: 24,
    right: 24,
    cursor: 'pointer',
  },
}));

const apiUrl = process.env.REACT_APP_API_LINK;

const Register = (props) => {
  // const temp_password = 'PE<gN<Mf38)T';
  const { t, i18n } = useTranslation();
  const context = useContext(AppContext);
  const { setLoaderOpen, setSnackBar, setLoginUsername } = context;
  const [username, setUsername] = useState();
  const [formValue, setFormValue] = useState({ agree: false });
  const [error, setError] = useState();
  const [disableMFABtn, setDisableMFABtn] = useState(false);
  const { seconds, restart } = useTimer({
    onExpire: () => setDisableMFABtn(false),
  });
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const generalClasses = generalStyles();
  const localClasses = localStyles();
  const dialogRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const url_username = url('?u') || url('?username');
    console.log(url_username);
    console.log(isNaN(url_username));
    if (url_username) {
      setUsername(isNaN(url_username) ? url_username : `+${url_username}`);
    }
    Cookies.remove('mfaToken');
  }, []);

  useEffect(() => {
    if (username) {
      //console.log(username);
      setLoaderOpen(true);
      const checkUserPromise = new Promise((resolve) => {
        const res = makeRequest(['user-auth'], ['checkUserStatus'], [{ username }]);
        resolve(res);
      });
      checkUserPromise
        .then((res) => {
          //console.log(res);
          if (res[0].data) {
            const { message } = res[0].data;
            //console.log(message);
            if (message === 'continue to register') {
              //* continue
            } else if (message === 'user registered') {
              setLoginUsername(username);
              history.push('/');
            } else if (message === 'no this user') {
              setError({ error_user_account_error: true });
            } else if (message === 'account error') {
              setError({ error_user_account_error: true });
            }
          }
          setLoaderOpen(false);
        })
        .catch((error) => {
          setLoaderOpen(false);
          sendApiError(error, 'register: checkUserStatus');
        });
    }
  }, [username]);

  const handleTextFieldChange = (e) => {
    const name = e.currentTarget.getAttribute('name');
    const value = e.currentTarget.value;
    setFormValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSendMFA = async () => {
    const { new_password, re_new_password } = formValue;
    setError();
    if (!new_password || new_password === '' || !re_new_password || re_new_password === '') {
      console.log('password empty');
      setError({ empty_password: true });
      return;
    }
    if (!pwSchema.validate(new_password)) {
      console.log('password pattern not correct');
      setError({ error_password_length_pattern: true });
      return;
    }
    setLoaderOpen(true);
    try {
      const res = await makeRequest(['user-auth'], ['sendMFA'], [{ username, type: 'register' }]);
      console.log(res);
      if (res[0].data) {
        const { message, token } = res[0].data;
        console.log(message);
        if (message === 'done sendMFA') {
          const time = new Date();
          time.setSeconds(time.getSeconds() + 60);
          restart(time);
          setDisableMFABtn(true);
          Cookies.set('mfaToken', token, { secure: true });
        } else if (message === 'account status error') {
          setError({ error_user_account_error: true });
        } else if (message === 'fail sendMFA') {
          setError({ error_send_mfa: true });
        }
      }
    } catch (error) {
      sendApiError(error, 'register: sendMFA error');
    }
    setLoaderOpen(false);
  };

  const handleAgreeClick = () => {
    setFormValue((prev) => {
      return {
        ...prev,
        agree: !prev.agree,
      };
    });
  };

  const handleRegisterClick = async () => {
    const { agree, mfa_code, new_password } = formValue;
    console.log(username);
    setError();
    if (agree && (mfa_code || mfa_code !== '')) {
      setLoaderOpen(true);
      try {
        const res = await axios({
          method: 'post',
          url: `${apiUrl}/user-auth/`,
          headers: {
            Authorization: Cookies.get('mfaToken'),
          },
          data: {
            entry: 'userRegister',
            username,
            password: new_password,
            code: mfa_code,
          },
        });
        console.log(res);
        if (res) {
          const { message } = res.data;
          console.log(message);
          if (message === 'session expired') {
            setError({ error_mfa: true });
          } else if (message === 'register done') {
            setSnackBar((prev) => ({
              ...prev,
              open: true,
              message: t('error:success_message.register_completed'),
            }));
            setLoginUsername(username);
            history.push('/');
            Cookies.remove('mfaToken');
          } else if (message === 'password format incorrect') {
            setError({ error_password_length_pattern: true });
          } else if (message === 'user account error') {
            setError({ error_user_account_error: true });
          }
        }
      } catch (error) {
        sendApiError(error, 'register: submit register');
      }
      setLoaderOpen(false);
    } else if (!mfa_code || mfa_code === '') {
      setError({ empty_verify: true });
    } else {
      setError({ error_agree_tnc: true });
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleReturnToLogin = () => {
    history.replace('/');
  };

  const handleShowPw = () => {
    setShowPassword(true);
  };
  const handleHidePw = () => {
    setShowPassword(false);
  };

  return (
    <div>
      <Typography style={{ marginBottom: 25 }}>{t('general:register.heading')}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box style={{ textAlign: 'left' }}>
            {t('general:register.username')}: <strong>{username}</strong>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              className={generalClasses.formInput}
              required
              name="new_password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('general:register.new_password')}
              variant="outlined"
              onChange={handleTextFieldChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleShowPw}
                    onMouseUp={handleHidePw}
                    onTouchStart={handleShowPw}
                    onTouchEnd={handleHidePw}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              className={generalClasses.formInput}
              required
              name="re_new_password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('general:register.re_enter_new_password')}
              variant="outlined"
              onChange={handleTextFieldChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleShowPw}
                    onMouseUp={handleHidePw}
                    onTouchStart={handleShowPw}
                    onTouchEnd={handleHidePw}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ fontSize: '12px', textAlign: 'left' }}>{t('general:register.pw_pattern')}</Box>
        </Grid>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <TextField
              className={generalClasses.formInput}
              required
              name="mfa_code"
              placeholder={t('general:register.mfa_code')}
              variant="outlined"
              onChange={handleTextFieldChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} className={generalClasses.button}>
          <Button style={{ textTransform: 'none', width: '100%' }} onClick={handleSendMFA} disabled={disableMFABtn}>
            <Box display={disableMFABtn ? 'none' : 'block'}>{t('general:register.send_mfa')}</Box>
            <Box display={disableMFABtn ? 'block' : 'none'}>
              {seconds}
              {t('general:can_resend')}
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Button size="small" style={{ color: 'red', textAlign: 'left' }} disabled>
            {error && error.error_verify && t('error:error_verify')}
            {error && error.empty_password && t('error:empty_password')}
            {error && error.empty_verify && t('error:empty_verify')}
            {error && error.password_not_same && t('error:password_not_same')}
            {error && error.error_password_length_pattern && t('error:error_password_length_pattern')}
            {error && error.empty_verify_pw && t('error:empty_verify_pw')}
            {error && error.user_not_found && t('error:user_not_found')}
            {error && error.error_agree_tnc && t('error:error_agree_tnc')}
            {error && error.error_send_mfa && t('error:error_send_mfa')}
            {error && error.error_user_account_error && t('error:error_user_account_error')}
            {error && error.user_session_expired && t('error:user_session_expired')}
            {error && error.error_mfa && t('error:error_mfa')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Box style={{ textAlign: 'left' }}>
                <FormControlLabel
                  control={<Checkbox checked={formValue.agree} onChange={handleAgreeClick} name="agree" />}
                  label={t('general:register.agree')}
                  style={{ marginRight: 0 }}
                />
                {i18n.language === 'en' ? ' ' : ''}
                <button
                  onClick={handleModalOpen}
                  style={{
                    color: 'inherit',
                    fontSize: '1rem',
                    lineHeight: 1,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    appearance: 'none',
                    border: '0',
                    padding: '0',
                    outline: 'none',
                  }}
                >
                  {t('general:register.tnc')}
                </button>
              </Box>
            </Grid>
            <Grid item className={generalClasses.button} xs={4} style={{ textAlign: 'right' }}>
              <Button onClick={handleRegisterClick}>{t('general:register.register')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={localClasses.modal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={localClasses.modalContent}>
            <CloseIcon className={localClasses.modalClose} onClick={handleModalClose} />
            <div dangerouslySetInnerHTML={{ __html: tnc }}></div>
          </div>
        </Fade>
      </Modal>
      <AlertDialog
        ref={dialogRef}
        text={t('general:user_not_exist')}
        close_action={true}
        confirm_action={handleReturnToLogin}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
    </div>
  );
};

export default Register;
