import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Hidden, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';

//* Context
import { PaymentHistoryContext } from '../context/context';

//* Style
import generalStyles from '../layouts/styles/style';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
  },
  button: {
    borderBottom: '1px solid #373A3C',
    cursor: 'pointer',
    display: 'inline-block',
  },
});

const PHTable = () => {
  const context = useContext(PaymentHistoryContext);
  const {
    tableFields,
    tableData,
    pagCount,
    pagPage,
    pagDisable,
    pageTotal,
    sumTotal,
    bPreRev,
    handlePageChange,
    handleSummaryAmountClick,
    tabIdx,
    handleTotalClick,
    handleSortClick,
    order,
    orderBy,
  } = context;
  const { t } = useTranslation();
  const generalClasses = generalStyles();
  const classes = useStyles();

  const colSpan = tableFields.length - 2;

  //* State
  // const [order, setOrder] = useState('desc');
  // const [orderBy, setOrderBy] = useState('payment_date');

  return (
    <Box p={3} my={4} className={generalClasses.root}>
      <Hidden xsDown>
        <Box component={Paper}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableFields &&
                    tableFields.map(({ key, label, field_name }) => {
                      let comp;
                      if (key !== 'b_pre_rev') {
                        comp = (
                          <TableCell key={key} sortDirection={orderBy === field_name ? order : 'asc'} style={{ whiteSpace: 'nowrap' }}>
                            <TableSortLabel
                              active={orderBy === field_name}
                              direction={orderBy === field_name ? order : 'asc'}
                              onClick={handleSortClick(field_name)}
                            >
                              {t(label)}
                            </TableSortLabel>
                          </TableCell>
                        );
                      } else {
                        comp = (
                          <TableCell key={key} style={{ whiteSpace: 'nowrap' }}>
                            {t(label)}
                          </TableCell>
                        );
                      }
                      return comp;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        {tableFields.map(({ key, field_name }) => {
                          return (
                            <TableCell key={key}>
                              {field_name === 'd_rev_fare_date' || field_name === 'm_begin_date' ? (
                                format(parseISO(row[field_name]), 'yyyy-MM-dd')
                              ) : field_name === 'srn_rev_mny' || field_name === 'nybqys' || field_name === 'received' ? (
                                <div style={{ textAlign: 'right' }}>
                                  <NumberFormat value={row[field_name]} displayType="text" thousandSeparator decimalScale="2" fixedDecimalScale />
                                </div>
                              ) : field_name === 'total_un_collected' || field_name === 'un_collected' ? (
                                <div style={{ textAlign: 'right' }}>
                                  {tabIdx === 2 || tabIdx === 1 ? (
                                    <NumberFormat value={row[field_name]} displayType="text" thousandSeparator decimalScale="2" fixedDecimalScale />
                                  ) : (
                                    <div
                                      className={classes.button}
                                      role="button"
                                      onClick={() => {
                                        handleSummaryAmountClick(row, field_name);
                                      }}
                                    >
                                      <NumberFormat value={row[field_name]} displayType="text" thousandSeparator decimalScale="2" fixedDecimalScale />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                row[field_name]
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {pageTotal !== 0 && typeof pageTotal !== 'object' && (
                  <TableRow>
                    <TableCell colSpan={colSpan}></TableCell>
                    <TableCell>{t('general:page_total')}</TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        <NumberFormat value={pageTotal} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {sumTotal !== 0 && typeof sumTotal !== 'object' && (
                  <TableRow>
                    <TableCell colSpan={colSpan}></TableCell>
                    <TableCell>{t('general:sum_total')}</TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        {tabIdx === 0 || tabIdx === 1 ? (
                          <div
                            className={classes.button}
                            role="button"
                            onClick={() => {
                              handleTotalClick(2);
                            }}
                          >
                            <NumberFormat value={sumTotal} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                          </div>
                        ) : (
                          <NumberFormat value={sumTotal} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {typeof pageTotal === 'object' && (
                  <TableRow>
                    <TableCell colSpan={8}></TableCell>
                    <TableCell>{t('general:page_total')}</TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        <NumberFormat value={pageTotal.nybqys_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        <NumberFormat value={pageTotal.received_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        <NumberFormat value={pageTotal.un_collected_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </div>
                    </TableCell>
                    {tabIdx === 2 && <TableCell></TableCell>}
                  </TableRow>
                )}
                {typeof sumTotal === 'object' && (
                  <TableRow>
                    <TableCell colSpan={8}></TableCell>
                    <TableCell>{t('general:sum_total')}</TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        <NumberFormat value={sumTotal.nybqys_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        <NumberFormat value={sumTotal.received_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ textAlign: 'right' }}>
                        {tabIdx === 1 ? (
                          <div
                            className={classes.button}
                            role="button"
                            onClick={() => {
                              handleTotalClick(2);
                            }}
                          >
                            <NumberFormat value={sumTotal.un_collected_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                          </div>
                        ) : (
                          <NumberFormat value={sumTotal.un_collected_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                        )}
                      </div>
                    </TableCell>
                    {tabIdx === 2 && <TableCell></TableCell>}
                  </TableRow>
                )}
                {bPreRev !== undefined && bPreRev !== 0 && (
                  <TableRow>
                    <TableCell colSpan={8}></TableCell>
                    <TableCell>{t('general:b_pre_rev')}</TableCell>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell>
                      <NumberFormat value={bPreRev} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Hidden>
      <Hidden smUp>
        {tableData &&
          tableData.map((row) => (
            <Box mb={2} key={row.id}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {tableFields.map(({ key, field_name, label }) => {
                      return (
                        <TableRow key={key}>
                          <TableCell style={{ width: 140 }}>
                            <Typography>{t(label)}</Typography>
                          </TableCell>
                          <TableCell>
                            {field_name === 'd_rev_fare_date' ? (
                              format(parseISO(row[field_name]), 'yyyy-MM-dd')
                            ) : field_name === 'srn_rev_mny' ||
                              field_name === 'total_un_collected' ||
                              field_name === 'nybqys' ||
                              field_name === 'un_collected' ? (
                              <>
                                {field_name === 'total_un_collected' && tabIdx === 0 ? (
                                  <div
                                    className={classes.button}
                                    role="button"
                                    onClick={() => {
                                      handleSummaryAmountClick(row, field_name);
                                    }}
                                  >
                                    <NumberFormat value={row[field_name]} displayType="text" thousandSeparator decimalScale="2" fixedDecimalScale />
                                  </div>
                                ) : (
                                  <NumberFormat value={row[field_name]} displayType="text" thousandSeparator decimalScale="2" fixedDecimalScale />
                                )}
                              </>
                            ) : (
                              row[field_name]
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        {pageTotal !== 0 && sumTotal !== 0 && typeof pageTotal !== 'object' && typeof sumTotal !== 'object' && (
          <Box mb={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: 140 }}>{t('general:page_total')}</TableCell>
                    <TableCell>
                      <NumberFormat value={pageTotal} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: 140 }}>{t('general:sum_total')}</TableCell>
                    <TableCell>
                      {
                        <div
                          className={classes.button}
                          role="button"
                          onClick={() => {
                            handleTotalClick(2);
                          }}
                        >
                          <NumberFormat value={sumTotal} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {typeof pageTotal === 'object' && typeof sumTotal === 'object' && (
          <Box mb={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: 140 }}></TableCell>
                    <TableCell>{t('general:receivables_amount')}</TableCell>
                    <TableCell>{t('general:received_amount')}</TableCell>
                    <TableCell>{t('general:un_collected')}</TableCell>
                    {bPreRev !== undefined && bPreRev !== 0 && <TableCell>{t('general:b_pre_rev')}</TableCell>}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: 140 }}>{t('general:page_total')}</TableCell>
                    <TableCell>
                      <NumberFormat value={pageTotal.nybqys_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                    <TableCell>
                      <NumberFormat value={pageTotal.received_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                    <TableCell>
                      <NumberFormat value={pageTotal.un_collected_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                    {bPreRev !== undefined && bPreRev !== 0 && <TableCell></TableCell>}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: 140 }}>{t('general:sum_total')}</TableCell>
                    <TableCell>
                      <NumberFormat value={sumTotal.nybqys_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                    <TableCell>
                      <NumberFormat value={sumTotal.received_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                    </TableCell>
                    <TableCell>
                      {tabIdx === 1 ? (
                        <div
                          className={classes.button}
                          role="button"
                          onClick={() => {
                            handleTotalClick(2);
                          }}
                        >
                          <NumberFormat value={sumTotal.un_collected_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                        </div>
                      ) : (
                        <NumberFormat value={sumTotal.un_collected_total} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      )}
                    </TableCell>
                    {bPreRev !== undefined && bPreRev !== 0 && <TableCell></TableCell>}
                  </TableRow>
                  {bPreRev !== undefined && bPreRev !== 0 && (
                    <TableRow>
                      <TableCell>{t('general:b_pre_rev')}</TableCell>
                      <TableCell colSpan={3}></TableCell>
                      <TableCell>
                        <NumberFormat value={bPreRev} thousandSeparator displayType="text" decimalScale="2" fixedDecimalScale />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Hidden>
      <Box mt={2}>
        <Grid container justify="center">
          <Grid item>
            <Pagination count={pagCount} page={pagPage} onChange={handlePageChange} disabled={pagDisable} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PHTable;
