import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveDrawer from '../components/drawer';
import MobileAppBar from '../components/appbar';
import { AppContext } from '../context/context';
import AlertDialog from '../components/dialog';
import handleLogout from '../helper/handleLogout';

const drawerWidth = 240;

const styles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: drawerWidth + 30,
      padding: 30,
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
}));

const MainLayout = (props) => {
  const { t } = useTranslation(['error']);
  // const cookies = useCookies();
  const classes = styles();
  const context = useContext(AppContext);
  const DialogRef = useRef();
  const { dialogOpen } = context;
  const { MainContent } = props;
  // const history = useHistory();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(true);

  // useEffect(() => {
  //   if (!cookies[0].accessToken) {
  //     history.replace('/');
  //   }
  // }, []);

  useEffect(() => {
    if (dialogOpen) {
      DialogRef.current.open();
    }
  }, [dialogOpen]);

  return (
    <div>
      <MobileAppBar drawerOpen={mobileDrawerOpen} drawerOnToggle={() => setMobileDrawerOpen(!mobileDrawerOpen)} />
      <ResponsiveDrawer
        width={drawerWidth}
        mobileDrawerOpen={mobileDrawerOpen}
        mobileDrawerOnClose={() => {
          setMobileDrawerOpen(false);
        }}
        handleClose={() => setMobileDrawerOpen(false)}
      />
      <main className={classes.main}>
        <AlertDialog
          ref={DialogRef}
          text={t('error:null_role')}
          confirm_action={handleLogout}
          close_action={true}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        />
        {MainContent || null}
      </main>
    </div>
  );
};

MainLayout.propTypes = {
  MainContent: PropTypes.element,
};

export default MainLayout;
