import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import generalStyles from '../styles/style';
import { Typography } from '@material-ui/core';
import BillingForm from './components/billing_form';
import ServerTable from '../../components/serverload_table';
import { makeRequest, pdfRequest } from '../../api/api';
import { AppContext, TableContext } from '../../context/context';
import AlertDialog from '../../components/dialog';
import Cookies from 'js-cookie';
import { usePrevious } from 'react-delta';
import useRequestList from '../../hooks/useRequestList';
import { find, isEqual } from 'lodash';

const PaymentReminder = () => {
  const generalClasses = generalStyles();
  const { t } = useTranslation(['menu', 'general', 'error']);
  const DialogRef = useRef();
  const PdfRef = useRef();
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageDisable, setPageDisable] = useState(false);
  const [paymentReminderList, setPaymentReminderList] = useState([]);
  const [formOptions, setFormOptions] = useState({});
  const [formOptionsSite, setFormOptionsSite] = useState([]);
  const [cookiesVal, setCookiesVal] = useState({
    site: false,
    pk_lessee: false,
  });
  const [formValue, setFormValue] = useState({
    site_list: [],
    pk_lessee_list: [],
    building_type_list: [],
    pk_unit_list: [],
    pk_pact_list: [],
    pk_actym_list: [],
    m_make_date_from: null,
    m_make_date_to: null,
  });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('pk_actym');
  const prevFormValue = usePrevious(formValue);
  const prevOrder = usePrevious(order);
  const prevOrderBy = usePrevious(orderBy);
  const handleSetRequestList = useRequestList();

  //fetch data*******************************************************************************
  const fetchPaymentReminderList = async (page) => {
    if (formValue.site_list.length < 1) {
      DialogRef.current.open();
    } else {
      setLoaderOpen(true);
      const profile = constructQueryProfile();
      const data = await makeRequest(['user-management'], ['getPaymentReminderList'], [{ profile, page, sorting: { order, orderBy } }]);
      if (data) {
        if (Array.isArray(data[0].data.body.results)) {
          if (data[0].data.body.results.length !== 0) {
            setPaymentReminderList(data[0].data.body.results);
            setPageNo(page);
            setPageCount(Math.ceil(data[0].data.body.total / 10));
            setPageDisable(data[0].data.body.total <= 10 ? true : false);
          } else {
            setPaymentReminderList([]);
            setPageNo(1);
            setPageCount(1);
            setPageDisable(true);
          }
        }
      }
      setLoaderOpen(false);
    }
  };

  const handlePdf = async (method, row) => {
    setLoaderOpen(true);
    const res = await pdfRequest(method, row.site, '95DX', row.pk_actym, row.pk_dunning);
    setLoaderOpen(false);
    if (res !== true && res.data.errorCode) {
      PdfRef.current.open();
    }
  };

  const getFormOptions = async () => {
    console.log('getFormOptions');
    const formValue = constructQueryProfile();

    (async () => {
      setLoaderOpen(true);
      const resCustomerList = await makeRequest(['user-management'], ['getCustomerName'], [{ formValue, page: 'payment_reminder' }]);
      if (resCustomerList[0].data) {
        const customerList = resCustomerList[0].data.body;
        if (customerList.length > 0) {
          const options = customerList.reduce((acc, item) => {
            const { v_cust_name, pk_lessee, v_site } = item;
            const newItem = {
              text: `${v_cust_name} (${v_site})`,
              value: `${v_site}::${pk_lessee}`,
              pk_lessee,
            };
            acc.push(newItem);
            return acc;
          }, []);
          setFormOptions((prev) => ({
            ...prev,
            pk_lessee_list: options,
          }));
        } else {
          setFormOptions((prev) => ({
            ...prev,
            pk_lessee_list: [],
          }));
        }
      }
      setLoaderOpen(false);
    })();

    (async () => {
      setLoaderOpen(true);
      const resBuildingList = await makeRequest(['user-management'], ['getBuilding'], [{ formValue, page: 'payment_reminder' }]);
      if (resBuildingList[0].data) {
        const buildingList = resBuildingList[0].data.body;
        if (buildingList.length > 0) {
          const options = buildingList.reduce((acc, item) => {
            const { building_type, v_site } = item;
            const newItem = {
              text: `${building_type} (${v_site})`,
              value: `${v_site}::${building_type}`,
            };
            acc.push(newItem);
            return acc;
          }, []);
          setFormOptions((prev) => ({
            ...prev,
            building_type_list: options,
          }));
        } else {
          setFormOptions((prev) => ({
            ...prev,
            building_type_list: [],
          }));
        }
      }
      setLoaderOpen(false);
    })();

    (async () => {
      setLoaderOpen(true);
      const resUnitList = await makeRequest(['user-management'], ['getUnit'], [{ formValue, page: 'payment_reminder' }]);
      if (resUnitList[0].data) {
        const unitList = resUnitList[0].data.body;
        if (unitList.length > 0) {
          const options = unitList.reduce((acc, item) => {
            const { pk_unit, unit_name, v_site } = item;
            const newItem = {
              text: `${unit_name} (${v_site})`,
              value: `${v_site}::${pk_unit}`,
            };
            acc.push(newItem);
            return acc;
          }, []);
          setFormOptions((prev) => ({
            ...prev,
            pk_unit_list: options,
          }));
        } else {
          setFormOptions((prev) => ({
            ...prev,
            pk_unit_list: [],
          }));
        }
      }
      setLoaderOpen(false);
    })();

    (async () => {
      setLoaderOpen(true);
      const resBillMonthList = await makeRequest(['user-management'], ['getBillingMonth'], [{ formValue, page: 'payment_reminder' }]);
      if (resBillMonthList[0].data) {
        const billMonthList = resBillMonthList[0].data.body;
        if (billMonthList.length > 0) {
          const options = billMonthList.reduce((acc, item) => {
            const { pk_actym, v_site } = item;
            const newItem = {
              text: `${pk_actym} (${v_site})`,
              value: `${v_site}::${pk_actym}`,
            };
            acc.push(newItem);
            return acc;
          }, []);
          setFormOptions((prev) => ({
            ...prev,
            pk_actym_list: options,
          }));
        } else {
          setFormOptions((prev) => ({
            ...prev,
            pk_actym_list: [],
          }));
        }
      }
      setLoaderOpen(false);
    })();

    (async () => {
      setLoaderOpen(true);
      const resPactList = await makeRequest(['user-management'], ['getContractNo'], [{ formValue, page: 'payment_reminder' }]);
      if (resPactList[0].data) {
        const pactList = resPactList[0].data.body;
        if (pactList.length > 0) {
          const options = pactList.reduce((acc, item) => {
            const { pk_pact, v_pact_num, v_site } = item;
            const newItem = {
              text: `${v_pact_num} (${v_site})`,
              value: `${v_site}::${pk_pact}`,
            };
            acc.push(newItem);
            return acc;
          }, []);
          setFormOptions((prev) => ({
            ...prev,
            pk_pact_list: options,
          }));
        } else {
          setFormOptions((prev) => ({
            ...prev,
            pk_pact_list: [],
          }));
        }
      }
      setLoaderOpen(false);
    })();
  };

  //useEffect******************************************************************************
  useEffect(() => {
    const fetchFormItems = async () => {
      handleSetRequestList('paymentReminderPage', false, true);
      const res = await makeRequest(['user-management'], ['getSiteList2']);
      console.log(res);
      if (res[0].data) {
        const { body } = res[0].data;
        const options = body.reduce((acc, item) => {
          const { project_name, site } = item;
          const newItem = {
            text: `${project_name} (${site})`,
            value: site,
          };
          acc.push(newItem);
          return acc;
        }, []);
        setFormOptionsSite(options);
      }
      handleSetRequestList('paymentReminderPage', true);
    };

    const cookies_site_list = Cookies.get('site_list');
    const cookies_pk_lessee_list = Cookies.get('pk_lessee_list');
    console.log(cookies_site_list, cookies_pk_lessee_list);
    setCookiesVal((prev) => ({
      ...prev,
      site: cookies_site_list ? true : false,
      pk_lessee: cookies_pk_lessee_list ? true : false,
    }));
    fetchFormItems();
  }, []);

  useEffect(() => {
    if (formOptionsSite.length > 0) {
      setFormOptions((prev) => ({
        ...prev,
        site_list: formOptionsSite,
      }));
    }
  }, [formOptionsSite]);

  useEffect(() => {
    console.log(formValue);
    const { site_list, pk_lessee_list } = formValue;
    const prev_lessee_list = prevFormValue ? prevFormValue.pk_lessee_list : [];
    if (!isEqual(prevFormValue, formValue)) {
      if (site_list.length > 0) {
        if (site_list.length === 1) {
          Cookies.set('site_list', JSON.stringify(site_list[0]));
        } else {
          Cookies.remove('site_list');
        }
        if (pk_lessee_list.length === 1) {
          Cookies.set('pk_lessee_list', JSON.stringify(pk_lessee_list[0]));
        } else if (prev_lessee_list.length !== 0 && pk_lessee_list.length === 0) {
          Cookies.remove('pk_lessee_list');
        }
        getFormOptions();
      } else {
        setFormValue((prev) => ({
          ...prev,
          site_list: [],
          pk_lessee_list: [],
          building_type_list: [],
          pk_unit_list: [],
          pk_pact_list: [],
          pk_actym_list: [],
          m_make_date_from: null,
          m_make_date_to: null,
        }));
        setFormOptions((prev) => ({
          ...prev,
          pk_lessee_list: [],
          building_type_list: [],
          pk_unit_list: [],
          pk_pact_list: [],
          pk_actym_list: [],
        }));
        if (prevFormValue) {
          Cookies.remove('site_list');
          Cookies.remove('pk_lessee_list');
        }
      }
    }
  }, [formValue]);

  useEffect(() => {
    if (formOptions.site_list && cookiesVal.site) {
      if (formOptions.site_list.length > 0) {
        if (Cookies.get('site_list')) {
          const cookies_site_list = JSON.parse(Cookies.get('site_list'));
          console.log(cookies_site_list, formOptions.site_list);
          if (cookies_site_list) {
            const optionValue = cookies_site_list.value.split('::').length === 2 ? cookies_site_list.value.split('::')[1] : cookies_site_list.value;
            const foundOption = find(formOptions.site_list, (o) => o.value === optionValue);
            console.log(foundOption);
            if (foundOption) {
              setFormValue((prev) => ({
                ...prev,
                site_list: [cookies_site_list],
              }));
              setCookiesVal((prev) => ({
                ...prev,
                site: false,
              }));
            } else {
              setCookiesVal((prev) => ({
                ...prev,
                site: false,
              }));
            }
          }
        }
      }
    }
  }, [formOptions.site_list, cookiesVal.site]);

  useEffect(() => {
    if (formOptions.pk_lessee_list && cookiesVal.pk_lessee) {
      if (formOptions.pk_lessee_list.length > 0) {
        if (Cookies.get('pk_lessee_list') && Cookies.get('site_list')) {
          const cookies_pk_lessee_list = JSON.parse(Cookies.get('pk_lessee_list'));
          console.log(cookies_pk_lessee_list, formOptions.pk_lessee_list);
          if (cookies_pk_lessee_list) {
            const optionValue =
              cookies_pk_lessee_list.value.split('::').length === 2 ? cookies_pk_lessee_list.value.split('::')[1] : cookies_pk_lessee_list.value;
            console.log(optionValue);
            const foundOption = find(formOptions.pk_lessee_list, (o) => o.pk_lessee === optionValue);
            console.log(foundOption);
            if (foundOption) {
              setFormValue((prev) => ({
                ...prev,
                pk_lessee_list: [...prev.pk_lessee_list, foundOption],
              }));
              setCookiesVal((prev) => ({
                ...prev,
                pk_lessee: false,
              }));
            } else {
              setCookiesVal((prev) => ({
                ...prev,
                pk_lessee: false,
              }));
            }
          }
        }
      }
    }
  }, [formOptions.pk_lessee_list, cookiesVal.pk_lessee]);

  useEffect(() => {
    if (prevOrder && prevOrderBy) {
      fetchPaymentReminderList(1);
    }
  }, [order, orderBy]);

  //searching******************************************************************************
  const constructQueryProfile = () => {
    let jsonData = {
      m_make_date_from: formValue['m_make_date_from'],
      m_make_date_to: formValue['m_make_date_to'],
    };
    const arraylist = ['site_list', 'building_type_list', 'pk_lessee_list', 'pk_unit_list', 'pk_pact_list', 'pk_actym_list'];
    arraylist.map((key) => {
      jsonData[key] = formValue[key].reduce((acc, item, index) => {
        acc.push(item.value);
        return acc;
      }, []);
    });
    return jsonData;
  };

  //form items*****************************************************************************
  const formItems = [
    {
      key: 'site_list',
      type: 'multiselect',
      label: 'general:site',
      xs: 12,
      sm: 12,
      lg: 12,
      required: true,
      options: formOptions['site_list'] || [],
    },
    {
      key: 'pk_lessee_list',
      type: 'multiselect',
      label: 'general:cust_grp.cust_name',
      xs: 12,
      sm: 12,
      lg: 12,
      options: formOptions['pk_lessee_list'] || [],
    },
    {
      key: 'building_type_list',
      type: 'multiselect',
      label: 'general:segment',
      xs: 12,
      sm: 4,
      lg: 2,
      options: formOptions['building_type_list'] || [],
    },
    {
      key: 'pk_unit_list',
      type: 'multiselect',
      label: 'general:unit',
      xs: 12,
      sm: 4,
      lg: 2,
      options: formOptions['pk_unit_list'] || [],
    },
    {
      key: 'pk_pact_list',
      type: 'multiselect',
      label: 'general:contract_no',
      xs: 12,
      sm: 4,
      lg: 2,
      options: formOptions['pk_pact_list'] || [],
    },
    {
      key: 'pk_actym_list',
      type: 'multiselect',
      label: 'general:billing_month',
      xs: 12,
      sm: 4,
      lg: 2,
      options: formOptions['pk_actym_list'] || [],
    },
    {
      key: 'date',
      type: 'date',
      label: 'general:date',
      xs: 12,
      sm: 8,
      lg: 4,
      m_make_date_from: 'm_make_date_from',
      m_make_date_to: 'm_make_date_to',
    },
  ];

  //table items****************************************************************************
  const tableColumns = [
    { element: 'basic', key: 'project_name', label: 'general:site', width: '15%' },
    {
      element: 'basic',
      key: 'v_cust_name',
      label: 'general:cust_grp.cust_name',
      width: '15%',
    },
    {
      element: 'basic',
      key: 'building_type',
      label: 'general:segment',
      width: '10%',
    },
    { element: 'basic', key: 'unit_name', label: 'general:unit', width: '10%' },
    {
      element: 'basic',
      key: 'v_pact_num',
      label: 'general:contract_no',
      width: '10%',
    },
    {
      element: 'basic',
      key: 'pk_actym',
      label: 'general:billing_month',
      width: '10%',
    },
    { element: 'basic', key: 'make_date', label: 'general:date', width: '10%' },
    { element: 'action', key: 'action', label: 'general:action', width: '20%' },
  ];

  //form changing**************************************************************************
  const formValueChange = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  //pagination*****************************************************************************
  const handlePageChange = (e, page) => {
    setPageNo(page);
    fetchPaymentReminderList(page);
  };

  const handleSortClick = (key) => (event) => {
    if (paymentReminderList.length > 0) {
      console.log({ key, order, orderBy });
      const isAsc = orderBy === key && order === 'asc';
      setOrderBy(key);
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  const contextValue = {
    pageCount,
    pageNo,
    pageDisable,
    handlePageChange,
    order,
    orderBy,
    handleSortClick,
  };

  return (
    <div className={generalClasses.mainContent}>
      <TableContext.Provider value={contextValue}>
        <Typography variant="h4">{t('menu:tab.payment_reminder')}</Typography>
        <BillingForm
          formItems={formItems}
          searchAction={() => {
            fetchPaymentReminderList(1);
          }}
          formValueChange={formValueChange}
          formValue={formValue}
        />
        <ServerTable tableColumns={tableColumns} tableList={paymentReminderList} handlePdf={handlePdf} />
        <Typography>{t('general:remarks.remark') + t('general:remarks.debit')}</Typography>
        <AlertDialog ref={DialogRef} text={t('error:empty_field')} close_action={true} />
        <AlertDialog ref={PdfRef} text={t('error:no_pdf')} close_action={true} />
      </TableContext.Provider>
    </div>
  );
};

export default PaymentReminder;
