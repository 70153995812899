import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './lib/routes';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import theme from './layouts/styles/theme';
import './App.css';
import './lib/i18n';
import { createBrowserHistory } from 'history';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AppContext } from './context/context';
import urlPraser from '@websanova/url/dist/es5-url.min.js';
import Cookies from 'js-cookie';
import { makeRequest } from './api/api';
import { useTranslation } from 'react-i18next';
import AlertDialog from './components/dialog';

const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

const useStyles = makeStyles((theme) => ({
  backDrop: {
    zIndex: 2000,
    color: '#fff',
  },
}));

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

if (path) {
  history.replace(path);
}

var url = window.location.href;
function removeURLParameter(url, parameter) {
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);
    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    url = urlparts[0] + '?' + pars.join('&');
    return url;
  } else {
    return url;
  }
}
if (url.indexOf('lng=') >= 0) {
  window.location.replace(removeURLParameter(url, 'lng'));
}

const App = (props) => {
  const classes = useStyles();
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loginUsername, setLoginUsername] = useState();
  const [requestList, setRequestList] = useState({});
  const { t } = useTranslation();
  const DialogRef = useRef();

  let token = false;
  if (urlPraser('#id_token')) {
    Cookies.set('accessToken', urlPraser('#id_token'));
    Cookies.set('accountType', 'account');
    history.replace('debit_note');
    token = true;
  } else {
    token = Cookies.get('accessToken');
  }

  if (window.location.href.includes('d1pdjyv0wqgs99.cloudfront.net')) {
    window.location.href = 'https://estatement.hanglung.com' + window.location.pathname;
  }

  const [auth, setAuth] = useState(token);
  const [checkUserPw, setCheckUserPw] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    type: 'success', // default for now
    message: '',
  });

  const handleSnackBarClose = () => {
    setSnackBar((prev) => ({
      ...prev,
      open: !prev.open,
    }));
  };

  useEffect(() => {
    if (Object.keys(requestList).length > 0) {
      setLoaderOpen(true);
      const requestLength = Object.keys(requestList).length;
      let trueCount = 0;
      Object.keys(requestList).forEach((key) => {
        if (requestList[key]) {
          trueCount++;
        }
      });
      if (requestLength === trueCount) {
        setLoaderOpen(false);
      }
    }
  }, [requestList]);

  useEffect(() => {
    const handleCheckUserPw = async () => {
      const res = await makeRequest(['user-management'], ['checkUserPasswordExpiry']);
      console.log(res);
      if (res) {
        if (res[0].data.body === 'change password') {
          DialogRef.current.open();
        }
      }
    };

    if (checkUserPw) {
      const accountType = Cookies.get('accountType');
      if (accountType === 'tenant') {
        handleCheckUserPw();
        setCheckUserPw(false);
      }
    }
  }, [checkUserPw]);

  return (
    <AppContext.Provider
      value={{
        setLoaderOpen,
        setSnackBar,
        dialogOpen,
        setDialogOpen,
        loginUsername,
        setLoginUsername,
        requestList,
        setRequestList,
        auth,
        setAuth,
        setCheckUserPw,
      }}
    >
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <Routes />
          </Router>
        </div>
        <Backdrop className={classes.backDrop} open={loaderOpen}>
          <CircularProgress />
        </Backdrop>
        <Snackbar open={snackBar.open} autoHideDuration={5000} onClose={handleSnackBarClose}>
          <MuiAlert severity={snackBar.type} elevation={6} variant="filled">
            {snackBar.message}
          </MuiAlert>
        </Snackbar>
        <AlertDialog ref={DialogRef} text={t('general:change_password_warning')} close_action={true} />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
