import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeRequest } from '../../api/api';
import { usePrevious } from 'react-delta';

//* Lodash
import { reduce, filter, includes, find, isEqual } from 'lodash';

//* Material UI
import { Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//* Context
import { AppContext, PaymentHistoryContext } from '../../context/context';

//* Hooks
import useRequestList from '../../hooks/useRequestList';

//* Components
import PHForm from './components/ph_form';
import PHTable from '../../components/ph_table';

import generalStyles from '../styles/style';

import Cookies from 'js-cookie';

const useTabsStyles = makeStyles((theme) => {
  return {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: theme.palette.button.main,
    },
  };
});

const AmountDue = (props) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const { setLoaderOpen } = context;
  const tabsClasses = useTabsStyles();

  const defaultFormValue = {
    site_list: null,
    pk_lessee_list: null,
    building_type_list: null,
    pk_unit_list: [],
    pk_pact_list: [],
    pk_brand_list: [],
  };

  //* State
  const [tabIdx, setTabIdx] = useState(0);
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [formFields, setFormFields] = useState(props.formFields);
  const [formErrorMsg, setFormErrorMsg] = useState(false);
  const [tableData, setTableData] = useState([]);
  // const [storeTableData, setStoreTableData] = useState({});
  const [pageTotal, setPageTotal] = useState(0);
  const [sumTotal, setSumTotal] = useState(0);
  const [bPreRev, setBPreRev] = useState(0);
  const [initFormOptions, setInitFormOptions] = useState({});
  const [pagCount, setPagCount] = useState(0);
  const [pagPage, setPagPage] = useState(1);
  const [pagDisable, setPagDisable] = useState(false);
  const [tableFields, setTableFields] = useState([]);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  //* Hooks
  const handleSetRequestList = useRequestList();

  //* Previous
  const previousFormValue = usePrevious(formValue);
  const previousFormFields = usePrevious(formFields);
  const previousTabIdx = usePrevious(tabIdx);

  //* Style
  const generalClasses = generalStyles();

  //* Handle Search Click
  const handleSearchClick = () => {
    const { site_list } = formValue;
    console.log(formValue);
    const ArrInputValue = Object.keys(formValue).reduce((acc, key) => {
      const value = formValue[key];
      if (Array.isArray(value) && value.length !== 0) {
        acc.push(key);
      } else if (value !== null && !Array.isArray(value)) {
        acc.push(key);
      }
      return acc;
    }, []);
    if ((site_list && ArrInputValue.length < 2) || !site_list) {
      setFormErrorMsg(true);
    } else {
      setFormErrorMsg(false);
      fetchTableData(1, tabIdx === 0 ? 'summary' : tabIdx === 1 ? 'details' : 'b_pre_rev');
    }
  };

  //* Handle Sort Click
  const handleSortClick = (key) => (event) => {
    if (tableData.length > 0) {
      const isAsc = orderBy === key && order === 'asc';
      setOrderBy(key);
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  const handleTabsChange = (event, newIdx) => {
    setTabIdx(newIdx);
  };

  const handlePageChange = (e, page) => {
    setPagPage(page);
    fetchTableData(page, tabIdx === 0 ? 'summary' : tabIdx === 1 ? 'details' : 'b_pre_rev');
  };

  const modifyFormValue = (formValue) => {
    return reduce(
      formValue,
      (result, fValue, fKey) => {
        let new_result;
        if (Array.isArray(fValue)) {
          const filteredValue = reduce(
            fValue,
            (acc, item) => {
              acc.push(item.value);
              return acc;
            },
            []
          );
          new_result = {
            ...result,
            [fKey.replace('_list', '')]: filteredValue.join(','),
          };
        } else if (fValue && !Array.isArray(fValue)) {
          new_result = {
            ...result,
            [fKey.replace('_list', '')]: fValue.value,
          };
        } else {
          new_result = {
            ...result,
            [fKey.replace('_list', '')]: '',
          };
        }
        return new_result;
      },
      {}
    );
  };

  const fetchTableData = async (page, tab) => {
    const m_formValue = modifyFormValue(formValue);
    const toPostData = {
      formValue: m_formValue,
      page,
      tab: tab,
      sorting: {
        order,
        orderBy,
      },
    };
    console.log(toPostData);
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['getAmountDue'], [toPostData]);
    console.log(res);
    window.scrollTo(0, 0);
    try {
      if (res) {
        if (res[0].data.body) {
          const data = res[0].data.body.results;
          const pageTotal = res[0].data.body.pageTotal;
          const sumTotal = res[0].data.body.sumTotal;
          const rowTotal = res[0].data.body.total;
          const bPreRev = res[0].data.body.b_pre_rev_amount;
          console.log(data);
          if (data.length !== 0) {
            setPagPage(page);
            setPageTotal(pageTotal);
            setSumTotal(sumTotal);
            setTableData(data);
            setPagCount(Math.ceil(rowTotal / 10));
            setPagDisable(rowTotal <= 10 ? true : false);
          } else {
            setPagPage(1);
            setPageTotal(pageTotal);
            setSumTotal(sumTotal);
            setTableData(data);
            setPagCount(1);
            setPagDisable(true);
          }
          if (bPreRev) {
            setBPreRev(bPreRev);
          } else {
            setBPreRev(0);
          }
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoaderOpen(false);
    }
  };

  const stateSetFormValue = (key, value) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const setOptionsToFields = (source, init) => {
    setFormFields((prev) => {
      const new_fields = reduce(
        prev,
        (result, item) => {
          if (init) {
            const newItem = {
              ...item,
              options: item.key === 'site_list' ? source[item.key] : [],
            };
            result.push(newItem);
          } else {
            const newItem = {
              ...item,
              options: source[item.key] ? source[item.key] : [],
            };
            result.push(newItem);
          }
          return result;
        },
        []
      );
      // console.log(new_fields);
      return new_fields;
    });
  };

  const fetchOtherFormFields = async (formValue) => {
    console.log(formValue);
    setLoaderOpen(true);
    const res = await makeRequest(['user-management'], ['getSearchAmountDue'], [{ formValue }]);
    console.log(res);
    const construFormFields = {};
    if (res[0].data) {
      res.forEach((resItem, index) => {
        if (resItem.data.body.length > 0) {
          const data = resItem.data.body;
          console.log(data);
          data.forEach((dItem) => {
            if (dItem.customerList) {
              const new_data = reduce(
                dItem.customerList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.v_cust_name} (${item.v_site})`,
                    value: item.pk_lessee,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_lessee_list = new_data;
            } else if (dItem.unitList) {
              const new_data = reduce(
                dItem.unitList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.unit_name} (${item.v_site})`,
                    value: item.pk_unit,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_unit_list = new_data;
            } else if (dItem.pactList) {
              const new_data = reduce(
                dItem.pactList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.v_pact_num} (${item.v_site})`,
                    value: item.pk_pact,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_pact_list = new_data;
            } else if (dItem.brandList) {
              const new_data = reduce(
                dItem.brandList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.v_brand_name} (${item.v_site})`,
                    value: item.pk_brand,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.pk_brand_list = new_data;
            } else if (dItem.buildingList) {
              const new_data = reduce(
                dItem.buildingList,
                (result, item) => {
                  const reItem = {
                    key: item.v_site,
                    text: `${item.building_type} (${item.v_site})`,
                    value: item.building_type,
                  };
                  result.push(reItem);
                  return result;
                },
                []
              );
              construFormFields.building_type_list = new_data;
            }
          });
        }
      });
    }
    setInitFormOptions((prev) => {
      return {
        ...prev,
        ...construFormFields,
      };
    });
    setLoaderOpen(false);
  };

  const handleTotalClick = (tab) => {
    setTabIdx(tab);
  };

  const handleSummaryAmountClick = (row, field_name) => {
    console.log(row, field_name);
    //* get the row and update the formValue
    //* set and store the formValue
    //* fetchData and update the tableData
    //* set and store the tableData
    //* switch Tab
    const updateFormValue = reduce(
      formValue,
      (acc, value, key) => {
        let newAcc;
        if (value && value.length !== 0) {
          console.log('1', key, value);
          newAcc = {
            ...acc,
            [key]: value,
          };
        } else if (!Array.isArray(value)) {
          console.log('2', key, value);
          const newKey = key.replace('_list', '');
          // console.log(newKey);
          newAcc = {
            ...acc,
            [key]: filter(initFormOptions[key], (o) => o.value === row[newKey])[0] || null,
          };
        } else {
          console.log('3', key, value);
          const newKey = key.replace('_list', '');
          const getRowValue = row[newKey];
          const splitValue = getRowValue.split(',');
          console.log(getRowValue, splitValue);
          if (splitValue.length > 1) {
            const newArray = reduce(
              splitValue,
              (acc, item) => {
                acc.push(filter(initFormOptions[key], (o) => o.value === item)[0]);
                return acc;
              },
              []
            );
            newAcc = {
              ...acc,
              [key]: newArray,
            };
          } else if (getRowValue !== '') {
            console.log(filter(initFormOptions[key], (o) => o.value === row[newKey])[0]);
            newAcc = {
              ...acc,
              [key]: filter(initFormOptions[key], (o) => o.value === row[newKey])[0] ? [filter(initFormOptions[key], (o) => o.value === row[newKey])[0]] : [],
            };
            console.log(key, newAcc);
          } else {
            newAcc = {
              ...acc,
              [key]: [],
            };
          }
        }
        return newAcc;
      },
      {}
    );
    console.log(updateFormValue);
    setFormValue(updateFormValue);
    setTabIdx(field_name === 'total_un_collected' ? 1 : 2);
  };

  const checkFormValueInput = () => {
    let inputCount = 0;
    Object.keys(formValue).forEach((key) => {
      if (Array.isArray(formValue[key])) {
        if (formValue[key].length > 0) {
          inputCount++;
        }
      } else if (formValue[key] !== null) {
        inputCount++;
      }
    });
    return inputCount < 2 ? false : true;
  };

  const updateTableFields = (tabIdx) => {
    const { tableFields } = props;
    const m_tableFields = filter(tableFields, (o) => {
      return includes(o.tab, tabIdx === 0 ? 'summary' : tabIdx === 1 ? 'details' : 'b_pre_rev');
    });
    // console.log(m_tableFields);
    setTableFields(m_tableFields);
  };

  //* init fetching
  useEffect(() => {
    //* Fetching Form Fields from db
    const fetchFormFields = async () => {
      handleSetRequestList('amount_due', false, true);
      const res = await makeRequest(['user-management'], ['getSiteList2']);
      console.log(res);
      const construFormFields = {};
      if (res) {
        if (res[0].data) {
          const data = res[0].data.body;
          if (data) {
            const new_data = reduce(
              data,
              (result, item) => {
                const reItem = {
                  text: `${item.project_name} (${item.site})`,
                  value: item.site,
                };
                result.push(reItem);
                return result;
              },
              []
            );
            construFormFields.site_list = new_data;
          }
        }
        setInitFormOptions(construFormFields);
      }
      handleSetRequestList('amount_due', true);
    };
    fetchFormFields();
  }, []);

  useEffect(() => {
    if (Object.keys(initFormOptions).length === 1 && initFormOptions.site_list) {
      setOptionsToFields(initFormOptions, true);
    } else {
      setOptionsToFields(initFormOptions, false);
    }
  }, [initFormOptions]);

  useEffect(() => {
    const optionSiteList = find(formFields, (o) => o.key === 'site_list').options;
    const prevOptionSiteList = find(previousFormFields, (o) => o.key === 'site_list') ? find(previousFormFields, (o) => o.key === 'site_list').options : [];
    const optionLesseeList = find(formFields, (o) => o.key === 'pk_lessee_list').options;
    const prevOptionLesseeList = find(previousFormFields, (o) => o.key === 'pk_lessee_list')
      ? find(previousFormFields, (o) => o.key === 'pk_lessee_list').options
      : [];
    console.log(optionSiteList, prevOptionSiteList, optionLesseeList, prevOptionLesseeList);
    if (optionSiteList.length > 0 && prevOptionSiteList !== optionSiteList) {
      console.log('trigger once option site list');
      console.log(Cookies.get('site_list'));
      if (Cookies.get('site_list')) {
        const cookies_site_list = JSON.parse(Cookies.get('site_list'));
        console.log(cookies_site_list);
        if (cookies_site_list) {
          let optionValue;
          if (Array.isArray(cookies_site_list)) {
            optionValue = cookies_site_list[0].value;
          } else {
            optionValue = cookies_site_list.value;
          }
          const foundOption = find(optionSiteList, (o) => o.value === optionValue);
          console.log(foundOption);
          if (foundOption) {
            setFormValue((prev) => ({
              ...prev,
              site_list: foundOption,
            }));
            // handleSetRequestList('amount_due_site_list', false);
          }
        }
      }
    }

    if (optionLesseeList.length > 0 && prevOptionLesseeList !== optionLesseeList) {
      console.log('trigger once option lessee list');
      console.log(Cookies.get('pk_lessee_list'));
      if (Cookies.get('pk_lessee_list')) {
        const cookies_lessee_list = JSON.parse(Cookies.get('pk_lessee_list'));
        console.log(cookies_lessee_list);
        if (cookies_lessee_list) {
          const optionValue = cookies_lessee_list.value.split('::').length === 2 ? cookies_lessee_list.value.split('::')[1] : cookies_lessee_list.value;
          const foundOption = find(optionLesseeList, (o) => o.value === optionValue);
          console.log(foundOption);
          if (foundOption) {
            setFormValue((prev) => ({
              ...prev,
              pk_lessee_list: foundOption,
            }));
          }
        }
      }
    }
  }, [formFields]);

  useEffect(() => {
    if (!isEqual(previousFormValue, formValue)) {
      const { site_list, pk_lessee_list } = formValue;
      const prev_pk_lessee_list = previousFormValue ? previousFormValue.pk_lessee_list : '';
      if (site_list) {
        const m_formValue = modifyFormValue(formValue);
        Cookies.set('site_list', JSON.stringify(site_list));
        console.log(m_formValue);
        fetchOtherFormFields(m_formValue);
      } else {
        setFormValue(defaultFormValue);
        const filteredFormOptions = reduce(
          initFormOptions,
          (result, ffValue, ffKey) => {
            let new_result;
            if (ffKey === 'site_list') {
              new_result = {
                ...result,
                [ffKey]: ffValue,
              };
            } else {
              new_result = {
                ...result,
                [ffKey]: [],
              };
            }
            return new_result;
          },
          {}
        );
        console.log(filteredFormOptions);
        setFormValue(defaultFormValue);
        setOptionsToFields(filteredFormOptions, false);
        if (previousFormValue) {
          Cookies.remove('site_list');
          Cookies.remove('pk_lessee_list');
        }
      }
      if (pk_lessee_list) {
        console.log('selected pk lessee');
        Cookies.set('pk_lessee_list', JSON.stringify(pk_lessee_list));
      } else if (!pk_lessee_list && prev_pk_lessee_list !== pk_lessee_list && prev_pk_lessee_list !== '') {
        console.log('removed pk lessee');
        Cookies.remove('pk_lessee_list');
      }
    }
  }, [formValue]);

  useEffect(() => {
    // console.log(checkFormValueInput(), tableData.length !== 0, bPreRev, previousTabIdx, tabIdx);
    if (checkFormValueInput() && (tableData.length !== 0 || bPreRev !== 0) && previousTabIdx !== tabIdx) {
      setTableData([]);
      setPagCount(1);
      setPagDisable(true);
      setPageTotal(0);
      setSumTotal(0);
      setBPreRev(0);
      console.log(formValue);
      fetchTableData(1, tabIdx === 0 ? 'summary' : tabIdx === 1 ? 'details' : 'b_pre_rev');
    }
    updateTableFields(tabIdx);
  }, [tabIdx, formValue]);

  useEffect(() => {
    if (order && orderBy) {
      fetchTableData(1, tabIdx === 0 ? 'summary' : tabIdx === 1 ? 'details' : 'b_pre_rev');
    }
  }, [order, orderBy]);

  const contextValue = {
    tabIdx,
    formFields,
    bPreRev,
    formValue,
    stateSetFormValue,
    formErrorMsg,
    tableData,
    tableFields,
    pageTotal,
    sumTotal,
    handleSearchClick,
    pagCount,
    pagPage,
    pagDisable,
    handleSummaryAmountClick,
    handlePageChange,
    handleTotalClick,
    order,
    orderBy,
    handleSortClick,
  };

  return (
    <PaymentHistoryContext.Provider value={contextValue}>
      <div className={generalClasses.mainContent}>
        <Typography variant="h4">{t('menu:tab.amount_due')}</Typography>
        <Tabs value={tabIdx} classes={tabsClasses} onChange={handleTabsChange}>
          <Tab label={t('general:summary')} />
          <Tab label={t('general:details')} />
          <Tab label={t('general:pre_receivable')} />
        </Tabs>
        <PHForm />
        <PHTable />
        <Typography>{t('general:remarks.remark') + t('general:remarks.debit')}</Typography>
      </div>
    </PaymentHistoryContext.Provider>
  );
};

AmountDue.propTypes = {
  formFields: PropTypes.array,
  tableFields: PropTypes.array,
};

AmountDue.defaultProps = {
  formFields: [
    {
      key: 'site_list',
      type: 'select',
      label: 'general:site',
      xs: 12,
      md: 12,
      required: true,
      options: [],
    },
    {
      key: 'pk_lessee_list',
      type: 'select',
      label: 'general:cust_grp.cust_name',
      xs: 12,
      md: 12,
      options: [],
    },
    {
      key: 'building_type_list',
      type: 'select',
      label: 'general:segment',
      xs: 12,
      md: 3,
      options: [],
    },
    {
      key: 'pk_unit_list',
      type: 'multiselect',
      label: 'general:unit',
      xs: 12,
      md: 3,
      options: [],
    },
    {
      key: 'pk_pact_list',
      type: 'multiselect',
      label: 'general:contract_no',
      xs: 12,
      md: 3,
      options: [],
    },
    {
      key: 'pk_brand_list',
      type: 'multiselect',
      label: 'general:brand',
      xs: 12,
      md: 3,
      options: [],
    },
  ],
  tableFields: [
    {
      key: 'site',
      label: 'general:site',
      field_name: 'project_name',
      tab: ['summary', 'details', 'b_pre_rev'],
    },
    {
      key: 'segment',
      label: 'general:segment',
      field_name: 'building_type',
      tab: ['summary', 'details', 'b_pre_rev'],
    },
    {
      key: 'cust_name',
      label: 'general:cust_grp.cust_name',
      field_name: 'v_cust_name',
      tab: ['summary', 'details', 'b_pre_rev'],
    },
    {
      key: 'contract_no',
      label: 'general:contract_no',
      field_name: 'v_pact_num',
      tab: ['summary', 'details', 'b_pre_rev'],
    },
    {
      key: 'unit',
      label: 'general:unit',
      field_name: 'unit_name',
      tab: ['summary', 'details', 'b_pre_rev'],
    },
    {
      key: 'brand',
      label: 'general:brand',
      field_name: 'v_brand_name',
      tab: ['summary', 'details', 'b_pre_rev'],
    },
    {
      key: 'total_un_collected',
      label: 'general:un_collected',
      field_name: 'total_un_collected',
      tab: ['summary'],
    },
    {
      key: 'receive_type',
      label: 'general:receive_type',
      field_name: 'v_name',
      tab: ['details', 'b_pre_rev'],
    },
    {
      key: 'pk_actym',
      label: 'general:pk_actym',
      field_name: 'cost_belong_month',
      tab: ['details', 'b_pre_rev'],
    },
    {
      key: 'm_begin_date',
      label: 'general:amount_due_date',
      field_name: 'd_bill_mutdate',
      tab: ['details', 'b_pre_rev'],
    },
    {
      key: 'nybqys',
      label: 'general:receivables_amount',
      field_name: 'nybqys',
      tab: ['details', 'b_pre_rev'],
    },
    {
      key: 'received',
      label: 'general:received_amount',
      field_name: 'received',
      tab: ['details', 'b_pre_rev'],
    },
    {
      key: 'un_collected',
      label: 'general:un_collected',
      field_name: 'un_collected',
      tab: ['details', 'b_pre_rev'],
    },
    {
      key: 'b_pre_rev',
      label: 'general:b_pre_rev',
      field_name: '',
      tab: ['b_pre_rev'],
    },
  ],
};

export default AmountDue;
