import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';

const AlertDialog = forwardRef(({ text, confirm_action, close_action = false, disableBackdropClick = false, disableEscapeKeyDown = false }, ref) => {
  const { t } = useTranslation(['general']);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      handleClickOpen();
    },
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    if (!!confirm_action) {
      confirm_action();
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {!!close_action ? '' : <Button onClick={handleClose}>{t('general:cancel')} </Button>}
          <Button onClick={onConfirm}>{t('general:confirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

AlertDialog.propTypes = {
  text: PropTypes.string,
  confirm_action: PropTypes.func,
  close_action: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
};

AlertDialog.displayName = 'AlertDialog';

export default AlertDialog;
