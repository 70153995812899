import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const MobileAppBar = ({ drawerOnToggle }) => {
  const classes = useStyles();
  const { t } = useTranslation(['menu']);

  return (
    <div>
      <Hidden smUp>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={drawerOnToggle}>
              <MenuIcon />
            </IconButton>
            <img src={'/img/hl_logo_name.png'} style={{ height: 40, marginRight: 20 }} alt="" />
            <Typography variant="h6" className={classes.title}>
              {t('menu:heading.eportal')}
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
    </div>
  );
};

MobileAppBar.propTypes = {
  drawerOnToggle: PropTypes.func,
};

export default MobileAppBar;
