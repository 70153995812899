import { useContext } from 'react';
import { AppContext } from '../context/context';

const useRequestList = () => {
  const context = useContext(AppContext);
  const { setRequestList } = context;

  const handleSetRequestList = (key, value, clear) => {
    setRequestList((prev) => {
      const bringItem = clear ? {} : prev;
      return {
        ...bringItem,
        [key]: value,
      };
    });
  };

  return handleSetRequestList;
};

export default useRequestList;
