import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import generalStyles from '../layouts/styles/style';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

//pagination***********************************************************************************
function TablePaginationActions(props) {
  const generalClasses = generalStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={generalClasses.paginationArrow}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
};

const BasicTable = ({ tableColumns = [], tableList = [], setData, padding, previewPdf, downloadPdf }) => {
  //tableColumns, tableList compulsory; setData, padding for Opt-out
  const { t } = useTranslation(['general']);
  const generalClasses = generalStyles();

  const handleChange = (event, pk_rent_lessee) => {
    setData((prev) => {
      let rowList = prev.reduce((acc, item, index) => {
        if (item.pk_rent_lessee === pk_rent_lessee) {
          const newItem = {
            ...item,
            opt_out: event.target.checked ? 1 : 0,
          };
          acc.push(newItem);
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
      return rowList;
    });
  };

  //pagination******************************************************************************
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(tableColumns[0].key);

  useEffect(() => {
    setPage(0);
    if (tableList.length > 0) {
      setRowsPerPage(10);
    }
  }, [tableList]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableList.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //sorting*********************************************************************************
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <div>
      <Hidden xsDown>
        <Box p={padding ? padding : 3} mt={4} className={generalClasses.root}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableColumns.map(({ key, label, width }) => (
                    <TableCell key={key} sortDirection={orderBy === key ? order : false} style={{ whiteSpace: 'nowrap', width: width }}>
                      <TableSortLabel active={orderBy === key} direction={orderBy === key ? order : 'asc'} onClick={createSortHandler(key)}>
                        {t(label)}
                        {orderBy === key ? (
                          <span className={generalClasses.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stableSort(tableList, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableList
                ).map((row, index) => (
                  <TableRow key={index}>
                    {tableColumns.map(({ element, key }) => {
                      let comp;
                      if (element === 'action') {
                        comp = (
                          <TableCell key={key}>
                            <Grid container spacing={1}>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  className={generalClasses.tableActionButton}
                                  onClick={() => previewPdf(row)}
                                >
                                  {t('general:preview')}
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  className={generalClasses.tableActionButton}
                                  onClick={() => downloadPdf(row)}
                                >
                                  {t('general:download')}
                                </Button>
                              </Grid>
                            </Grid>
                          </TableCell>
                        );
                      } else if (element === 'basic') {
                        comp = <TableCell key={key}>{row[key]}</TableCell>;
                      } else if (element === 'checkbox') {
                        comp = (
                          <TableCell key={key}>
                            <Checkbox
                              disabled={row[key] === 1 && row['handler'] === 1 ? true : false}
                              checked={row[key] === 1 ? true : false}
                              onChange={(e) => {
                                handleChange(e, row.pk_rent_lessee);
                              }}
                            />
                          </TableCell>
                        );
                      }
                      return comp;
                    })}
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className={generalClasses.paginationSpacer}
                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                    count={tableList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    labelRowsPerPage={t('general:rows_per_page')}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Hidden>
      <Hidden smUp>
        {tableList.length > 0 && (
          <Box p={padding ? padding : 3} mt={4} className={generalClasses.root}>
            {tableList.map((row, index) => (
              <Box key={index} my={4}>
                <TableContainer key={index} component={Paper}>
                  <Table>
                    <TableBody>
                      {tableColumns.map(({ element, key, label }) => (
                        <TableRow key={key}>
                          <TableCell>
                            <Typography>{t(label)}</Typography>
                          </TableCell>
                          {element === 'action' && (
                            <TableCell key={key}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={generalClasses.tableActionButton}
                                    onClick={() => previewPdf(row)}
                                  >
                                    {t('general:preview')}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={generalClasses.tableActionButton}
                                    onClick={() => downloadPdf(row)}
                                  >
                                    {t('general:download')}
                                  </Button>
                                </Grid>
                              </Grid>
                            </TableCell>
                          )}
                          {element === 'basic' && <TableCell key={key}>{row[key]}</TableCell>}
                          {element === 'checkbox' && (
                            <TableCell key={key}>
                              <Checkbox
                                disabled={row[key] === 1 && row['handler'] === 1 ? true : false}
                                checked={row[key] === 1 ? true : false}
                                onChange={(e) => {
                                  handleChange(e, row.pk_rent_lessee);
                                }}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </Box>
        )}
      </Hidden>

      {/* <Box>
        <Grid container>
          <TablePagination
            className={generalClasses.paginationSpacer}
            rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
            count={tableList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            labelRowsPerPage={t('general:rows_per_page')}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Grid>
      </Box> */}
    </div>
  );
};

BasicTable.propTypes = {
  tableColumns: PropTypes.array,
  tableList: PropTypes.array,
  setData: PropTypes.func,
  padding: PropTypes.string,
  previewPdf: PropTypes.func,
  downloadPdf: PropTypes.func,
};

export default BasicTable;
